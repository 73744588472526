import FAQContents from "@views/components/FAQ/faq_contents";
import React from "react";

const FAQPage = () => {
  return (
    <div>
      <FAQContents />
    </div>
  );
};

export default FAQPage;
