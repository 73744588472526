import SchemesContents from "@views/components/schemes/schemes_contents";
import React from "react";

const SchemesPage = () => {
  return (
    <div>
      <SchemesContents />
    </div>
  );
};

export default SchemesPage;
