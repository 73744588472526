import { gql } from "@apollo/client";

export const mutation_create_system_cms_sports_data = gql`
  mutation createSystemCmsSportsDataValue(
    $json: create_system_cms_sports_data_value_input
  ) {
    createSystemCmsSportsDataValue(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_system_cms_sports_data = gql`
  mutation updateSystemCmsSportsDataValue(
    $json: update_system_cms_sports_data_value_input
  ) {
    updateSystemCmsSportsDataValue(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_system_cms_sports_data = gql`
  mutation deleteSystemCmsSportsDataValue(
    $json: delete_system_cms_sports_data_value_input
  ) {
    deleteSystemCmsSportsDataValue(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_system_cms_sports_hostel_details = gql`
query getSystemCmsSportsDataValues($json:get_all_system_cms_sports_data_value_input){
    getSystemCmsSportsDataValues(json:$json){
      items{
        id
        name
        system_cms_sports_data_id
        details
      }
    }
  }
`;
