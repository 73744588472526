import { Image, Skeleton } from "antd";
import { Box, HStack, Hidden, VStack } from "native-base";
import React from "react";

const GalleryImageTemplateLoading = (props) => {
  const { images } = props;

  return (
    <div className="gallery_first_template" style={{ width: "100%" }}>
      <Hidden from="base" till="lg">
        <HStack space={4}>
          <Skeleton.Image
            className="gallery_second_image_size"
            style={{ borderRadius: "20px", height: "100%" }}
            active
          />
          <VStack justifyContent={"space-around"}>
            <Skeleton.Image
              className="gallery_first_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
              active
            />
            <Skeleton.Image
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
              active
            />
          </VStack>
          <VStack justifyContent={"space-around"}>
            <Skeleton.Image
              className="gallery_fourth_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
              active
            />
            <Skeleton.Image
              className="gallery_fifth_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
              active
            />
          </VStack>
        </HStack>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <VStack space={4}>
          <Skeleton.Image
            className="gallery_second_image_size"
            style={{ borderRadius: "20px" }}
            active
          />
          <HStack justifyContent={"space-around"} gap={"10px"}>
            <Skeleton.Image
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
              active
            />
            <Skeleton.Image
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
              active
            />
          </HStack>
          <HStack justifyContent={"space-around"} gap={"10px"}>
            <Skeleton.Image
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
              active
            />
            <Skeleton.Image
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
              active
            />
          </HStack>
        </VStack>
      </Hidden>
    </div>
  );
};

export default GalleryImageTemplateLoading;
