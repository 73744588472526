import { Box, HStack, Hidden, Link, Stack, Text } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../tender/table.css";
import { Button, Image, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import moment from "moment";
import TableLoading from "../events/table_loading";
import { get_cms_press_release_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_press_release_query";
import { FaArrowRight } from "react-icons/fa";

const PressReleaseTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [Images, set_images] = useState([]);

  const { items, loading } = useDynamicSelector("get_cms_press_release");

  const get_press_release = () => {
    let keys = [{ key: "get_cms_press_release", loading: true }];
    let variables = {
      json: {
        system_cms_key: "press_release",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_press_release_query, variables));
  };

  useEffect(() => {
    get_press_release();
  }, []);

  useEffect(() => {
    let values = FilterItems(items);
    setData(values);
  }, [items]);

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <Hidden from="base" till="lg">
            <div>
              {data?.map((tablelist, index) => {
                const eventImages = JSON.parse(
                  tablelist?.Images?.value || "[]"
                );
                const eventDocuments = JSON.parse(
                  tablelist?.files?.value || "[]"
                );
                const validImages = eventImages
                  .flat()
                  .filter((url) => url?.includes("https"));
                return (
                  <table
                    className="table"
                    style={{ marginBottom: "30px" }}
                    key={index}
                  >
                    <thead className="table-header">
                      <tr>
                        <th
                          style={{ textAlign: "start", paddingRight: "10px" }}
                        >
                          {moment(tablelist?.date?.value).format("DD/MM/YYYY")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {JSON.parse(tablelist?.title?.value || "[]").map(
                        (event, event_index) => (
                          <React.Fragment key={event_index}>
                            <tr
                              className={`space-add ${
                                data.length - 1 !== event_index
                                  ? "table-border-bottom"
                                  : ""
                              }`}
                            >
                              <td
                                data-label="Reference No"
                                onClick={() => {
                                  if (validImages.length > 0) {
                                    setVisible(true);
                                    set_images(
                                      JSON.parse(tablelist?.Images?.value)?.[
                                        event_index
                                      ]
                                    );
                                  }
                                }}
                                style={{
                                  cursor:
                                    validImages.length > 0
                                      ? "pointer"
                                      : "default",
                                  color:
                                    validImages.length > 0
                                      ? "#2f80ed"
                                      : "black",
                                }}
                              >
                                <FaArrowRight size={"15px"} color="green" />{" "}
                                {event}
                              </td>
                            </tr>
                            {visible && validImages.length > 0 && (
                              <div style={{ display: "none" }}>
                                <Image.PreviewGroup
                                  preview={{
                                    visible,
                                    onVisibleChange: (value) => {
                                      setVisible(value);
                                      set_images([]);
                                    },
                                  }}
                                >
                                  {tablelist?.Images?.value &&
                                    Images?.map((event_img, index) => {
                                      return (
                                        <Image
                                          key={index}
                                          src={event_img}
                                          width={180}
                                          height={100}
                                        />
                                      );
                                    })}{" "}
                                </Image.PreviewGroup>
                              </div>
                            )}
                            {/* {eventDocuments?.[0]?.[0] && (
                              <tr>
                                <td>
                                  <Button
                                    type="link"
                                    onClick={() =>
                                      window.open(
                                        eventDocuments[0][0],
                                        "_blank"
                                      )
                                    }
                                    style={{ paddingLeft: 0 }}
                                  >
                                    View Document
                                  </Button>
                                </td>
                              </tr>
                            )} */}
                            {eventDocuments[event_index]?.map(
                              (docUrl, docIndex) =>
                                docUrl ? (
                                  <tr key={`doc-${event_index}-${docIndex}`}>
                                    <td>
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          window.open(docUrl, "_blank")
                                        }
                                        style={{ paddingLeft: 0 }}
                                      >
                                        View Document
                                      </Button>
                                    </td>
                                  </tr>
                                ) : null
                            )}
                          </React.Fragment>
                        )
                      )}
                    </tbody>
                  </table>
                );
              })}
            </div>
          </Hidden>
          <Hidden from="lg" till="3xl">
            <Box variant={"tender_table_box"}>
              <div>
                {data?.map((tablelist, index) => {
                  const eventImages = JSON.parse(
                    tablelist?.Images?.value || "[]"
                  );
                  const eventDocuments = JSON.parse(
                    tablelist?.files?.value || "[]"
                  );
                  const validImages = eventImages
                    .flat()
                    .filter((url) => url?.includes("https"));
                  return (
                    <table
                      className="table"
                      style={{ marginBottom: "30px" }}
                      key={index}
                    >
                      <thead className="table-header">
                        <tr>
                          <th
                            style={{ textAlign: "start", paddingRight: "10px" }}
                          >
                            {moment(tablelist?.date?.value).format(
                              "DD/MM/YYYY"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {JSON.parse(tablelist?.title?.value).map(
                          (event, event_index) => (
                            <React.Fragment key={event_index}>
                              <tr
                                className={`space-add ${
                                  data.length - 1 !== event_index
                                    ? "table-border-bottom"
                                    : ""
                                }`}
                              >
                                <td
                                  data-label="Reference No"
                                  onClick={() => {
                                    if (validImages.length > 0) {
                                      setVisible(true);
                                      set_images(
                                        JSON.parse(tablelist?.Images?.value)?.[
                                          event_index
                                        ]
                                      );
                                    }
                                  }}
                                  style={{
                                    cursor:
                                      validImages.length > 0
                                        ? "pointer"
                                        : "default",
                                    color:
                                      validImages.length > 0
                                        ? "#2f80ed"
                                        : "black",
                                  }}
                                >
                                  <FaArrowRight size={"15px"} color="green" />{" "}
                                  {event}
                                </td>
                              </tr>
                              {visible && validImages.length > 0 && (
                                <div style={{ display: "none" }}>
                                  <Image.PreviewGroup
                                    preview={{
                                      visible,
                                      onVisibleChange: (value) => {
                                        setVisible(value);
                                        set_images([]);
                                      },
                                    }}
                                  >
                                    {tablelist?.Images?.value &&
                                      Images?.map((event_img, index) => {
                                        return (
                                          <Image
                                            key={index}
                                            src={event_img}
                                            width={180}
                                            height={100}
                                          />
                                        );
                                      })}
                                  </Image.PreviewGroup>
                                </div>
                              )}
                              {eventDocuments[event_index]?.map(
                                (docUrl, docIndex) =>
                                  docUrl ? (
                                    <tr key={`doc-${event_index}-${docIndex}`}>
                                      <td>
                                        <Button
                                          type="link"
                                          onClick={() =>
                                            window.open(docUrl, "_blank")
                                          }
                                          style={{ paddingLeft: 0 }}
                                        >
                                          View Document
                                        </Button>
                                      </td>
                                    </tr>
                                  ) : null
                              )}
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </Box>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default PressReleaseTable;
