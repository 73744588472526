import { Box } from "native-base";
import React from "react";
import Largelogo from "@assets/images/Background_logo.png";

const BackgroundWaterMarkLogo = () => {
  return (
    <Box variant={"service_content_image_box"}>
      <img src={Largelogo} alt="largelogo" className="sport-large-logo" />
    </Box>
  );
};

export default BackgroundWaterMarkLogo;
