import React, { useEffect, useState } from "react";
import { Box, Button, VStack } from "native-base";
import { ROUTES } from "@views/routes/my_routes";
import SideBar from "@views/components/ui/side_bar";
import SideBarHeader from "@views/components/modules/sidebar/sidebar_header";
import SideBarFooter from "@views/components/modules/sidebar/sidebar_footer";

import { useTranslation } from "react-i18next";
import FooterBar from "@views/components/ui/footer_bar";
import HeaderBar from "@views/components/ui/admin_header_bar";
import ScrollView from "@views/components/ui/scroll_view";
import SportsImage from "@assets/images/sports_image.jpg";
import Cms from "@assets/icons/content-management-system.png";
import List from "@assets/icons/web-programming.png";
import { MdOutlineDeviceHub } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";

const Layout1 = ({ children }, store) => {
  const { t } = useTranslation();
  let menu = [
    // {
    //   key: "Page",
    //   label: "Page",
    //   icon: <MdOutlineDeviceHub />,
    //   url: ROUTES.PAGE,
    //   included_url: [ROUTES.PAGE],
    // },
    {
      key: "CMS List",
      title: "CMS List",
      icon: <img src={List} alt={"List"} className={"layout-icons"} />,
      url: ROUTES.CMS_LIST,
      included_url: [ROUTES.CMS_LIST, `${ROUTES.CMS_DETAILS}/:id?`],
    },
    {
      key: "System Cms ",
      title: "System Cms ",
      icon: <img src={Cms} alt={"Cms"} className={"layout-icons"} />,
      url: ROUTES.SYSTEM_CMS,
      included_url: [ROUTES.SYSTEM_CMS],
    },
    // {
    //   key: "sports_hostel",
    //   title: "Sports hostel ",
    //   icon: <FaBuilding />,
    //   url: ROUTES.SPORTS_HOSTEL_LIST,
    //   included_url: [ROUTES.SPORTS_HOSTEL_LIST],
    // },
     {
      key: "gallery",
      title: "Gallery",
      icon: <FaBuilding />,
      url: ROUTES.GALLERY_LIST,
      included_url: [ROUTES.GALLERY_LIST],
    },
  ];

  return (
    <Box flexDirection="row" flexGrow="1">
      <SideBar
        image={SportsImage}
        iconShape="round"
        iconBgColor={"none"}
        iconColor={"#ffffff"}
        iconSize={25}
        iconSelectedColor={"#330000"}
        titleColor={"#adadad"}
        titleActiveColor={"#ffffff"}
        headerHeight={"100px"}
        footerHeight="50px"
        menu={menu}
        headerComponent={<SideBarHeader />}
        footerComponent={<SideBarFooter />}
        projectName="SDAT"
      />
      <VStack flex={1}>
        <HeaderBar
          projectName={t("SPORTS DEVELOPMENT AUTHORITY OF TAMILNADU")}
        />
        <ScrollView flex={1}>
          <Box flex={1} ml="10px" mr="10px" px="30px">
            {children}
          </Box>
        </ScrollView>
        <FooterBar />
      </VStack>
    </Box>
  );
};
export default Layout1;
