import { FilterItems } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_services_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_services_query";
import { ROUTES } from "@views/routes/my_routes";
import {
  Box,
  Card,
  HStack,
  Link,
  Pressable,
  SimpleGrid,
  Text,
} from "native-base";
import React, { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { useDispatch } from "react-redux";

const Servicebox = () => {
  const dispatch = useDispatch();
  const [service_data, setServiceData] = useState([]);

  const { items: services_items, loading } =useDynamicSelector("get_cms_services");

  const ServiceBox = [
    {
      name: "Schemes",
      subtitle: "(ELITE / MIMS / CDS/ HCI / 3% SPORTS QUOTA)",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/application.png",
      link: "https://schemes.sdat.in/",
    },
    {
      name: "TN Champions Foundation",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/trophy.png",
      link: "https://tnchampions.sdat.in/home",
    },
    {
      name: "Stadium Booking",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Stadium.jpg",
      link: "https://scms.sdat.broadline.co.in/#/stadium-booking/login",
    },
    {
      name: "Membership Booking",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Membership%20Booking.jpg",
      link: "https://scms.sdat.broadline.co.in/#/membership-booking/login",
    },
    {
      name: "Licensed Premises Management System",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Shopping.jpg",
      link: "https://scms.sdat.broadline.co.in/#/shopping-complex/login",
    },
    {
      name: "Sportsperson Scheme",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Sportsperson.jpg",
      link: "",
      status: "Coming Soon",
    },
    {
      name: "CM Trophy Games",
      img: "https://v-lfa-assets.blr1.vultrobjects.com/c25447a0-8de4-4bc9-801b-3e2c1d1d7722.png",
      // link: "https://www.sdat.in/cmtrophy",
      status: "Coming Soon",
    },
    // {
    //   name: "High Cash Incentive",
    //   img: "https://sdat-cmtrophy-assets-new.s3.ap-south-1.amazonaws.com/pic+2.png",
    //   url: ROUTES.HIGH_CASE_INCENTIVE,
    // },

    // {
    //   name: "Association Recognition",
    //   img: "https://sdat-cmtrophy-assets-new.s3.ap-south-1.amazonaws.com/PIC+11.png",
    // },
    // {
    //   name: "Coaches Registrations",
    //   img: "https://sdat-cmtrophy-assets-new.s3.ap-south-1.amazonaws.com/pic+7.png",
    // },
    {
      name: "Sports Hostel",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Sports%20Hostel%20Online%20Application.jpg",
      // link: "https://www.sdat.tn.gov.in/core/hostel/hostel/Sports_Hostel_SH_completed.php",
      status: "Coming Soon",
    },
    {
      name: "CM State Youth Award",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/CM%20State%20Youth%20Award.jpg",
      // link: "https://www.sdat.tn.gov.in/core/schemes/cm_youth_award/cm_youth_award_closed_application.php",
      status: "Coming Soon",
    },
    {
      name: "Sportsperson Pension Scheme",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Sportsperson%20Pension%20Scheme.jpg",
      // link: "https://www.sdat.tn.gov.in/core/schemes/sports_person_pension/pension_scheme_completed.php",
      status: "Coming Soon",
    },
    {
      name: "Grievance redressal",
      img: "https://v-sdat-website-assets.blr1.vultrobjects.com/Grievance.jpg",
      link: "https://cmhelpline.tnega.org/portal/ta/home",
    },
  ];

  const get_services = () => {
    let keys = [{ key: "get_cms_services", loading: true }];
    let variables = {
      json: {
        system_cms_key: "services",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_services_query, variables));
  };

  useEffect(() => {
    get_services();
  }, []);

  useEffect(() => {
    let values = FilterItems(services_items);
    setServiceData(values?.sort((a, b) => Number(a?.order?.value) - Number(b?.order?.value)));
  }, [services_items]);

  const handle_direct_page = (url) => {
    window.open(url, "_black");
  };
  return (
    <>
      <SimpleGrid
        columns={{
          base: 2,
          sm: 2,
          xs: 1,
          md: 2,
          lg: 3,
          xl: 3,
          "2xl": 5,
          "3xl": 6,
        }}
        space={5}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {service_data.map((service_list) => {
          return (
            <Pressable
              onPress={
                !service_list?.status?.value?.includes("Coming Soon")
                  ? () => handle_direct_page(service_list?.link?.value)
                  : {}
              }
              cursor={
                service_list?.status?.value?.includes("Coming Soon")
                  ? "not-allowed"
                  : "pointer"
              }
            >
              <Card variant={"service_card"}>
                {/* <Box variant={"Servicebox"}> */}
                <Box variant={"Service_left"}>
                  <div className="service-left-icon">
                    <img
                      src={service_list?.image?.value}
                      alt="${service_list.name}"
                      width={"30px"}
                    />
                  </div>
                </Box>
                <Box
                  variant={"Service_right"}
                  _text={{ fontWeight: "600" }}
                  textAlign={"start"}
                >
                  <div className="FiArrowUpRight">
                    <FiArrowUpRight />
                  </div>
                  <Text
                    fontWeight={"600"}
                    paddingRight={"20px"}
                    fontSize={"var(--font-size-16)"}
                    bold
                  >
                    {service_list?.name?.value}
                  </Text>
                  <Text
                    fontWeight={"600"}
                    paddingRight={"20px"}
                    fontSize={"var(--font-size-12)"}
                  >
                    {service_list?.subtitle?.value}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      marginTop: "30px",
                      position: "absolute",
                      width: "100%",
                    }}
                  >
                    <Text
                      bold
                      color={"gray.500"}
                      fontSize={"var(--font-size-14)"}
                    >
                      {service_list?.status?.value}
                    </Text>
                  </div>
                </Box>

                {/* </Box> */}
              </Card>
            </Pressable>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default Servicebox;
