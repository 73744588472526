import { Box, HStack, Image, Stack, Text } from "native-base";
import React from "react";
// import KabaddiPlay from "../../../assets/images/menu/kabaddiplay.png";
// import Kabbadi from "../../../assets/images/menu/kabaddi.jpg";
import Logo from "@assets/images/SDAT New English Logo.png";
import { useTranslation } from "react-i18next";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { FilterItems } from "@helpers/constants";
import { get_cms_aboutus_history_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_aboutus_history_query";
import { Skeleton } from "antd";
import AboutUsLoading from "./about_us_loading";

const AboutUsHistoryContents = ({ about_us_data }) => {
  const { t } = useTranslation();

  let about_us_data_change = [about_us_data?.[0]];
  return (
    <>
      <HStack>
        {about_us_data_change?.map((item, index) => {
          return (
            <Box
              variant={"About_us_history_contents_box"}
              height={["800px", "800px", "400px", "400px", "400px", "400px"]}
            >
              <Box
                variant="BackGroundImageBox"
                height={["800px", "800px", "400px", "400px", "400px", "400px"]}
              >
                <Image
                  variant="SportEventBackGroundImage"
                  height={"full"}
                  source={{
                    uri: item?.image?.value,
                  }}
                />
              </Box>

              <Stack
                display={"flex"}
                flexDirection={["column", "column", "row", "row", "row", "row"]}
                justifyContent={"space-around"}
                width={"100%"}
              >
                <Box
                  flex={1}
                  justifyContent={"center"}
                  paddingLeft={["55px", "30px", "55px", "25px", "55px", "55px"]}
                  paddingRight={["55px", "40px", "55px", "0px", "0px", "0px"]}
                  width={["", "", "", "", "700px", "500px", "900px"]}
                  textAlign={{ xs: "justify" }}
                >
                  <Text variant="history_headline_text_one">
                    {t("history")}
                  </Text>
                  <Text color={"#fff"} fontFamily="Inter" mb={"20px"} fontSize={"var(--font-size-14)"}>
                    {item?.content?.value}
                  </Text>
                  {/* <Text color={"#fff"} fontFamily="Inter">
                  {item?.content?.value}
                </Text> */}
                </Box>
                <Box
                  flex={1}
                  justifyContent={"center"}
                  paddingRight={[
                    "55px",
                    "15px",
                    "55px",
                    "25px",
                    "55px",
                    "55px",
                  ]}
                  paddingLeft={{ md: "20px" }}
                  alignItems={["end", "center", "end", "end", "end", "end"]}
                  width={"100%"}
                >
                  <Box
                    height={"300px"}
                    width={["500px", "350px", "", "", "", "", "500px"]}
                    style={{ borderRadius: "10px" }}
                  >
                    <img src={item?.image?.value} className="history-image" />
                  </Box>
                  <Box
                    width={"90px"}
                    height={"91px"}
                    position={"absolute"}
                    mt={["150px"]}
                    mr={[
                      "25px",
                      "-154px",
                      "50px",
                      "50px",
                      "50px",
                      "50px",
                      "50px",
                    ]}
                  >
                    <Image
                      size={"full"}
                      source={{
                        uri: Logo,
                      }}
                    />
                  </Box>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </HStack>
    </>
  );
};

export default AboutUsHistoryContents;
