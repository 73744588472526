import { Box, HStack, Hidden, Image, Text, VStack } from "native-base";
import React from "react";
import YouthHostel from "@assets/images/menu/youth_hostel.png";

const YouthHostels = () => {
  return (
    <Box w="100%">
      <Hidden from="base" till="lg">
        <HStack space="30px">
          <Box w={"40%"}>
            <img src={YouthHostel} size={"100%"} />
          </Box>
          <VStack space={"10px"} width={"50%"}>
            <Text bold fontSize={"var(--font-size-20)"}>
              Youth Hostels
            </Text>
            <Text fontSize={"var(--font-size-14)"} textAlign={"justify"}>
              It is a joint venture programme between Government of India and
              Government of Tamil Nadu. The Youth Hostels provide inexpensive
              accommodation facilities to youth while undergoing educational and
              adventure tours, visits to historical places and cultural
              interest, etc. The State Government provides land for the
              construction of Youth Hostel whereas the Central Government bears
              the construction and maintenance cost. There are five Youth
              Hostels functioning in Chennai, Madurai, Thanjavur, Ooty and
              Tiruchirappalli.
            </Text>
          </VStack>
        </HStack>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <VStack space="30px" alignItems={"center"}>
          <Box w={"70%"}>
            <img src={YouthHostel} size={"100%"} />
          </Box>
          <VStack space={"10px"} width={"70%"}>
            <Text bold fontSize={"var(--font-size-20)"}>
              Youth Hostels
            </Text>
            <Text fontSize={"var(--font-size-14)"} textAlign={"justify"}>
              It is a joint venture programme between Government of India and
              Government of Tamil Nadu. The Youth Hostels provide inexpensive
              accommodation facilities to youth while undergoing educational and
              adventure tours, visits to historical places and cultural
              interest, etc. The State Government provides land for the
              construction of Youth Hostel whereas the Central Government bears
              the construction and maintenance cost. There are five Youth
              Hostels functioning in Chennai, Madurai, Thanjavur, Ooty and
              Tiruchirappalli.
            </Text>
          </VStack>
        </VStack>
      </Hidden>
    </Box>
  );
};
export default YouthHostels;
