import { showNotification } from '@helpers/notify'
import { showToast } from '@helpers/toast'
import { dynamicClear, dynamicRequest, useDynamicSelector } from '@services/redux'
import { mutation_sports_createSystemCmsSportsData, mutation_sports_updateSystemCmsSportsData } from '@services/redux/slices/dynamic_entity/graphql/graphql_cms_sports_hostal'
import { mutation_create_system_cms_sports_data, mutation_update_system_cms_sports_data } from '@services/redux/slices/dynamic_entity/graphql/graphql_hostal_details'
import Antd_form from '@views/components/ui/antd_form'
import RichText from '@views/components/ui/form/rich_text'
import { Button, Form, Input } from 'antd'
import { HStack } from 'native-base'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

const HostelDetails = ({ form_values, onCancel, get_hostel_details }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { loading: create_loading, status: create_status, error: create_error } = useDynamicSelector("createSystemCmsSportsDataValue");
  const { loading: update_loading, status: update_status, error: update_error } = useDynamicSelector("updateSystemCmsSportsDataValue");
  const { state } = useLocation()

  const handle_submit = (values) => {
    if (form_values?.id) {
      update_one_sports(values)
    } else {
      create_one_sports(values)
    }
  }

  const create_one_sports = (data) => {
    let keys = [{ key: "createSystemCmsSportsDataValue", loading: true }];
    let variables = {
      json: {
        data: { ...data, system_cms_sports_data_id: state.id }
      },
    };
    dispatch(dynamicRequest(keys, mutation_create_system_cms_sports_data, variables));
  };

  const update_one_sports = (data) => {
    let keys = [{ key: "updateSystemCmsSportsDataValue", loading: true }];
    let variables = {
      json: {
        id: form_values?.id,
        data: { ...data, system_cms_sports_data_id: state.id }
      },
    };
    dispatch(dynamicRequest(keys, mutation_update_system_cms_sports_data, variables));
  };

  useEffect(() => {
    if (update_error) {
      showToast({
        type: "error",
        message: create_error,
      });
      dispatch(dynamicClear("updateSystemCmsSportsDataValue"))

    } else if (update_status === "success") {
      showToast({
        type: "success",
        message: `Hostel details updated successfully`,
      });
      onCancel()
      get_hostel_details()
      dispatch(dynamicClear("updateSystemCmsSportsDataValue"))

    } else if (create_error) {
      showToast({
        type: "error",
        message: create_error,
      });
      dispatch(dynamicClear("createSystemCmsSportsDataValue"))

    } else if (create_status === "success") {
      showToast({
        type: "success",
        message: `Hostel details created successfully`,
      });
      onCancel()
      get_hostel_details()
      dispatch(dynamicClear("createSystemCmsSportsDataValue"))

    }
  }, [update_status, update_error, create_error, create_status]);

  useEffect(() => {
    if (form_values.id) {
      form.setFieldsValue(form_values)
    } else {
      form.resetFields()
    }
  }, [form_values])
  const handle_close = () => {
    onCancel()
    form.resetFields()
  }
  return (
    <div >
      <Form onFinish={handle_submit} form={form} layout='vertical'>
        <Form.Item name={"name"} label={"Name"} rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}>
          <Input />
        </Form.Item>
        <Antd_form.RichText label={"Description"} field={"details"} rules={[
          {
            required: true,
            message: "Description is required",
          },
        ]} />
        <HStack justifyContent={"flex-end"} space={"20px"} >
          <Button danger onClick={handle_close} >
            Close
          </Button>
          <Button loading={create_loading || update_loading} htmlType='submit' type='primary'>
            {form_values?.id ? "Update" : "Create"}
          </Button>
        </HStack>
      </Form>
    </div>
  )
}

export default HostelDetails
