import InfrastructureContents from "@views/components/infrastructure/infrastructure_contents";
import React from "react";

const InfrastructurePage = () => {
  return (
    <div>
      <InfrastructureContents />
    </div>
  );
};

export default InfrastructurePage;
