import { Box } from 'native-base'
import React from 'react'
import Header from './banner'
import MenuBar from './menubar'

const HeaderPage = () => {
    return (
        <Box>
            < Header />
            {/* <MenuBar /> */}
        </Box>
    )
}

export default HeaderPage