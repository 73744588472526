import { Box, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExecutiveComponent from "./executive_component";
// import StalinImage from "@assets/images/menu/stalin_image.png";
// import Ramachandran from "@assets/images/menu/Narayana_Ramachandran.jpg";
// import AshokSigamani from "@assets/images/menu/Ashok Sigamani.png";
// import AdditionalCs from "@assets/images/menu/as.jpg";
// import MsIas from "@assets/images/menu/ms.jpg";
import { Button } from "antd";
import { useEffect } from "react";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { get_cms_executive_committee_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_executive_committee_query";
import ExecutiveComponentLoading from "./executive_committee_loading";

const ExecutiveContents = ({ executive_data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expend, setexpend] = useState(true);
  const onExpendable = () => {
    setexpend(!expend);
  };
  let person_details = [];
  if (expend) {
    person_details?.push(executive_data?.slice(0, 3));
  } else {
    person_details?.push(executive_data);
  }
  return (
    <VStack>
      <Box padding={"60px"}>
        <Text variant="HeadLineTextOne">{t("executive_committee")}</Text>
      </Box>
      <div
        className={`executive-component-box ${expend ? `active` : `inactive`}`}
      >
        <Box marginTop={"70px"}>
          <ExecutiveComponent persondetails={person_details[0]} />
        </Box>
      </div>
      {executive_data?.length > 3 ? (
        <div className="view-all-button">
          <Button
            onClick={() => {
              onExpendable();
            }}
            style={{
              borderRadius: "12px",
              width: "fit-content",
              height: "40px",
              border: "1px solid #3B1B7E",
            }}
          >
            <Text color={"#3B1B7E"}>
              {expend
                ? t("view_all_committee_members")
                : t("less_committee_members")}
            </Text>
          </Button>
        </div>
      ) : (
        ""
      )}
    </VStack>
  );
};
export default ExecutiveContents;
