import { Skeleton } from "antd";
import { Box, Card, Center, HStack, Hidden, VStack } from "native-base";
import React from "react";
import Carousel from "react-multi-carousel";
import { Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const HomePageLoading = () => {
  return (
    <Box>
      <Hidden from="base" till="md">
        <VStack space={"20px"}>
          {/* menubar */}
          <Box>
            <Center>
              <HStack space="25px">
                <Skeleton.Avatar active />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Button active className="menu-button" />
                <Skeleton.Avatar active />

              </HStack>
            </Center>
          </Box>
          {/*homepage slide image */}
          <Box>
            <Skeleton.Image active style={{ width: "100%", height: "400px" }} />
          </Box>
          {/*about_us homepage */}
          <Box padding={"20px"}>
            <VStack space={"20px"}>
              <HStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </HStack>
              <HStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </HStack>
            </VStack>
          </Box>

          {/*cm trophy h"omepage */}
          <Box px="30px">
            <HStack space={"20px"} alignItems={"center"}>
              <Skeleton.Image active />
              <Skeleton active />
            </HStack>
          </Box>

          {/*twitter gallery homepage */}
          <Box px="30px">
            <HStack space="50px">
              <Box variant="TwitterContentContainer" height={"450px"}>
                <Skeleton active />
                <Skeleton active />
                <Skeleton active />
              </Box>
              <Box>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  Swiper
                  navigation={true}
                  mousewheel={true}
                  modules={[Navigation, Mousewheel]}
                  loopedSlides={true}
                  className="mySwiper"
                >
                  <Box
                    width={{
                      base: "400px",
                      md: "726px",
                      lg: "700px",
                      xl: "55vw",
                    }}
                  >
                    <SwiperSlide>
                      <HStack space={"10px"}>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image active style={{ height: "500px" }} />
                        </Box>
                      </HStack>
                    </SwiperSlide>
                  </Box>
                </Swiper>
              </Box>
            </HStack>
          </Box>

          {/*policy note*/}
          <Box px="40px">
            <Skeleton active />
          </Box>
        </VStack>
      </Hidden>
      <Hidden from="md" till="3xl">
        <VStack space={"20px"}>
          {/* menubar */}
          <Box>
            <Center>
              <HStack space="25px">
                <Skeleton.Button active />
                <Skeleton.Button active />
                <Skeleton.Button active />
              </HStack>
            </Center>
          </Box>
          {/*homepage slide image */}
          <Box>
            <Skeleton.Image active style={{ width: "100%", height: "400px" }} />
          </Box>
          {/*about_us homepage */}
          <Box padding={"20px"}>
            <VStack space={"20px"}>
              <VStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </VStack>
              <VStack space={"20px"} alignItems={"center"}>
                <Skeleton.Image
                  active
                  style={{ width: "300px", height: "200px" }}
                />
                <Skeleton active></Skeleton>
              </VStack>
            </VStack>
          </Box>

          {/*cm trophy h"omepage */}
          <Box px="30px">
            <HStack space={"20px"} alignItems={"center"}>
              <Skeleton.Image active />
              <Skeleton active />
            </HStack>
          </Box>

          {/*twitter gallery homepage */}
          <Box px="30px">
            <VStack space="50px">
              <Center variant="galleryAndTwitterTitle">
                <Box variant="TwitterContentContainer" height={"450px"}>
                  <Skeleton active />
                  <Skeleton active />
                  <Skeleton active />
                </Box>
              </Center>
              <Box>
                <Swiper
                  slidesPerView={3}
                  spaceBetween={30}
                  Swiper
                  navigation={true}
                  mousewheel={true}
                  modules={[Navigation, Mousewheel]}
                  loopedSlides={true}
                  className="mySwiper"
                >
                  <Box
                    width={{
                      base: "400px",
                      md: "726px",
                      lg: "700px",
                      xl: "55vw",
                    }}
                  >
                    <SwiperSlide>
                      <HStack space={"10px"}>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image
                            active
                            style={{ height: "500px", width: "200px" }}
                          />
                        </Box>
                        <Box variant="galleryImageShape">
                          <Skeleton.Image active style={{ height: "500px" }} />
                        </Box>
                      </HStack>
                    </SwiperSlide>
                  </Box>
                </Swiper>
              </Box>
            </VStack>
          </Box>

          {/*policy note*/}
          <Box px="40px">
            <Skeleton active />
          </Box>
        </VStack>
      </Hidden>
    </Box>
  );
};
export default HomePageLoading;
