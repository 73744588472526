import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_page_query } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    item: null,
};

const slice = createSlice({
    name: "pageGet",
    initialState,
    reducers: {
        _get_one_page: (state) => {
            state.loading = true;
            state.item = null;
            state.error = null;
        },

        _get_one_page_success: (state, { payload }) => {
            state.loading = false;
            state.item = payload;
            state.error = null;
        },
        _get_one_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
            state.item = null;
        },
    },
});

const { _get_one_page, _get_one_page_success, _get_one_page_failure } =
    slice.actions;

export const getPageSelector = (state) => state.pageGet;

export const getPageReducer = slice.reducer;

export function get_one_page(variables) {
    return async (dispatch) => {
        dispatch(_get_one_page());
        try {
            const response = await QueryRequest(
                get_one_page_query,
                variables,
                dispatch
            );
            if (response?.data?.getPage && !response?.data?.getPage.error) {
                dispatch(_get_one_page_success(response?.data?.getPage));
            } else if (response?.data?.getPage?.error) {
                dispatch(_get_one_page_failure(response?.data?.getPage.error));
            }
        } catch (error) {
            dispatch(_get_one_page_failure(error));
        }
    };
}
export function get_one_page_clear() {
    return async (dispatch) => {
        dispatch(_get_one_page());
    };
}
