import { Menu, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import Logo from "@assets/images/SDAT New English Logo.png";
import GovernmentLogo from "@assets/images/menu/government_logo.png";
// import UdhayanidhiStalin from "@assets/images/menu/udhayanidhi_stalin.png";
import Udhayanidhi_image from "@assets/images/Deputy CM.png";
import Stalin from "@assets/images/menu/Hon_CM_Photo1.png";
// import Kalaignar from "@assets/images/menu/100_kalaignar.png";
import logo_image from "@assets/images/menu/logo_image.png";

import {
  HStack,
  Center,
  Box,
  Image,
  Hidden,
  Pressable,
  Stack,
} from "native-base";
import { TopMenu, SideMenu, FilterItems } from "@helpers/constants";
import { HiMenu } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { color_gradients } from "@helpers/constants";
import "@src/index.css";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch, useSelector } from "react-redux";
import { get_cms_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms";
import { get_cms_rti_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_rti_query";
import { get_cms_right_to_person_disabilities_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_right_to_person_disabilities_query";
import { get_cms_state_association_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_state_association";
import { get_cms_state_youth_policy_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_state_youth_policy";
import {
  get_cms_national_youth_policy_query,
  get_cms_press_release_event_query,
} from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_national_youth_policy";
import { get_cms_post_and_level_of_pay_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_post_and_level_of_pay_query";
import { get_cms_public_information_officers_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_public_information_officers_query";

export const MenuBarContent = (props) => {
  const {
    data,
    rti_data,
    state_data,
    state_policy_data,
    national_policy_data,
    press_release_event,
    post_data,
    public_data,
  } = props;
  const [current, setCurrent] = useState("");

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleHome = () => {
    history.push(ROUTES.HOME);
  };

  const onMenuClick = (e) => {
    if (e?.key == "service") {
      return history.push(ROUTES.SERVICE);
    } else if (e?.Key == "sports_bodies") {
      return window.location.href("/admin");
    } else if (e?.key == "about_us") {
      return history.push(ROUTES.ABOUT_US);
    } else if (e?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (e?.key == "dept_of_youth_welfare") {
      return history.push(ROUTES.YOUTH_WALFARE);
    } else if (e?.key == "contact_us") {
      return history.push(ROUTES.CONTACT_US);
    } else if (e?.key == "sports") {
      return history.push(ROUTES.SPORTS);
    } else if (e?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (e?.key == "competitions") {
      return history.push(ROUTES.COMPETITIONS);
    } else if (e?.key == "schemes") {
      return history.push(ROUTES.SCHEMES);
    } else if (e?.key == "high_cash_incentive") {
      return history.push(ROUTES.HIGH_CASE_INCENTIVE);
    } else if (e?.key == "sports_hostel") {
      return history.push(ROUTES.SPORTS_HOSTEL);
    } else if (e?.key == "sports_hostel_of_excellence") {
      return history.push(ROUTES.SPORTS_HOSTEL_OF_EXCELLENCE);
    } else if (e?.key == "centre_of_excellence") {
      return history.push(ROUTES.CENTRE_OF_EXCELLENCE);
    } else if (e?.key == "tenders") {
      return history.push(ROUTES.TENDER);
    } else if (e?.key == "rti_questions") {
      return history.push(ROUTES.RTI_QUESTIONS);
    } else if (e?.key == "faqs") {
      return history.push(ROUTES.FAQS);
    } else if (e?.key == "government_orders") {
      return history.push(ROUTES.GOVERNMENT_ORDER);
    } else if (e?.key == "vigilance_and_anti_corruption") {
      return history.push(ROUTES.VIGILANCE_ANTI_CORRUPTION);
    } else if (e?.key == "infrastructure") {
      return history.push(ROUTES.INFRASTRUCTURE);
    } else if (e?.key == "state_accusations") {
      return history.push(ROUTES.ADMIN);
    } else if (e?.key == "budget_and_expenditure_annual_report") {
      return history.push(ROUTES.APPLICATION_FORM);
    } else if (e?.Key == "national_sports_code") {
      return history.push(ROUTES.ADMIN);
    } else if (e?.Key == "scheme_categories") {
      return history.push(ROUTES.SCHEMES);
    } else if (e?.key == "national_youth_policy") {
      return window.open(national_policy_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "state_youth_policy") {
      return window.open(state_policy_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "post_and_level_of_pay") {
      return window.open(post_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "public_information_officers") {
      return window.open(public_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "right_of_persons_with_disabilities") {
      return window.open(data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "budget_and_expenditure_annual_report") {
      return window.open("https://sdat.tn.gov.in/pdf/rpwd.pdf", "_blank");
    } else if (e?.key == "gallery") {
      return history.push(ROUTES.GALLERY);
    } else if (e?.key == "state_association") {
      return window.open(state_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "officer_login") {
      return window.open("https://sdat.tn.gov.in/app/", "_blank");
    } else if (e?.key == "youth_hostel") {
      return history.push(ROUTES.YOUTH_HOSTEL);
    } else if (e?.key == "press_release") {
      return history.push(ROUTES.PRESS_RELEASE);
    }
  };
  const handle_select = (e) => {
    if (e?.target?.innerText == "RTI") {
      return window.open(rti_data?.[0]?.document?.value, "_blank");
    }
  };
  useEffect(() => {
    if (pathname?.key == "service") {
      return history.push(ROUTES.SERVICE);
    } else if (pathname?.Key == "sports_bodies") {
      return window.location.href("/admin");
    } else if (pathname?.key == "about_us") {
      return history.push(ROUTES.ABOUT_US);
    } else if (pathname?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.key == "dept_of_youth_welfare") {
      return history.push(ROUTES.YOUTH_WALFARE);
    } else if (pathname?.key == "contact_us") {
      return history.push(ROUTES.CONTACT_US);
    } else if (pathname?.key == "sports") {
      return history.push(ROUTES.SPORTS);
    } else if (pathname?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.key == "competitions") {
      return history.push(ROUTES.COMPETITIONS);
    } else if (pathname?.key == "schemes") {
      return history.push(ROUTES.SCHEMES);
    } else if (pathname?.key == "high_cash_incentive") {
      return history.push(ROUTES.HIGH_CASE_INCENTIVE);
    } else if (pathname?.key == "sports_hostel") {
      return history.push(ROUTES.SPORTS_HOSTEL);
    } else if (pathname?.key == "sports_hostel_of_excellence") {
      return history.push(ROUTES.SPORTS_HOSTEL_OF_EXCELLENCE);
    } else if (pathname?.key == "centre_of_excellence") {
      return history.push(ROUTES.CENTRE_OF_EXCELLENCE);
    } else if (pathname?.key == "tenders") {
      return history.push(ROUTES.TENDER);
    } else if (pathname?.key == "rti_questions") {
      return history.push(ROUTES.RTI_QUESTIONS);
    } else if (pathname?.key == "faqs") {
      return history.push(ROUTES.FAQS);
    } else if (pathname?.key == "government_orders") {
      return history.push(ROUTES.GOVERNMENT_ORDER);
    } else if (pathname?.key == "vigilance_and_anti_corruption") {
      return history.push(ROUTES.VIGILANCE_ANTI_CORRUPTION);
    } else if (pathname?.key == "infrastructure") {
      return history.push(ROUTES.INFRASTRUCTURE);
    } else if (pathname?.key == "state_accusations") {
      return history.push(ROUTES.ADMIN);
    } else if (pathname?.key == "budget_and_expenditure_annual_report") {
      return history.push(ROUTES.APPLICATION_FORM);
    } else if (pathname?.Key == "national_sports_code") {
      return history.push(ROUTES.ADMIN);
    } else if (pathname?.Key == "sports_bodies") {
      return window.location.href("/admin");
    } else if (pathname?.Key == "scheme_categories") {
      return history.push(ROUTES.SCHEMES);
    } else if (pathname?.key == "national_youth_policy") {
      return window.open(
        "https://sdat.tn.gov.in/pdf/Draft%20National%20Youth%20Policy%2029.09.2022.pdf",
        "_blank"
      );
    } else if (pathname?.key == "state_youth_policy") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.key == "gallery") {
      return history.push(ROUTES.GALLERY);
    } else if (pathname?.key == "press_release") {
      return history.push(ROUTES.PRESS_RELEASE);
    }
  }, [pathname]);
  return (
    <Box bgImage={color_gradients.transparent_color}>
      <HStack
        // alignContent={"space-evenly"}
        justifyContent={"space-between"}
        // space={{ lg: "20rem", xl: "44rem" }}
        mt={"1rem"}
        ml={"31px"}
        mr={"31px"}
      >
        <Center>
          <HStack space={2} alignItems={"center"}>
            {/* <Pressable onPress={handleHome}>
              <Box width={"80px"} height={"80px"}>
                <Image
                  size={"full"}
                  source={{
                    uri: logo_image,
                  }}
                />
              </Box>
            </Pressable> */}
            <Pressable onPress={handleHome}>
              <Box width={"80px"} height={"80px"}>
                <Image
                  size={"full"}
                  source={{
                    uri: GovernmentLogo,
                  }}
                />
              </Box>
            </Pressable>
            {/* <Box width={"80px"} height={"80px"}>
              <Image
                size={"full"}
                source={{
                  uri: logo_image,
                }}
              />
            </Box> */}
            <Pressable onPress={handleHome}>
              <Box width={"80px"} height={"80px"}>
                <Image
                  size={"full"}
                  source={{
                    uri: Logo,
                  }}
                />
              </Box>
            </Pressable>
          </HStack>
        </Center>
        <Box
          width={{
            // lg: "60rem",
            xl: "auto",
          }}
          height={"6rem"}
          justifyContent="center"
        >
          <Hidden only={"lg"}>
            <Center>
              <Menu
                className="home_menu_bar"
                selectedKeys={[current]}
                style={{
                  background: color_gradients.transparent_color,
                  color: "white",
                  border: "0px",
                  width: "100%",
                  fontFamily: "Inter",
                  gap: "0.8rem",
                  fontWeight: "400",
                }}
                mode={"horizontal"}
                items={TopMenu(t)}
                onClick={onMenuClick}
              />
            </Center>
          </Hidden>
        </Box>
        <HStack space={2} alignItems={"center"} justifyContent={"center"}>
          <Box width={"80px"} height={"80px"} mt={"5px"}>
            <Image
              borderRadius={"40px"}
              size={"full"}
              source={{
                uri: Udhayanidhi_image,
              }}
            />
          </Box>
          <Box width={"82px"} height={"82px"}>
            <Image
              size={"full"}
              source={{
                uri: Stalin,
              }}
            />
          </Box>
        </HStack>
      </HStack>
      <HStack
        justifyContent={"center"}
        alignItems={"center"}
        display={{ xl: "none" }}
      >
        <Box>
          <Menu
            className="home_menu_bar"
            selectedKeys={[current]}
            style={{
              background: color_gradients.transparent_color,
              color: "white",
              border: "0px",
              width: "100%",
              fontFamily: "Inter",
              gap: "0.8rem",
              fontWeight: "400",
            }}
            mode={"horizontal"}
            items={TopMenu(t)}
            onClick={onMenuClick}
            onClickCapture={handle_select}
          />
        </Box>
      </HStack>
    </Box>
  );
};
const MenuBar = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("");
  const [open_menu, setOpenMenu] = useState(false);
  const { pathname } = useLocation();
  const [data, setData] = useState([]);
  const [rti_data, setRtiData] = useState([]);
  const [post_data, setPostData] = useState([]);
  const [public_data, setPublicData] = useState([]);

  const [state_data, setStateData] = useState([]);
  const [state_policy_data, setStatePolicyData] = useState([]);
  const [national_policy_data, setNationalPolicyData] = useState([]);
  const [press_release_event, set_press_release_event] = useState([]);

  const { items: rti_menu_items } = useDynamicSelector(
    "get_cms_right_to_person_disabilities"
  );
  const { items: rti_items } = useDynamicSelector("get_cms_rti_menu");
  const { items: post_and_level_items } = useDynamicSelector(
    "get_cms_post_and_level_of_pay_menu"
  );
  const { items: public_items } = useDynamicSelector(
    "get_cms_public_information_officers_menu"
  );
  console.log("post_and_level_items", post_and_level_items);

  const { items: state_items } = useDynamicSelector(
    "get_cms_state_association"
  );

  const { items: state_policy_items } = useDynamicSelector(
    "get_cms_state_youth_policy"
  );
  const { items: national_policy_items } = useDynamicSelector(
    "get_cms_national_youth_policy"
  );
  const { items: press_release } = useDynamicSelector(
    "get_cms_press_release_event"
  );

  const showDrawer = () => {
    setOpenMenu(true);
  };
  const onClose = () => {
    setOpenMenu(false);
  };
  const handleHome = () => {
    history.push(ROUTES.HOME);
  };

  const get_right_to_person_disabilities_menu = () => {
    let keys = [{ key: "get_cms_right_to_person_disabilities", loading: true }];
    let variables = {
      json: {
        system_cms_key: "right_to_person_with_disabilities_menu",
      },
    };
    dispatch(
      dynamicRequest(
        keys,
        get_cms_right_to_person_disabilities_query,
        variables
      )
    );
  };

  const get_rti_menu = () => {
    let keys = [{ key: "get_cms_rti_menu", loading: true }];
    let variables = {
      json: {
        system_cms_key: "rti_menu",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_rti_query, variables));
  };

  const get_post_and_level_menu = () => {
    let keys = [{ key: "get_cms_post_and_level_of_pay_menu", loading: true }];
    let variables = {
      json: {
        system_cms_key: "post_and_level_of_pay",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_post_and_level_of_pay_query, variables)
    );
  };

  const get_public_information_officers_menu = () => {
    let keys = [
      { key: "get_cms_public_information_officers_menu", loading: true },
    ];
    let variables = {
      json: {
        system_cms_key: "public_information_officers",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_public_information_officers_query, variables)
    );
  };

  const get_state_association_menu = () => {
    let keys = [{ key: "get_cms_state_association", loading: true }];
    let variables = {
      json: {
        system_cms_key: "state_association_menu",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_state_association_query, variables));
  };

  const get_state_youth_policy = () => {
    let keys = [{ key: "get_cms_state_youth_policy", loading: true }];
    let variables = {
      json: {
        system_cms_key: "state_youth_policy_menu",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_state_youth_policy_query, variables));
  };

  const get_national_youth_policy = () => {
    let keys = [{ key: "get_cms_national_youth_policy", loading: true }];
    let variables = {
      json: {
        system_cms_key: "national_youth_policy_menu",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_national_youth_policy_query, variables)
    );
  };
  const get_press_release_event = () => {
    let keys = [{ key: "get_cms_press_release_event", loading: true }];
    let variables = {
      json: {
        system_cms_key: "press_event",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_press_release_event_query, variables)
    );
  };

  useEffect(() => {
    get_right_to_person_disabilities_menu();
    get_rti_menu();
    get_state_association_menu();
    get_state_youth_policy();
    get_national_youth_policy();
    get_press_release_event();
    get_post_and_level_menu();
    get_public_information_officers_menu();
  }, []);

  useEffect(() => {
    let values = FilterItems(rti_items);
    setRtiData(values);
  }, [rti_items]);

  useEffect(() => {
    let values = FilterItems(post_and_level_items);
    setPostData(values);
  }, [post_and_level_items]);

  useEffect(() => {
    let values = FilterItems(public_items);
    setPublicData(values);
  }, [public_items]);

  useEffect(() => {
    let values = FilterItems(rti_menu_items);
    setData(values);
  }, [rti_menu_items]);

  useEffect(() => {
    let values = FilterItems(state_items);
    setStateData(values);
  }, [state_items]);

  useEffect(() => {
    let values = FilterItems(state_policy_items);
    setStatePolicyData(values);
  }, [state_policy_items]);

  useEffect(() => {
    let values = FilterItems(national_policy_items);
    setNationalPolicyData(values);
  }, [national_policy_items]);
  useEffect(() => {
    let values = FilterItems(press_release);
    set_press_release_event(values);
  }, [press_release]);

  const onMenuClick = (e) => {
    if (e?.key == "service") {
      return history.push(ROUTES.SERVICE);
    } else if (e?.Key == "sports_bodies") {
      return window.location.href("/admin");
    } else if (e?.key == "about_us") {
      return history.push(ROUTES.ABOUT_US);
    } else if (e?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (e?.key == "dept_of_youth_welfare") {
      return history.push(ROUTES.YOUTH_WALFARE);
    } else if (e?.key == "contact_us") {
      return history.push(ROUTES.CONTACT_US);
    } else if (e?.key == "sports") {
      return history.push(ROUTES.SPORTS);
    } else if (e?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (e?.key == "competitions") {
      return history.push(ROUTES.COMPETITIONS);
    } else if (e?.key == "schemes") {
      return history.push(ROUTES.SCHEMES);
    } else if (e?.key == "high_cash_incentive") {
      return history.push(ROUTES.HIGH_CASE_INCENTIVE);
    } else if (e?.key == "sports_hostel_of_excellence") {
      return history.push(ROUTES.SPORTS_HOSTEL_OF_EXCELLENCE);
    } else if (e?.key == "centre_of_excellence") {
      return history.push(ROUTES.CENTRE_OF_EXCELLENCE);
    } else if (e?.key == "tenders") {
      return history.push(ROUTES.TENDER);
    } else if (e?.key == "rti_questions") {
      return history.push(ROUTES.RTI_QUESTIONS);
    } else if (e?.key == "faqs") {
      return history.push(ROUTES.FAQS);
    } else if (e?.key == "government_orders") {
      return history.push(ROUTES.GOVERNMENT_ORDER);
    } else if (e?.key == "vigilance_and_anti_corruption") {
      return history.push(ROUTES.VIGILANCE_ANTI_CORRUPTION);
    } else if (e?.key == "infrastructure") {
      return history.push(ROUTES.INFRASTRUCTURE);
    } else if (e?.key == "state_accusations") {
      return history.push(ROUTES.ADMIN);
    } else if (e?.key == "budget_and_expenditure_annual_report") {
      return history.push(ROUTES.APPLICATION_FORM);
    } else if (e?.Key == "national_sports_code") {
      return history.push(ROUTES.ADMIN);
    } else if (e?.Key == "scheme_categories") {
      return history.push(ROUTES.SCHEMES);
    } else if (e?.key == "national_youth_policy") {
      return window.open(national_policy_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "state_youth_policy") {
      return window.open(state_policy_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "post_and_level_of_pay") {
      return window.open(post_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "public_information_officers") {
      return window.open(public_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "right_of_persons_with_disabilities") {
      return window.open(rti_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "budget_and_expenditure_annual_report") {
      return window.open("https://sdat.tn.gov.in/pdf/rpwd.pdf", "_blank");
    } else if (e?.key == "gallery") {
      return history.push(ROUTES.GALLERY);
    } else if (e?.key == "state_association") {
      return window.open(state_data?.[0]?.document?.value, "_blank");
    } else if (e?.key == "officer_login") {
      return window.open("https://sdat.tn.gov.in/app/", "_blank");
    } else if (e?.key == "press_release") {
      return history.push(ROUTES.PRESS_RELEASE);
    }
  };

  const handle_select = (e) => {
    if (e?.target?.innerText == "RTI") {
      return window.open(rti_data?.[0]?.document?.value, "_blank");
    }
  };

  useEffect(() => {
    if (pathname?.key == "service") {
      return history.push(ROUTES.SERVICE);
    } else if (pathname?.key == "about_us") {
      return history.push(ROUTES.ABOUT_US);
    } else if (pathname?.key == "dept_of_youth_welfare") {
      return history.push(ROUTES.YOUTH_WALFARE);
    } else if (pathname?.key == "contact_us") {
      return history.push(ROUTES.CONTACT_US);
    } else if (pathname?.key == "sports") {
      return history.push(ROUTES.SPORTS);
    } else if (pathname?.key == "home") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.key == "competitions") {
      return history.push(ROUTES.COMPETITIONS);
    } else if (pathname?.key == "schemes") {
      return history.push(ROUTES.SCHEMES);
    } else if (pathname?.key == "high_cash_incentive") {
      return history.push(ROUTES.HIGH_CASE_INCENTIVE);
    } else if (pathname?.key == "sports_hostel") {
      return history.push(ROUTES.SPORTS_HOSTEL);
    } else if (pathname?.key == "sports_hostel_of_excellence") {
      return history.push(ROUTES.SPORTS_HOSTEL_OF_EXCELLENCE);
    } else if (pathname?.key == "centre_of_excellence") {
      return history.push(ROUTES.CENTRE_OF_EXCELLENCE);
    } else if (pathname?.key == "tenders") {
      return history.push(ROUTES.TENDER);
    } else if (pathname?.key == "rti_questions") {
      return history.push(ROUTES.RTI_QUESTIONS);
    } else if (pathname?.key == "faqs") {
      return history.push(ROUTES.FAQS);
    } else if (pathname?.key == "government_orders") {
      return history.push(ROUTES.GOVERNMENT_ORDER);
    } else if (pathname?.key == "vigilance_and_anti_corruption") {
      return history.push(ROUTES.VIGILANCE_ANTI_CORRUPTION);
    } else if (pathname?.key == "infrastructure") {
      return history.push(ROUTES.INFRASTRUCTURE);
    } else if (pathname?.key == "state_accusations") {
      return history.push(ROUTES.ADMIN);
    } else if (pathname?.key == "budget_and_expenditure_annual_report") {
      return history.push(ROUTES.APPLICATION_FORM);
    } else if (pathname?.Key == "national_sports_code") {
      return history.push(ROUTES.ADMIN);
    } else if (pathname?.Key == "sports_bodies") {
      return window.location.href("/admin");
    } else if (pathname?.Key == "scheme_categories") {
      return history.push(ROUTES.SCHEMES);
    } else if (pathname?.key == "national_youth_policy") {
      return window.open(
        "https://sdat.tn.gov.in/pdf/Draft%20National%20Youth%20Policy%2029.09.2022.pdf",
        "_blank"
      );
    } else if (pathname?.key == "state_youth_policy") {
      return history.push(ROUTES.HOME);
    } else if (pathname?.key == "gallery") {
      return history.push(ROUTES.GALLERY);
    } else if (pathname?.key == "press_release") {
      return history.push(ROUTES.PRESS_RELEASE);
    }
  }, [pathname]);

  return (
    <>
      <Hidden from="lg" till="3xl">
        <Box variant="menuBarBox">
          <HStack space={2} mt={{ xs: "1rem" }} ml={{ xs: "1rem" }}>
            <Box height={"100%"} justifyContent="center">
              <HiMenu color="white" size={"24px"} onClick={showDrawer} />
            </Box>
            <Stack
              width={"90%"}
              display={"flex"}
              flexDirection={"row"}
              space={"2px"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <HStack space={2} alignItems={"center"}>
                {/* <Pressable onPress={handleHome}>
                  <Box width={"50px"} height={"50px"}>
                    <Image
                      size={"full"}
                      source={{
                        uri: logo_image,
                      }}
                    />
                  </Box>
                </Pressable> */}
                <Box width={"50px"} height={"50px"}>
                  <Image
                    size={"full"}
                    source={{
                      uri: GovernmentLogo,
                    }}
                  />
                </Box>
                <Box width={"50px"} height={"50px"}>
                  <Image
                    size={"full"}
                    source={{
                      uri: Logo,
                    }}
                  />
                </Box>
              </HStack>
              <HStack space={2} alignItems={"center"}>
                <Box width={"50px"} height={"50px"}>
                  <Image
                    size={"full"}
                    source={{
                      uri: Udhayanidhi_image,
                    }}
                  />
                </Box>
                <Box width={"52px"} height={"52px"}>
                  <Image
                    size={"full"}
                    source={{
                      uri: Stalin,
                    }}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>
      </Hidden>
      <Box>
        <Hidden from="base" till="lg">
          <MenuBarContent
            data={data}
            rti_data={rti_data}
            post_data={post_data}
            public_data={public_data}
            state_data={state_data}
            state_policy_data={state_policy_data}
            national_policy_data={national_policy_data}
            press_release_event={press_release_event}
          />
        </Hidden>
        <Drawer
          placement="left"
          open={open_menu}
          onClose={onClose}
          // closable={false}
          // bodyStyle={{
          //   background:
          //     "linear-gradient(99.18deg, rgb(50, 13, 107) 13.03%, rgb(176 61 185) 92.33%)",
          // }}
        >
          <Menu
            selectedKeys={[current]}
            className="home_menu_bar"
            style={{
              background: "rgb(31 0 95 / 0%)",
              color: "black",
              border: "0px",
              width: "100%",
              fontFamily: "Inter",
              gap: "1.2rem",
              fontWeight: "400",
              fontSize: "20px !important",
            }}
            onClick={onMenuClick}
            mode={"inline"}
            // mode={isInline ? "inline" : "horizontal"}
            items={SideMenu(t)}
            onClickCapture={handle_select}

          />
          {/* <Menu.Item key="home">
              home
            </Menu.Item>
            </Menu> */}
        </Drawer>
      </Box>
    </>
  );
};
export default MenuBar;
