import { FilterItems, filter_items } from "@helpers/constants";
import {
  get_system_cms_field,
  getsystemCMSFieldListSelector,
} from "@services/redux";
import { Pagination } from "antd";
import { Box, HStack, Hidden, Link, Stack, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TableLoading from "../events/table_loading";

const RTIQuestionsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const { items, loading } = useSelector(getsystemCMSFieldListSelector);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const [data, setData] = useState([]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  useEffect(() => {
    dispatch(
      get_system_cms_field({
        json: {
          system_cms_key: "rti_questions_table",
        },
      })
    );
  }, []);
  useEffect(() => {
    let values = FilterItems(items);
    setData(values);
  }, [items]);
  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <Hidden from="base" till="lg">
            <table class="table">
              <thead className="table-header">
                <tr>
                  <th className="radius-right">{t("table:s_no")}</th>
                  <th style={{ width: "50%" }}>{t("information_sought")}</th>
                  <th className="radius-left" style={{ width: "50%" }}>
                    {t("reply_given")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map(
                  (item, index) => (
                    (
                      <tr
                        className={`space-add ${
                          currentItems.length - 1 !== index
                            ? "table-border-bottom"
                            : ""
                        }`}
                        key={item?.id}
                      >
                        <td data-label="S.No">{index + 1}</td>
                        <td
                          data-label="Tenders"
                          style={{ paddingRight: "20px" }}
                        >
                          {item?.infromation_sought?.value}
                        </td>
                        <td
                          data-label="Closing Date"
                          className="column-width"
                          style={{ paddingRight: "20px" }}
                        >
                          {item?.replay_given?.value}
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </Hidden>
          <Hidden from="lg" till="3xl">
            <Box variant={"tender_table_box"}>
              {data.map((content, index) => {
                return (
                  <>
                    <Box variant={"tender_table_contents"}>
                      <Text variant={"tender_description"}>
                        {content?.infromation_sought?.value}
                      </Text>
                      <Text>
                        <Text variant={"table_headline"} color={"#828282"}>
                          {t("reply_given")}:
                        </Text>
                        <Text
                          color={"black"}
                          fontWeight={"500"}
                          marginLeft={"1px"}
                        >
                          {content?.replay_given?.value}
                        </Text>
                      </Text>
                      {/* <Text>
                    <Text variant={"table_headline"} color={"#828282"}>
                      {t("reference_no")}:
                    </Text>
                    <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                      {content.reference_no}
                    </Text>
                  </Text> */}
                    </Box>
                  </>
                );
              })}
            </Box>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default RTIQuestionsTable;
