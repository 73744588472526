import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, VStack, Text, HStack, Pressable, Link } from "native-base";
import Dialog from "@views/components/ui/dialog";
import Wrapper from "@views/components/ui/wrapper";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { showToast } from "@helpers/toast";

import {
  getcmsListSelector,
  get_cms,
  deletecmsSelector,
  delete_one_cms_modal,
  delete_cms,
  get_one_cms_clear,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import Scroll_view from "@views/components/ui/scroll_view";
import { Button, Form, Modal, Pagination, Popconfirm, Space, Table } from "antd";
import SearchBox from "@views/components/ui/search_box/search_box";
import ActionButton from "@views/components/ui/dialog/action_button";
import CustomizeButton from "@views/components/ui/Button/button";
import { showNotification } from "@helpers/notify";
import { startCase } from "lodash";
import { countFormat } from "@helpers/constants";
import { mutation_sports_deleteSystemCmsSportsData, query_get_all_system_cms_sports_data } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_sports_hostal";
// import SportsHostelDetails from "./sports_hostel_details";
import { FaBuilding } from "react-icons/fa";
import GalleryDetails from "./gallery_details";
import { get_all_gallery_list, mutation_delete_gallery } from "@services/redux/slices/dynamic_entity/graphql/grahpql_cms_gallery";
const GalleryList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form_values, set_form_values] = useState({});
  const [actionItem, setActionItem] = useState({});
  const [manageModalVisible, setManageModalVisible] = useState(false);
  const [page_limit, setPageLimit] = useState(10);
  const [page_number, setPageNumber] = useState(1);
  const { t } = useTranslation();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [modal_details, set_modal_details] = useState(false);

  const [key, setKeys] = useState();

  const handleAdd = () => {
    set_form_values({})
    set_modal_details(true)
  };

  const handleEdit = (item) => {
    set_form_values(item)
    set_modal_details(true)
  };

  const handleDelete = (item) => {
    setActionItem(item);
    setDialogVisible(true);
  };

  const handleModalClose = () => {
    setManageModalVisible(false);
  };

  const handleDialogClose = () => {
    setDialogVisible(false);
  };
  const handleKeys = (keys) => {
    setKeys(keys.key);
  };
  const handleSearch = (letter) => {
    if (key == "Enter") {
      dispatch(get_cms_query({}));
    }
  };

  const { loading, total_items, items } = useDynamicSelector("getGalleryList");
  console.log("total_items",total_items,items);
  const {
    loading: deleteLoading,
    status: deleteStatus,
    error: deleteError,
  } = useDynamicSelector("deleteGallery");
  useEffect(() => {
    if (deleteError) {
      setDialogVisible(false);
      showToast({
        type: "error",
        message: deleteError,
      });
    } else if (deleteStatus === "success") {
      showToast({
        type: "success",
        message: `Gallery ${t("deleted_successfully")}`,
      });
      setDialogVisible(false);
      get_gallery_list()
      dispatch(dynamicClear("deleteGallery"));
      handleDialogClose();
    }
  }, [deleteStatus]);

  const handle_add_hostel = (record) => {
    history.push({
      pathname: `${ROUTES.ALBUM_LIST}/${record?.id}`,
      state: record,
    });

  }

  let columns = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sortable: false,
      align: "left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sortable: false,
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "",
      key: "is_active",
      sortable: false,
      align: "left",
      render: (item) => {
        return <Text>{item.is_active ? "Active" : "Inactive"}</Text>;
      },
    },
    {
      title: t("table:actions"),
      dataIndex: "",
      key: "actions",
      type: "actions",
      align: "center",
      type: "actions",
      width: 200,
      render: (e) => {
        return (
          <>
            <Space space={2}>
              <Pressable onPress={() => { handle_add_hostel(e) }}>
                <FaBuilding size={20} />
              </Pressable>
              <Pressable
                onPress={() => {
                  handleEdit(e);
                }}
              >
                <AiOutlineEdit color="#06b506" size={20} />
                {/* <CustomizeButton.IconButton icon={<AiOutlineEdit />} /> */}
              </Pressable>
              <Pressable
                onPress={() => {
                  handleDelete(e);
                }}
              >
                <AiOutlineDelete color="red" size={20} />
              </Pressable>
            </Space>
          </>
        );
      },
    },
  ];
  const get_gallery_list = () => {
    let keys = [{ key: "getGalleryList", loading: true }];
    let variables = {
        page_number: page_number, page_limit: page_limit
    };
    dispatch(dynamicRequest(keys, get_all_gallery_list, variables));
  };
  useEffect(() => {
    get_gallery_list()
  }, [page_number, page_limit]);

  let header_actions = [
    {
      height: "50px",
      label: t("table:add"),
      colorScheme: "primary",
      variant: "outline",
      _text: { color: "green" },
      style: { border: "1px solid green" },
      onPress: handleAdd,
    },
  ];
  const fetchData = (value, value1) => {
    setPageNumber(value);
    setPageLimit(value1);
  };
  const handle_close = () => {
    set_form_values({})
    set_modal_details(false)
  }
  const hadle_delete_gallery_details = (values) => {
    let keys = [{ key: "deleteGallery", loading: true }];
    let variables = {
        id: actionItem?.id
    };
    dispatch(dynamicRequest(keys, mutation_delete_gallery, variables));
  }
  return (
    <>
      <Box width="95%" marginLeft="20px" marginRight="20px">
        <Box position="sticky" top="0px" zIndex="2" bg="white">
          <Text fontSize="md" fontWeight="bold" py={4}>
            {"Gallery"}
          </Text>
        </Box>
        <HStack
          variant="admin_search_component"
          alignItems={"flex-end"}
          justifyContent={"flex-end"}
          space={3}
          padding={"10px"}
        >
          {header_actions?.map((headerAction, index) => (
            <Box key={`header-action-${index}`}>
              <ActionButton {...headerAction} />
            </Box>
          ))}
          <Box>
            <SearchBox
              width={300}
              label="Search"
              onSearch={handleSearch}
              onKeyPress={handleKeys}
            />
          </Box>
        </HStack>
        <VStack space={5}>
          <Wrapper>
            <Table
              loading={loading}
              dataSource={items}
              columns={columns}
            pagination={false}
            />
          </Wrapper>
          {!loading &&
            <HStack justifyContent={"flex-end"}>
              <Pagination
                total={total_items}
                page_number={page_number}
                hideOnSinglePage={false}
                onChange={fetchData}
                showTotal={(total, range) => `Total ${countFormat(total)}  items`}
                showSizeChanger
              />
            </HStack>
          }
        </VStack>
      </Box>
      <Modal open={modal_details} onCancel={handle_close} title={form_values?.id ? "Update Gallery" : "Create Gallery"} footer={null}>
        <GalleryDetails form_values={form_values} onCancel={handle_close} get_sports_hostel_list={get_gallery_list} />
      </Modal>
      <Dialog
        isOpen={dialogVisible}
        onClose={handleDialogClose}
        header={t("error:delete_gallery")}
        content={t("error:delete_cms_content")}
        source={actionItem}
        actions={[
          {
            height: "40px",
            label: t("cancel"),
            colorScheme: "blueGray",
            variant: "ghost",
            onPress: handleDialogClose,
          },
          {
            height: "40px",
            width: "80px",
            label: t("delete"),
            colorScheme: "danger",
            variant: "outline",
            isLoading: deleteLoading,
            formErrorMessage: t("error:error_message"),
            submit: hadle_delete_gallery_details,
          },
        ]}
      />
    </>
  );
};
export default GalleryList;
