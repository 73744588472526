import { showNotification } from "@helpers/notify";
import { showToast } from "@helpers/toast";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { Button, Checkbox, Form, Input, InputNumber, Upload } from "antd";
import { HStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import {
  mutation_create_gallery_image,
  mutation_update_gallery_image,
} from "@services/redux/slices/dynamic_entity/graphql/grahpql_cms_gallery_image";
import VForm from "@views/components/ui/antd_form";

const AlbumDetails = ({ form_values, onCancel, get_hostel_details }) => {
  console.log("form_values", form_values);

  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);
  const [form] = Form.useForm();
  const {
    loading: create_loading,
    status: create_status,
    error: create_error,
  } = useDynamicSelector("createGalleryImage");
  const {
    loading: update_loading,
    status: update_status,
    error: update_error,
  } = useDynamicSelector("updateGalleryImage");
  const { state } = useLocation();

  const handle_submit = (values) => {
    if (form_values?.id) {
      update_one_sports(values);
    } else {
      create_one_sports(values);
    }
  };

  const onChange = (e) => {
    console.log("checked = ", e.target.checked);
    setChecked(e.target.checked);
  };
  const create_one_sports = (data) => {
    let keys = [{ key: "createGalleryImage", loading: true }];
    console.log(data);
    let variables = {
      data: {
        ...data,
        image_url: data?.image_url?.[0].url,
        gallery_album_id: state.id,
      },
    };
    dispatch(dynamicRequest(keys, mutation_create_gallery_image, variables));
  };

  const update_one_sports = (data) => {
    let keys = [{ key: "updateGalleryImage", loading: true }];
    console.log(data);
    let variables = {
      id: form_values?.id,
      data: {
        ...data,
        image_url: data?.image_url?.[0].url,
        gallery_album_id: state.id,
      },
    };
    dispatch(dynamicRequest(keys, mutation_update_gallery_image, variables));
  };

  useEffect(() => {
    if (update_error) {
      showToast({
        type: "error",
        message: create_error,
      });
      dispatch(dynamicClear("updateGalleryImage"));
    } else if (update_status === "success") {
      showToast({
        type: "success",
        message: `Album details updated successfully`,
      });
      onCancel();
      get_hostel_details();
      dispatch(dynamicClear("updateGalleryImage"));
    } else if (create_error) {
      showToast({
        type: "error",
        message: create_error,
      });
      dispatch(dynamicClear("createGalleryImage"));
    } else if (create_status === "success") {
      showToast({
        type: "success",
        message: `Album details created successfully`,
      });
      onCancel();
      get_hostel_details();
      dispatch(dynamicClear("createGalleryImage"));
    }
  }, [update_status, update_error, create_error, create_status]);

  useEffect(() => {
    if (form_values.id) {
      form.setFieldsValue({
        ...form_values,
        image_url: [
          {
            uid: "-1",
            url: form_values?.image_url || "",
            status: "done",
            name: "View",
          },
        ],
      });
    } else {
      form.resetFields();
    }
  }, [form_values]);
  const handle_close = () => {
    onCancel();
    form.resetFields();
  };
  return (
    <div>
      <Form
        onFinish={handle_submit}
        form={form}
        layout="vertical"
        initialValues={{ image_url: [] }}
      >
        <Form.Item
          name={"order"}
          label={"Order"}
          rules={[
            {
              required: true,
              message: "Order is required",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <VForm.FormFile field={"image_url"} label="Image" maxCount={1} />

        <Form.Item name={"is_active"} valuePropName="checked" noStyle>
          <Checkbox>Active</Checkbox>
        </Form.Item>
        <HStack justifyContent={"flex-end"} space={"20px"}>
          <Button danger onClick={handle_close}>
            Close
          </Button>
          <Button
            loading={create_loading || update_loading}
            htmlType="submit"
            type="primary"
          >
            {form_values?.id ? "Update" : "Create"}
          </Button>
        </HStack>
      </Form>
    </div>
  );
};

export default AlbumDetails;
