import { gql } from "@apollo/client";

export const mutation_create_gallery = gql`
  mutation createGallery($data: create_gallery_input) {
    createGallery(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_update_gallery = gql`
  mutation updateGallery($id: String!, $data: update_gallery_input) {
    updateGallery(id: $id, data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_gallery = gql`
  mutation deleteGallery($id: String!) {
    deleteGallery(id: $id) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_gallery_list = gql`
  query getGalleryList(
    $search_string: String
    $page_number: Float
    $page_limit: Float
  ) {
    getGalleryList(
      search_string: $search_string
      page_number: $page_number
      page_limit: $page_limit
    ) {
      items {
        id
        name
        order
        is_active
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const get_one_gallery = gql`
  query getGallery($id: String!) {
    getGallery(id: $id) {
      id
      name
      order
      is_active
      error {
        status_code
        message
      }
    }
  }
`;

export const get_all_active_gallery_list = gql`
  query getGalleryActiveList {
    getGalleryActiveList {
      items {
        id
        name
        order
        is_active
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
