import { Box, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { RupeesFormat } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";

const CompetitionsResponsiveTable = (props) => {
  const { TableData } = props;
  const [open_index, setOpenIndex] = useState(0);
  const { t } = useTranslation();
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  return (
    <div>
      <table class="table">
        <thead className="table-header">
          <tr>
            <th className="radius-right table-list-padding-change">
              {t("table:s_no")}
            </th>
            <th className="radius-left table-list-padding-change">
              {t("table:name_of_competition")}
            </th>
          </tr>
        </thead>
        <tbody>
          {TableData?.map((tablelist, index) => {
            return (
              <>
                <tr
                  className={`space-add ${TableData?.length - 1 !== index ? "table-border-bottom" : ""
                    }`}
                >
                  <td
                    // className="table-list-padding-change"
                    style={{
                      alignItems: "flex-start",
                      position: "absolute",
                      padding: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td className="table-list-padding-change">
                    <div
                      onClick={() => handleExpendable(index)}
                      className={`table_row ${open_index === index
                          ? `table_row_height`
                          : `table_row_normal`
                        }`}
                    >
                      {open_index === index ? (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            {/* <Text width={"10px"}>-</Text> */}
                            <Box width={"20px"}>
                              <AiOutlineMinus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist?.title?.value}
                            </Text>
                          </HStack>
                        </p>
                      ) : (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            {/* <Text>+</Text> */}
                            <Box width={"20px"}>
                              <AiOutlinePlus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist?.title?.value}
                            </Text>
                          </HStack>
                        </p>
                      )}
                      <div
                        className={`table_open ${open_index === index
                            ? `table_active`
                            : `table_inactive`
                          }`}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={
                            tablelist?.content != 0
                              ? "column"
                              : "column-reverse"
                          }
                          gap={"10px"}
                        >
                          <div className="description_contents padding-description-changes">
                            {tablelist?.description?.value}
                          </div>
                          <img
                            src={tablelist?.image?.value}
                            alt="fd"
                            style={{ borderRadius: "10%" }}
                            width={"90%"}
                          />
                        </Box>
                        {/* {tablelist.map((s) => { */}
                        {/* return ( */}
                        <VStack mt={"10px"}>
                          {ReactHtmlParser(tablelist?.list?.value)}

                          {/* <Text
                                fontSize={"15px"}
                                fontFamily={"Inter"}
                                fontWeight={"bold"}
                              >
                                {s.level}
                              </Text>
                              <table
                                className="expendable-table"
                                style={{ width: "300px" }}
                              >
                                <thead>
                                  <tr className="expendable-table-header">
                                    <th className="radius-right padding-change">
                                      {t("table:first_price")}
                                    </th>
                                    <th className="padding-change">
                                      {t("table:second_price")}
                                    </th>
                                    <th className="radius-left padding-change">
                                      {t("table:third_price")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className={"expendable-table-contents"}>
                                    <td>{RupeesFormat(s.first_place)}/-</td>
                                    <td>{RupeesFormat(s.second_place)}/-</td>
                                    <td>{RupeesFormat(s.third_place)}/-</td>
                                  </tr>
                                </tbody>
                              </table> */}
                          <Box
                            padding={"20px"}
                            textAlign={"justify"}
                          >
                            <Text fontSize={"var(--font-size-14)"}>{tablelist?.content?.value}</Text>
                          </Box>
                        </VStack>
                        {/* );
                        })} */}
                        <Box pr={"35px"}>
                          <Text textAlign={"justify"} fontSize={"var(--font-size-14)"}>
                            {tablelist?.note?.value}
                          </Text>
                        </Box>
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CompetitionsResponsiveTable;
