import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { generateAllPageMutation } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "pageGenerateAll",
    initialState,
    reducers: {
        _generateAll_all_page: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _generateAll_all_page_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
        },
        _generateAll_all_page_success: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _generateAll_all_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {
    _generateAll_all_page,
    _generateAll_all_page_reset,
    _generateAll_all_page_success,
    _generateAll_all_page_failure,
} = slice.actions;

export const generateAllPageSelector = (state) => state.pageGenerateAll;

export const generateAllPageReducer = slice.reducer;

export function generateAll_all_page(variables) {
    return async (dispatch) => {
        dispatch(_generateAll_all_page());
        try {
            const response = await MutateRequest(
                generateAllPageMutation,
                variables,
                dispatch
            );
            if (
                response?.data?.generateAllPage &&
                !response?.data?.generateAllPage.error
            ) {
                dispatch(
                    _generateAll_all_page_success(
                        response?.data?.generateAllPage
                    )
                );
            } else if (response?.data?.generateAllPage?.error) {
                dispatch(
                    _generateAll_all_page_failure(
                        response?.data?.generateAllPage.error
                    )
                );
            }
        } catch (error) {
            dispatch(_generateAll_all_page_failure(error));
        }
    };
}
export function generateAll_all_page_clear() {
    return async (dispatch) => {
        dispatch(_generateAll_all_page_reset());
    };
}
