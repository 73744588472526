import { Skeleton } from "antd";
import {
  Box,
  Center,
  HStack,
  Hidden,
  SimpleGrid,
  Stack,
  VStack,
} from "native-base";
import React from "react";

const AboutUsLoading = () => {
  return (
    <Box>
      {/* <Hidden from="base" till="md"> */}
      <VStack space={"20px"}>
        {/* menubar */}
        <Box>
          <Center>
            <HStack space="25px">
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
              <Skeleton.Button active className="menu-loading" />
            </HStack>
          </Center>
        </Box>
        <Box px="30px">
          <Stack
            alignItems={"center"}
            flexDirection={{ base: "column", lg: "row" }}
            style={{ gap: "20px" }}
          >
            <Skeleton.Image
              active
              className="executive-card"
            />
            <Skeleton active />
          </Stack>
        </Box>
        <Box px="50px">
          <Stack
            alignItems={"center"}
            flexDirection={{ base: "column", lg: "row" }}
            style={{ gap: "20px" }}
          >
            <Skeleton active />
            <Skeleton.Image
              active
              className="executive-card"
            />
          </Stack>
        </Box>
      </VStack>
      <SimpleGrid
        columns={{
          base: 2,
          sm: 2,
          xs: 1,
          md: 2,
          lg: 3,
          xl: 3,
          "2xl": 5,
          "3xl": 6,
        }}
        space={["1", "1", "2", "25px", "85px", "105px", "185px"]}
        justifyContent={"center"}
        alignItems={"center"}
        // marginLeft={"60px"}
        // marginRight={"60px"}
        mt={"20px"}
      >
        <Skeleton.Button active className="executive-card" />
        <Skeleton.Button className="executive-card" active />
        <Skeleton.Button className="executive-card" active />
        <Skeleton.Button className="executive-card" active />
        <Skeleton.Button className="executive-card" active />
      </SimpleGrid>
      <VStack space={"20px"} mt={"10px"} padding={"20px"}>
        <Skeleton.Button className="policy-note-card" active />
        <Skeleton.Button className="policy-note-card" active />
      </VStack>
      {/* </Hidden> */}
      {/* <Hidden from="md" till="3xl"></Hidden> */}
    </Box>
  );
};
export default AboutUsLoading;
