import React, { useEffect, useState } from "react";
import { Input } from "antd";
import PanelItemValue from "./panel_item_value";

const { Search } = Input;
const PanelItem = (props) => {
  const { panel_list, setMenuList, menuList } = props;
    useEffect(() => {
    setMenuList(panel_list);
  }, []);
  const getSearch = () => {
    return (
      <Search
        placeholder="search"
        allowClear
        onSearch={(value) => {
          onSearch(value, panel_list);
        }}
      />
    );
  };

  const onSearch = (value, list) => {
    list = list.filter((list_item) => {
      return list_item.key.toLowerCase().includes(value.toLowerCase());
    });
    setMenuList(list);
  };

  let search = getSearch();
  let panel_menu = menuList?.map((item) => {
    return <PanelItemValue item={item} />;
  });

  panel_menu = [search].concat(panel_menu);

  return panel_menu;
};
export default PanelItem;
