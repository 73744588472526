import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { createPageMutation } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "pageCreate",
    initialState,
    reducers: {
        _create_page: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _create_page_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
        },
        _create_page_success: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _create_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {
    _create_page,
    _create_page_reset,
    _create_page_success,
    _create_page_failure,
} = slice.actions;

export const createPageSelector = (state) => state.pageCreate;

export const createPageReducer = slice.reducer;

export function create_page(variables) {
    return async (dispatch) => {
        dispatch(_create_page());
        try {
            const response = await MutateRequest(
                createPageMutation,
                variables,
                dispatch
            );
            if (
                response?.data?.createPage &&
                !response?.data?.createPage.error
            ) {
                dispatch(_create_page_success(response?.data?.createPage));
            } else if (response?.data?.createPage?.error) {
                dispatch(
                    _create_page_failure(response?.data?.createPage.error)
                );
            }
        } catch (error) {
            dispatch(_create_page_failure(error));
        }
    };
}
export function create_one_page_clear() {
    return async (dispatch) => {
        dispatch(_create_page_reset());
    };
}
