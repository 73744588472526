import GalleryContentsNew from "@views/components/gallery_page/gallery_contents_new";
import React from "react";

const GalleryPageNew = () => {
  return (
    <div>
      <GalleryContentsNew />
    </div>
  );
};

export default GalleryPageNew;
