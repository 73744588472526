import React, { useState, useRef, useEffect } from "react";
import { Box, Text, HStack, Tooltip, IconButton, Center } from "native-base";
import * as yup from "yup";

import JoditEditor from "jodit-react";

import { Controller, useFormContext } from "react-hook-form";
import { retrieveItem } from "@helpers/storage";
import { useEventListener } from "@helpers/event_listener";

let defaultStyles = {
  height: "150px",
  labelColor: "#6e6e6e",
  labelBGColor: "#fff",
  iconColor: "#ff0000",
  iconSize: 20,
  boxBorderRadius: 6,
  fontSize: "14px",
};

const RichText = (props) => {
  let inputTimeout = null;
  const { control } = useFormContext();
  const editor = useRef(null);
  const [is_required, set_is_required] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  var observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (!mutation.oldValue) {
        setIsFocused(true);
      }
    });
  });
  useEffect(() => {
    const elements = Array.from(
      document.getElementsByClassName("jodit-wysiwyg")
    );
    observer.observe(elements[0], {
      attributes: true,
      attributeFilter: ["data-focusvisible-polyfill"],
      attributeOldValue: true,
    });
  }, [editor.current]);

  const checkIsRequired = () => {
    let _form_id = props.form_id;
    let _validation_schema = props.validation_schema;
    let data = retrieveItem(`${_form_id}_data`);
    try {
      const fieldDescription = yup
        .reach(_validation_schema, props.field)
        .resolve({
          parent: data,
        })
        .describe();
      if (fieldDescription) {
        let isRequiredTest = fieldDescription.tests.find(
          (x) => x.name === "required"
        );
        if (isRequiredTest) {
          set_is_required(true);
        } else {
          set_is_required(false);
        }
      }
    } catch (e) {}
  };
  useEventListener(`${props.form_id}_data`, checkIsRequired, window);

  let height = props.height || props.h || defaultStyles.height;
  let labelColor = props.labelColor || defaultStyles.labelColor;
  let labelBGColor = props.labelBGColor || defaultStyles.labelBGColor;
  let boxBorderRadius = props.boxBorderRadius || defaultStyles.boxBorderRadius;

  let boxColor = "inputBorder.300";

  return (
    <Box minHeight={height} {...props}>
      <Controller
        control={control}
        name={`${props.field}`}
        render={({
          field: { onChange, value, name, ref },
          fieldState: { error: _error },
        }) => {
          let isValid = _error?.message === undefined;
          let error = _error?.message;

          if (!isValid && error) {
            boxColor = "error.400";
          } else if (isFocused) {
            boxColor = "success.400";
          }

          return (
            <>
              <HStack space={0} justifyContent="flex-end">
                {!props.noLeftbar && (
                  <Box
                    width="15px"
                    minHeight={height}
                    bg={boxColor}
                    borderLeftRadius={boxBorderRadius}
                    borderWidth="1px"
                    borderColor={boxColor}
                    style={{
                      transition: "background .5s ease",
                      WebkitTransition: "background .5s ease",
                      MozTransition: "background .5s ease",
                    }}
                  ></Box>
                )}
                <Box
                  flex={1}
                  minHeight={height}
                  bg="#ffffff"
                  borderRightRadius="6"
                  borderLeftRadius={props.noLeftbar ? "6" : "0"}
                  borderWidth="1px"
                  borderLeftWidth={!props.noLeftbar ? "0px" : "1px"}
                  borderColor={isFocused ? "success.400" : boxColor}
                  style={{
                    transition: "border .5s ease",
                    WebkitTransition: "border .5s ease",
                    MozTransition: "border .5s ease",
                  }}
                >
                  <Box
                    height="10px"
                    width="100%"
                    bg="#f9f9f9"
                    borderTopRightRadius="6"
                    position="relative"
                  >
                    <Box position="absolute" top="-10px" left="10px">
                      <Box
                        zIndex="2"
                        _text={{
                          color: labelColor,
                          textTransform: props.allCaps ? "uppercase" : "",
                        }}
                      >
                        <HStack>
                          <Text>{props.label}</Text>
                          <Text color={"#ff5500"}>
                            {is_required ? " *" : ""}
                          </Text>
                        </HStack>
                      </Box>
                      <Box
                        zIndex="1"
                        position="absolute"
                        top="9px"
                        left="0px"
                        bg="#ffffff"
                        height="1px"
                        width="100%"
                      ></Box>
                    </Box>
                  </Box>
                  <Tooltip
                    label={error}
                    isDisabled={!isValid && error ? false : true}
                    display={!isValid && error ? "block" : "none"}
                    //hasArrow={!isValid && error ? true : false}
                    bg="error.400"
                    _text={{
                      color: "#fff",
                    }}
                  >
                    <Box>
                      <JoditEditor
                        ref={editor}
                        value={value ? value.toString("html") : ""}
                        onBlur={(newContent) => {
                          setIsFocused(false);
                        }}
                        onChange={(newContent) => {
                          onChange(newContent.toString("html"));
                        }}
                      />
                    </Box>
                  </Tooltip>
                </Box>
                {props.button && (
                  <Center width="50px" minHeight={height}>
                    <IconButton
                      variant="unstyled"
                      icon={<ButtonIcon />}
                      onPress={() => {
                        props.button.onPress();
                      }}
                    />
                  </Center>
                )}
              </HStack>
            </>
          );
        }}
      />
    </Box>
  );
};

export default RichText;
