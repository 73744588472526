import React from "react";
import Stalin from "@assets/images/menu/MKStalin_16072021_1200_0.jpg";
// import Sport from "../../../assets/images/menu/hockey.jpg";
// import Kabbadi from "../../../assets/images/menu/kabaddi.jpg";
import AboutUsComponent from "./about_us_component";
// import Udhaya from "@assets/images/menu/Udhaya.jpg";
// import StalinAboutUsContents from "./stalin_about_us_contents";
import { Box, VStack } from "native-base";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BackgroundWaterMarkLogo from "@views/pages/home/background_water_mark_logo";
import Vally_ball from "@assets/images/menu/Vally_ball.jpg";
import hockey_hit from "@assets/images/menu/hockey_hit.jpg";
import running_game from "@assets/images/menu/running_game.jpg";
import hockey_game from "@assets/images/menu/hockey_game.jpg";
import Udhaya_image from "@assets/images/Deputy CM.png";
import LongJump from "@assets/images/menu/longjump.jpg";
import Womens_Kabbadi from "@assets/images/menu/WomensKabbadi.jpg";
import { useTranslation } from "react-i18next";

const HomeAboutUs = () => {
  const { t } = useTranslation();
  const contents1 = [
    {
      Img1: running_game,
      // Img2: "https://v-lfa-assets.blr1.vultrobjects.com/01ba9770-6943-450c-9336-0048f0ffa2d4.jpg",
      Img2: Udhaya_image,
      Img3: hockey_game,
      Img4: Womens_Kabbadi,
      title: t("thiru_udhayanidhi"),
      header: t("minister_for_youth"),
      content: t("tamilnadu_has_rich"),
    },
  ];
  const contents = [
    // {
    //   Img1: Kabbadi,
    //   Img2: Udhaya,
    //   Img3: Sport,
    //   title: "Thiru.Udhayanidhi Stalin,",
    //   header: "Hon'ble Minister for Youth Welfare and Sports Development",
    //   content:
    //     "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Nam Scelerisque Sit Amet Ex Non laculis. Curabitur Ipsum Augue, Elementum Vel Tellus In, Luctus Egestas Justo. Vestibulum Tempus Nisl Eget Lacus Placerat Dignissim. Aliquam Vel Vulputate Lacus. Nam Id Dignissim Nisi, Rhoncus Maximus Magna. Etiam Interdum, Turpis Fringilla Faucibus Congue, Quam Orci Fermentum Sem.",
    // },
    {
      Img1: Vally_ball,
      Img2: Stalin,
      Img3: hockey_hit,
      Img4: LongJump,
      title: t("thiru_stalin"),
      header: t("chief_minister"),
      content: t("our_government"),
    },
  ];
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1, slidesToSlide: 1 },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <VStack>
      <BackgroundWaterMarkLogo />
      {/* <Carousel responsive={responsive}> */}
      {contents.map((s) => {
        return (
          <div className="home_about_us_slider">
            <AboutUsComponent
              title={s.title}
              header={s.header}
              content={s.content}
              Img1={s.Img1}
              Img2={s.Img2}
              Img3={s.Img3}
              Img4={s.Img4}
              xs={"column"}
              md={"row"}
              lg={"row"}
            />
          </div>
        );
      })}
      {/* </Carousel> */}
      <Box marginTop={["", "", "", "", "", "110px"]}>
        {/* <StalinAboutUsContents /> */}
        {contents1.map((s) => {
          return (
            <div className="home_about_us_slider">
              <AboutUsComponent
                title={s.title}
                header={s.header}
                content={s.content}
                Img1={s.Img1}
                Img2={s.Img2}
                Img3={s.Img3}
                Img4={s.Img4}
                xs={"column"}
                md={"row"}
                lg={"row"}
              />
            </div>
          );
        })}
      </Box>
    </VStack>
  );
};

export default HomeAboutUs;
