import React, { useState } from "react";
// import GroupOne from "@assets/images/menu/news/group.png";
// import GroupTwo from "@assets/images/menu/news/New Coaches.jpg";
// import GroupThree from "@assets/images/menu/news/CM TROPHY (1).jpg";
// import Chess from "@assets/images/menu/news/chess.png";
// import Hockey from "@assets/images/menu/news/hockey.png";
// import StadiumOne from "@assets/images/menu/news/stadium.png";
// import StadiumTwo from "@assets/images/menu/news/stadium.jpg";
// import SwimmingOne from "@assets/images/menu/news/swimming.jpg";
// import SwimmingTwo from "@assets/images/menu/news/swimming.png";
// import Running from "@assets/images/menu/news/DSC01262.jpg";
// import StadiumTopView from "@assets/images/menu/news/JNS TOP VIEW.jpg";
// import NewCoaches from "@assets/images/menu/news/New Coaches.jpg";
// import AQUATICComplex from "@assets/images/menu/news/SDAT AQUATIC Complex.jpg";

// import HockeySport from "@assets/images/menu/hockey1.jpg";
// import SportTeam from "@assets/images/menu/sportteam.jpg";
// import Kabadi from "@assets/images/menu/kabadi.jpg";
// import VellyBall from "@assets/images/menu/vellyball.jpg";
// import StalinCm from "@assets/images/menu/stalincm.jpg";
// import Award from "@assets/images/menu/award.jpg";
// import UdhayaNithi from "@assets/images/menu/udhayanithi.jpg";
// import Stage from "@assets/images/menu/img1.jpg";
// import Img2 from "@assets/images/menu/img2.jpg";
// import Img3 from "@assets/images/menu/img3.jpg";
// import weight from "@assets/images/menu/weightlift.jpg";

import GalleryImageTemplateOne from "./gallery_image_template_one";
import { Box, Center, Hidden, HStack, Pressable, Text, VStack } from "native-base";
import GalleryImageTemplateTwo from "./gallery_image_template_two";
import GalleryImageTemplateThree from "./gallery_image_template_three";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FilterItems, color_gradients } from "@helpers/constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { get_cms_gallery_page_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_gallery_page_query";
import { Image, Skeleton, Space, Spin } from "antd";
import GalleryImageTemplateLoading from "./gallery_loading";
import { order } from "styled-system";
import { get_all_active_gallery_list } from "@services/redux/slices/dynamic_entity/graphql/grahpql_cms_gallery";
import { get_all_active_gallery_image_list } from "@services/redux/slices/dynamic_entity/graphql/grahpql_cms_gallery_image";
import BrokenImage from "../../../assets/icons/broken_image.png";
import GalleryImageTemplate from "./gallery_image_template";

const GalleryImagesNew = () => {
  const dispatch = useDispatch();
  const [currentAlbum, setCurrentAlbum] = useState(null);
  const [gallery_album_data, setGalleryAlbumData] = useState([]);
  const [gallery_image_data, setGalleryImageData] = useState([]);

  // const { items } = useSelector(getsystemCMSFieldListSelector);
  const { items: gallery_album_items, loading: album_loading } =
    useDynamicSelector("getGalleryActiveList");

  const { items: gallery_image_items, loading: image_loading } =
    useDynamicSelector("getActiveGalleryImageList");

  // useEffect(() => {
  //   dispatch(
  //     get_system_cms_field({
  //       json: {
  //         system_cms_key: "Gallery_page",
  //       },
  //     })
  //   );
  // }, []);

  const get_gallery_images = () => {
    let keys = [{ key: "getActiveGalleryImageList", loading: true }];
    let variables = {
      gallery_album_id: currentAlbum,
    };
    dispatch(
      dynamicRequest(keys, get_all_active_gallery_image_list, variables)
    );
  };
  const get_gallery_albums = () => {
    let keys = [{ key: "getGalleryActiveList", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_all_active_gallery_list, variables));
  };

  useEffect(() => {
    get_gallery_albums();
    get_gallery_images();
  }, []);
  useEffect(() => {
    get_gallery_images();
  }, [currentAlbum]);
  useEffect(() => {
    if (gallery_album_items?.length)
      setGalleryAlbumData([{ id: null, name: "All" }, ...gallery_album_items]);
  }, [gallery_album_items]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5.5,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 7.5,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 4.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 2.5,
      slidesToSlide: 1,
    },
  };
  const handlePress = (id) => {
    setCurrentAlbum(id);
  };

  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }
  useEffect(() => {
    if (gallery_image_items?.length)
      setGalleryImageData(chunkArray(gallery_image_items, 5));
  }, [gallery_image_items]);

  return (
    <div>
      <Box
        padding={{
          base: "20px",
          md: "20px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
        }}
      >
        <VStack space={10}>
          {album_loading ? (
            <Space>
              {/* <Skeleton.Button active />
                <Skeleton.Avatar active /> */}
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
              <Skeleton.Input active />
            </Space>
          ) : (
            <Carousel responsive={responsive} className="gallery-carousel">
              {gallery_album_data?.map((item, index) => {
                return (
                  <div
                    className="carousel-height"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "end",
                      justifyContent: "center",
                      width: "100%",
                      padding: "10px",
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        handlePress(item?.id);
                      }}
                    >
                      <Box
                        key={index}
                        bgImage={
                          item?.id === currentAlbum
                            ? color_gradients.sports_event_gradient
                            : "linear-gradient(99.9deg, #EDEDED,#EDEDED)"
                        }
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          minWidth: "100px",
                          maxWidth: "200px",
                          alignItems: "center",
                          boxShadow:
                            item?.id === currentAlbum
                              ? "0px 14px 19px rgba(0, 0, 0, 0.15)"
                              : "",
                        }}
                      >
                        <Text
                          textAlign={"center"}
                          color={item?.id === currentAlbum ? "white" : "black"}
                          fontSize={"var(--font-size-14)"}
                        >
                          {item?.name}
                        </Text>
                      </Box>
                    </Pressable>
                  </div>
                );
              })}
            </Carousel>
          )}

          {image_loading ? (
            <Center>
              <GalleryImageTemplateLoading />
            </Center>
          ) : (
            <GalleryImageTemplate images={gallery_image_items} />
          )}
        </VStack>
      </Box>
    </div>
  );
};

export default GalleryImagesNew;
