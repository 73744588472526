import { Box, Hidden, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import HighCaseIncentiveTable from "./high_case_incentive_table";

const HighCashIncentiveContent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("high_cash_incentive")}</Text>
        </Hidden>
      </Box>
      <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
        <Box>
          <Text
            variant={"page_title"}
            color={"black"}
            paddingBottom={"0px"}
            paddingLeft={"18px"}
          >
            {t("high_cash_incentive")}
          </Text>
        </Box>
      </Hidden>
      {/* <Box
        padding={{
          base: "20px",
          xs: "25px",
          md: "60px",
          lg: "60px",
          xl: "60px",
          "2xl": "60px",
        }}
        // marginRight={{
        //   sm: "15px",
        //   xs: "15px",
        //   base: "15px",
        //   md: "0px",
        //   lg: "0px",
        //   xl: "0px",
        //   "2xl": "0px",
        // }}
      > */}
      <div style={{ padding: "20px" }}>
        <></>
        <HighCaseIncentiveTable />
      </div>
      {/* </Box> */}
    </div>
  );
};

export default HighCashIncentiveContent;
