import React, { useEffect } from "react";
import { Box, Center } from "native-base";
import { startCase } from "lodash";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
const PanelItemValue = (props) => {
  const { item } = props;
  const history = useHistory();
  useEffect(() => {}, []);
  const moveToDynamicPage = () => {
    history.push({ pathname: `${ROUTES.SYSTEM_CMS}/${item.id}` });
  };
  return (
    <Box
      className="sideBarItem"
      m={3}
      cursor={"pointer"}
      onClick={moveToDynamicPage}
    >
      {startCase(item.key)}
    </Box>
  );
};
export default PanelItemValue;
