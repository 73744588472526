import VigilanceAntiCorruptionContents from "@views/components/vigilance_anti_corruption/vigilance_anti_corruption_contents";
import React from "react";

const VigilanceAntiCorruptionPage = () => {
  return (
    <div>
      <VigilanceAntiCorruptionContents />
    </div>
  );
};

export default VigilanceAntiCorruptionPage;
