import Servicepagecontents from "@views/components/service/servicecontents";
import React from "react";

const ServicePage = () => {
  return (
    <div>
      <Servicepagecontents />
    </div>
  );
};

export default ServicePage;
