import SportsHostelContents from "@views/components/sports_hostel/sports_hostel_contents";
import React from "react";

const SportsHostelPage = () => {
  return (
    <div>
      <SportsHostelContents />
    </div>
  );
};

export default SportsHostelPage;
