import React, { useEffect, useState } from "react";
import { Box, Card, HStack, Pressable, Stack, Text, VStack } from "native-base";
import Carousel from "react-multi-carousel";
import {
  InfrastructureContentss,
  stadium_content,
} from "./Infrastructure_json";
import { FilterItems, color_gradients } from "@helpers/constants";
import InfrastructureContent from "./infrastructure_content";
import { get_cms_infrastructure_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_infrastructure_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
const InfrastructureMainContents = () => {
  const { items } = useDynamicSelector("get_cms_infrastructure");
  const [values, setValues] = useState([]);
  const [find_index, setFindIndex] = useState(0);
  const dispatch = useDispatch();
  const [infra_content, setInfraContent] = useState([]);

  const handlePress = (s, index, description) => {
    let values = infra_content?.find((Item) => Item.stadium_name.value === s);
    setValues(values);
    setFindIndex(index);
    // setDescription(description);
  };

  const get_infrastructure = () => {
    let keys = [{ key: "get_cms_infrastructure", loading: true }];
    let variables = {
      json: {
        system_cms_key: "Infrastructure_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_infrastructure_query, variables));
  };

  useEffect(() => {
    get_infrastructure();
  }, []);

  useEffect(() => {
    let filtered_items = FilterItems(items);
    setInfraContent(filtered_items.sort((a, b) => a.stadium_name.row_number - b.stadium_name.row_number));
    setValues(
      filtered_items?.find(
        (Item) =>
          Item?.stadium_name?.value === "Jawaharlal Nehru Stadium, Chennai"
      )
    );
  }, [items]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2.5,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      slidesToSlide: 1,
    },
  };
  return (
    <div>
      <Box>
        <Text fontSize={"var(--font-size-20)"} fontWeight={"bold"} my="10px">
          Stadium
        </Text>
      </Box>
      <Carousel responsive={responsive} className="infrastructure-carousel" >
        {infra_content?.map((s, index) => {
          return (
            <div className="carousel-height">
              <Pressable
                onPress={() => {
                  handlePress(s?.stadium_name?.value, index);
                }}
              >
                <Card
                  key={index}
                  width={"200px"}
                  height={"70px"}
                  maxH={"150px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  bgImg={
                    index === find_index
                      ? color_gradients.sports_event_gradient
                      : "linear-gradient(99.9deg, #EDEDED,#EDEDED)"
                  }
                  style={{
                    boxShadow:
                      index === find_index
                        ? "0px 14px 19px rgba(0, 0, 0, 0.15)"
                        : "",
                  }}
                >
                  <Text
                    fontSize={"var(--font-size-13)"}
                    fontFamily={"Inter"}
                    fontWeight={"medium"}
                    color={index === find_index ? "white" : "black"}
                  >
                    {s?.stadium_name?.value}
                  </Text>
                </Card>
              </Pressable>
            </div>
          );
        })}
      </Carousel>
      <InfrastructureContent values={values} />
    </div>
  );
};

export default InfrastructureMainContents;
