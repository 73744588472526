import { FilterItems } from "@helpers/constants";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { get_cms_vigilance_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_vigilance";
import { Pagination } from "antd";
import { Box, HStack, Hidden, Link, Stack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import TableLoading from "../events/table_loading";

const VigilanceAntiCorruptionTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vigilance_data, setVigilanceData] = useState();

  const { items: vigilance_items, loading } =
    useDynamicSelector("get_cms_vigilance");

  const TableData = [
    {
      name: "The Director",
      address:
        "Vigilance & Anti - Corruption,No: 293, M.K.N. Road, Alandur,Chennai - 600001",
      website: " www.dvac.tns.gov.in",
      phone:
        "044 - 2231 0989, 044, 2232 1090, 044 - 2232 1085, 044 - 2234 2142",
      fax_no: "044 - 2232 1005",
    },
  ];

  const get_vigilance = () => {
    let keys = [{ key: "get_cms_vigilance", loading: true }];
    let variables = {
      json: {
        system_cms_key: "vigilance_and_anti-corruption_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_vigilance_query, variables));
  };

  useEffect(() => {
    get_vigilance();
  }, []);

  useEffect(() => {
    let values = FilterItems(vigilance_items);
    setVigilanceData(values);
  }, [vigilance_items]);

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <Hidden from="base" till="md">
            <table class="contact-table">
              <thead className="table-header">
                <tr>
                  <th className="radius-right" style={{ width: "50%" }}>
                    {t("contact_person")}
                  </th>
                  <th className="radius-left" style={{ width: "50%" }}>
                    {t("table:contact_address")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {vigilance_data?.map((tablelist, index) => {
                  return (
                    <>
                      <tr
                        className={`space-add ${
                          vigilance_data?.length - 1 !== index
                            ? "table-border-bottom"
                            : ""
                        }`}
                      >
                        {/* <td data-label="S.No">{index + 1}</td> */}
                        <td data-label="District">
                          <VStack>
                            <Text fontSize={"2vh"} bold>
                              {tablelist?.name?.value},
                            </Text>
                            <Text width={"35vh"}>
                              {tablelist?.address?.value}
                            </Text>
                          </VStack>
                        </td>
                        <td
                          data-label="Contact"
                          className="column-width"
                          style={{ fontSize: "13px" }}
                        >
                          <VStack space={3}>
                            <HStack space={2}>
                              <Text bold width={"70px"}>
                                {t("website")}
                              </Text>
                              <Text>
                                <span style={{ fontWeight: "bold" }}>
                                  {": "}
                                </span>
                                <a href={`mailto:${tablelist?.website?.value}`}>
                                  {tablelist?.website?.value}
                                </a>
                              </Text>
                            </HStack>
                            <HStack space={2}>
                              <Text bold width={"70px"}>
                                {t("pho_no")}
                              </Text>
                              <Box
                                width={"35vh"}
                                display={"flex"}
                                flexDirection={"row"}
                                gap={"4px"}
                              >
                                <span style={{ fontWeight: "bold" }}>
                                  {": "}
                                </span>{" "}
                                <Text>
                                  <a
                                    href={`tel:${tablelist?.phone_number?.value}`}
                                  >
                                    {tablelist?.phone_number?.value}
                                  </a>
                                  {/* ,
                              <a href={"tel: 044- 2232 1090"}> 044-2232 1090</a>
                              ,
                              <a href={"tel:044 - 2231 0989"}>
                                044 - 2231 0989
                              </a> */}
                                </Text>
                              </Box>
                            </HStack>
                            <HStack space={2}>
                              <Text bold width={"70px"}>
                                {t("fax_no")}
                              </Text>
                              <Text>
                                <span style={{ fontWeight: "bold" }}>
                                  {": "}
                                </span>{" "}
                                <a href={`tell${tablelist?.fax?.value}`}>
                                  {tablelist?.fax?.value}
                                </a>
                              </Text>
                            </HStack>
                          </VStack>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Hidden>
          <Hidden from="md" till="3xl">
            <Box variant={"tender_table_box"}>
              {vigilance_data?.map((tablelist, index) => {
                return (
                  <Box variant={"tender_table_contents"}>
                    <VStack>
                      <Text fontSize={"4vh"} bold>
                        {tablelist?.name?.value},
                      </Text>
                      <Text width={"35vh"}>{tablelist?.address?.value}</Text>
                    </VStack>
                    <VStack space={3}>
                      <HStack space={2}>
                        <Text bold width={"70px"}>
                          {t("website")}
                        </Text>
                        <Text>
                          <span style={{ fontWeight: "bold" }}>{": "}</span>
                          {tablelist?.website?.value}
                        </Text>
                      </HStack>
                      <HStack space={2}>
                        <Text bold width={"70px"}>
                          {t("pho_no")}
                        </Text>
                        <Box
                          width={"35vh"}
                          display={"flex"}
                          flexDirection={"row"}
                          gap={"4px"}
                        >
                          <span style={{ fontWeight: "bold" }}>{": "}</span>{" "}
                          <Text>{tablelist?.phone_number?.value}</Text>
                        </Box>
                      </HStack>
                      <HStack space={2}>
                        <Text bold width={"70px"}>
                          {t("fax_no")}
                        </Text>
                        <Text>
                          <span style={{ fontWeight: "bold" }}>{": "}</span>{" "}
                          {tablelist?.fax?.value}
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>
                );
              })}
            </Box>
          </Hidden>
          {/* <div className="pagination-box">
          <Stack variant={"pagination_stack"}>
            <Hidden from="base" till="lg">
              <Box justifyContent={"center"}>
                <Text>Records per page</Text>
              </Box>
            </Hidden>
            <Box variant={"pagination_box"}>
              <Pagination
                total={85}
                //   showTotal={(total) => `Records per page ${total} items`}
                defaultPageSize={20}
                defaultCurrent={1}
              />
            </Box>
          </Stack>
        </div> */}
        </div>
      )}
    </>
  );
};

export default VigilanceAntiCorruptionTable;
