import { Image } from "antd";
import { Box, HStack, Hidden, VStack } from "native-base";
import React from "react";
import BrokenImage from "../../../assets/icons/broken_image.png";

const GalleryImageTemplateOne = (props) => {
  const { images } = props;

  return (
    <div className="gallery_first_template" style={{ width: "100%" }}>
      <Hidden from="base" till="lg">
        <HStack space={4}>
          <Image
            src={images?.image_one?.value || BrokenImage}
            className="gallery_second_image_size"
            preview={!!images?.image_one?.value}
            style={{ borderRadius: "20px", height: "100%" }}
          />
          <VStack justifyContent={"space-around"}>
            <Image
              src={images?.image_two?.value || BrokenImage}
              alt="djf"
              preview={!!images?.image_two?.value}
              className="gallery_first_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
            />
            <Image
              src={images?.image_three?.value || BrokenImage}
              alt="djf"
              preview={!!images?.image_three?.value}
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
            />
          </VStack>
          <VStack justifyContent={"space-around"}>
            <Image
              src={images?.imgae_four?.value || BrokenImage}
              alt="djf"
              preview={!!images?.imgae_four?.value}
              className="gallery_fourth_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
            />
            <Image
              src={images?.image_five?.value || BrokenImage}
              alt="djf"
              preview={!!images?.image_five?.value}
              className="gallery_fifth_image_size"
              style={{ borderRadius: "20px", width: "25vw", height: "14vw" }}
            />
          </VStack>
        </HStack>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <VStack space={4}>
          <Image
            src={images?.image_one?.value || BrokenImage}
            preview={!!images?.image_one?.value}
            style={{ borderRadius: "20px" }}
          />
          <HStack justifyContent={"space-around"} gap={"10px"}>
            <Image
              src={images?.image_two?.value || BrokenImage}
              alt="djf"
              preview={!!images?.image_two?.value}
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
            />
            <Image
              src={images?.image_three?.value || BrokenImage}
              alt="djf"
              preview={!!images?.image_three?.value}
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
            />
          </HStack>
          <HStack justifyContent={"space-around"} gap={"10px"}>
            <Image
              src={images?.imgae_four?.value || BrokenImage}
              alt="djf"
              preview={!!images?.imgae_four?.value}
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
            />
            <Image
              src={images?.image_five?.value || BrokenImage}
              alt="djf"
              preview={!!images?.image_five?.value}
              className="gallery_third_image_size"
              style={{ borderRadius: "20px", width: "46vw" }}
            />
          </HStack>
        </VStack>
      </Hidden>
    </div>
  );
};

export default GalleryImageTemplateOne;
