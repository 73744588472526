import React from "react";
import { Box, Button, Text, Tooltip } from "native-base";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollView from "@views/components/ui/scroll_view/scroll_view";

const Card = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const buttonClick = () => {
    history.push(props.url);
  };
  return (
    <>
      <Box variant="card">
        <Text variant="header">{props.header}</Text>
        <ScrollView>
          <Box variant="infoCard" padding={"10px"}>
            {props.component}
          </Box>
        </ScrollView>
        <Box style={{ boxShadow: "#fefefe -20px -44px 50px" }}>
          <Tooltip label="View more" openDelay={500}>
            <Button
              variant="cardButton"
              onPress={buttonClick}
              background={"#3B1B7E"}
            >
              <Text variant={"cardButton_Txt"}>{t("view_more")}</Text>
            </Button>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};

export default Card;
