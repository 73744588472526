import { Box, HStack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { RupeesFormat } from "@helpers/constants";
import ReactHtmlParser from "react-html-parser";

const HighCashIncentiveResponsiveTable = (props) => {
  const { high_data } = props;
  const [open_index, setOpenIndex] = useState(0);
  const { t } = useTranslation();
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  return (
    <div>
      {ReactHtmlParser(high_data)}

      {/* <table class="table">
        <thead className="table-header">
          <tr>
            <th className="radius-right table-list-padding-change">
              {t("table:s_no")}
            </th>
            <th className="radius-left table-list-padding-change">
              {t("table:name_of_competition")}
            </th>
          </tr>
        </thead>
        <tbody>
          {high_data.map((tablelist, index) => {
            return (
              <>
                <tr
                  className={`space-add ${
                    high_data.length - 1 !== index ? "table-border-bottom" : ""
                  }`}
                >
                  <td
                    // className="table-list-padding-change"
                    style={{
                      alignItems: "flex-start",
                      position: "absolute",
                      padding: "20px",
                      marginTop: "10px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td className="table-list-padding-change">
                    <div
                      onClick={() => handleExpendable(index)}
                      className={`table_row ${
                        open_index === index
                          ? `table_row_height`
                          : `table_row_normal`
                      }`}
                    >
                      {open_index === index ? (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            <Box width={"20px"}>
                              <AiOutlineMinus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist.name}
                            </Text>
                          </HStack>
                        </p>
                      ) : (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            <Box width={"20px"}>
                              <AiOutlinePlus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist.name}
                            </Text>
                          </HStack>
                        </p>
                      )}
                      <div
                        className={`table_open ${
                          open_index === index
                            ? `table_active`
                            : `table_inactive`
                        }`}
                      >
                        {tablelist?.level ? (
                          ""
                        ) : (
                          <table className="expendable-table">
                            <thead>
                              <tr className="expendable-table-header">
                                <th className="radius-right padding-change">
                                  {t("table:gold")}
                                </th>
                                <th className="padding-change">
                                  {t("table:sliver")}
                                </th>
                                <th className="radius-left padding-change">
                                  {t("table:bronze")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className={"expendable-table-contents"}>
                                <td>{tablelist?.gold}</td>
                                <td>{tablelist.sliver}</td>
                                <td>{tablelist.brave}</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                        {tablelist?.level ? (
                          <>
                            {tablelist?.level?.map((s) => {
                              return (
                                <>
                                  <Text
                                    fontSize={"15px"}
                                    fontFamily={"Inter"}
                                    fontWeight={"bold"}
                                  >
                                    {s?.name}
                                  </Text>
                                  <table className="expendable-table">
                                    <thead>
                                      <tr className="expendable-table-header">
                                        <th className="radius-right padding-change">
                                          {t("table:gold")}
                                        </th>
                                        <th className="padding-change">
                                          {t("table:sliver")}
                                        </th>
                                        <th className="radius-left padding-change">
                                          {t("table:bronze")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        className={"expendable-table-contents"}
                                      >
                                        <td>{s?.gold}</td>
                                        <td>{s.sliver}</td>
                                        <td>{s.brave}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          ""
                        )}
                        {tablelist.details ? (
                          <div className="high-cash-incentive-details-box">
                            <Box
                              backgroundColor={"#f2f2f2"}
                              borderRadius={"10px"}
                              padding={"10px"}
                            >
                              <Text fontFamily={"Inter"}>
                                {tablelist.details}
                              </Text>
                            </Box>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
};

export default HighCashIncentiveResponsiveTable;
