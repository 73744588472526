import { Box, HStack, Hidden, Link, Pressable, Stack, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import "../tender/table.css";
import { Pagination, Select, Tooltip } from "antd";
import {
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
} from "@services/redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterItems } from "@helpers/constants";
import { useState } from "react";
import moment from "moment";

const NotificationsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const { items } = useSelector(getsystemCMSFieldListSelector);

  useEffect(() => {
    dispatch(
      get_system_cms_field({
        json: {
          system_cms_key: "notification",
        },
      })
    );
  }, []);

  useEffect(() => {
    let values = FilterItems(items);
    setData(values.sort((a, b) => a?.link?.row_number - b?.link?.row_number));
    dispatch(get_system_cms_value_clear());
  }, [items]);

  const options = [
    {
      id: "1",
      label: "2001",
    },
  ];
  const handleChange = (event) => {
    console.log("handleChange", event);
  };

  const handleClick = (list) => {
  if(list?.file?.value&&list?.link?.value){
      window.open(list.file.value, "_blank");
      window.open(list.link.value, "_blank");
  }else if(list?.file?.value){
    window.open(list?.file?.value, "_blank");
  }else if(list?.link?.value){
    window.open(list?.link?.value, "_blank");
  }
  }
  return (
    <div>
      <HStack padding={"10px"} alignItems={"center"} space={3}>
        {/* <Text>{t("year_of_events")}</Text>
        <Select
          placeholder={"Select the year"}
          style={{ width: "150px" }}
          fieldNames={"year"}
          options={options}
          onChange={handleChange}
        /> */}
      </HStack>
      <Hidden from="base" till="lg">
        <div>
          <table class="table">
            <thead className="table-header">
              <tr>
                <th className="radius-right">{t("s.no")}</th>
                <th>{"Notifications"}</th>
                {/* <th className="radius-left">{t("event_title")}</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map((tablelist, index) => {
                return (
                  <tr
                    className={`space-add ${
                      data.length - 1 !== index ? "table-border-bottom" : ""
                    }`}
                    key={index}
                  >
                    <td data-label="S.No" style={{ width: "10px" }}>
                      {index + 1}
                    </td>
                    <td data-label="Closing Date">
                    <a onClick={() => handleClick(tablelist)}>{tablelist?.name?.value}</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Hidden>
      <Hidden from="lg" till="3xl">
        <Box variant={"tender_table_box"}>
          {data?.map((content, index) => {
            return (
              <Box variant={"tender_table_contents"} key={index}>
                <Pressable onPress={()=>handleClick(content)}>
                <Text variant={"tender_description"}>
                  {content?.name?.value}
                </Text>
                </Pressable>
                <Text>
                  <Text variant={"table_headline"} color={"#828282"}>
                    {"Notifications"}:
                  </Text>
                  <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                    {/* {moment(content?.data?.value).format("MMMM YYYY")} */}
                  </Text>
                </Text>
                {/* <Text>
                    <Text variant={"table_headline"} color={"#828282"}>
                      {t("application_form")}:
                    </Text>
                    <Text color={"black"} fontWeight={"500"} marginLeft={"1px"}>
                      <a
                        // href={content.application_form}
                        target="_blank"
                        className="application-form-title"
                      >
                        {t("view_&_download")}
                      </a>
                    </Text>
                  </Text> */}
              </Box>
            );
          })}
        </Box>
      </Hidden>
      <div className="pagination-box">
        <Stack variant={"pagination_stack"}>
          <Hidden from="base" till="lg">
            <Box justifyContent={"center"}>
              <Text>Records per page</Text>
            </Box>
          </Hidden>
          <Box variant={"pagination_box"}>
            <Pagination
              total={85}
              //   showTotal={(total) => `Records per page ${total} items`}
              defaultPageSize={20}
              defaultCurrent={1}
            />
          </Box>
        </Stack>
      </div>
    </div>
  );
};

export default NotificationsTable;
