import GalleryContents from "@views/components/gallery_page/gallery_contents";
import React from "react";

const GalleryPage = () => {
  return (
    <div>
      <GalleryContents />
    </div>
  );
};

export default GalleryPage;
