import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_cms_languages_query } from "./graphql";
// import { get_departments_query } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  items: [],
  // item: {},
  total_items: null,
  page_number: null,
  page_limit: null,
  sort_column: null,
  sort_order: null,
};

const slice = createSlice({
  name: "cmsLanguageList",
  initialState,
  reducers: {
    _get_cms_languages: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _get_cms_languages_success: (state, { payload }) => {
      state.loading = false;
      state.items = payload.items;
      state.error = null;
      state.total_items = payload.pagination?.total_count;
      state.page_number = payload.pagination?.page_number;
      state.page_limit = payload.pagination?.page_limit;
    },
    _get_cms_languages_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
      state.total_items = [];
    },
  },
});

const {
  _get_cms_languages,
  _get_cms_languages_success,
  _get_cms_languages_failure,
} = slice.actions;

export const getcmsLanguageSelector = (state) => state.cmsLanguageList;

export const getcmsLanguageReducer = slice.reducer;

export function get_cms_languages(variables) {
  return async (dispatch) => {
    dispatch(_get_cms_languages());
    try {
      const response = await QueryRequest(
        get_cms_languages_query,
        variables,
        dispatch
      );
      if (
        response?.data?.getSystemLanguages &&
        !response?.data?.getSystemLanguages.error
      ) {
        dispatch(
          _get_cms_languages_success(response?.data?.getSystemLanguages)
        );
      } else if (response?.data?.getSystemLanguages?.error) {
        dispatch(
          _get_cms_languages_failure(response?.data?.getSystemLanguages.error)
        );
      }
    } catch (error) {
      dispatch(_get_cms_languages_failure(error));
    }
  };
}
