import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { deletecmsMutation } from "./graphql";

const initialState = {
  loading: false,
  error: null,
  id: null,
  status: null,
};

const slice = createSlice({
  name: "cmsDelete",
  initialState,
  reducers: {
    _delete_cms: (state) => {
      state.loading = true;
      state.id = null;
      state.status = null;
    },
    _delete_cms_success: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.id = payload.id;
      state.status = payload.status;
    },
    _delete_cms_failure: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message;
    },
    _delete_cms_reset: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.status = null;
    },
  },
});

const {
  _delete_cms,
  _delete_cms_success,
  _delete_cms_failure,
  _delete_cms_reset,
} = slice.actions;

export const deletecmsSelector = (state) => state.cmsDelete;

export const deletecmsReducer = slice.reducer;

export function delete_cms(variables) {
  return async (dispatch) => {
    dispatch(_delete_cms());
    try {
      const response = await MutateRequest(
        deletecmsMutation,
        variables,
        dispatch
      );
      if (
        response?.data?.deleteSystemCms &&
        !response?.data?.deleteSystemCms.error
      ) {
        dispatch(_delete_cms_success(response?.data?.deleteSystemCms));
      } else if (response?.data?.deleteSystemCms?.error) {
        dispatch(_delete_cms_failure(response?.data?.deleteSystemCms.error));
      }
    } catch (error) {
      dispatch(_delete_cms_failure(error));
    }
  };
}
export function delete_one_cms_modal() {
  return async (dispatch) => {
    dispatch(_delete_cms());
  };
}

export function delete_one_cms_clear() {
  return async (dispatch) => {
    dispatch(_delete_cms_reset());
  };
}
