import { Box, Hidden, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import InfrastructureMainContents from "./infrastructure_main_contents";
import InfrastructureMonogram from "./infrastructure_monogram";
import InfrastructureTable from "./infrastructure_table";

const InfrastructureContents = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden from="base" till="lg">
          <Text variant={"page_title"}>{t("infrastructure")}</Text>
        </Hidden>
      </Box>
      <Hidden from="lg" till="3xl">
        <Box>
          <Text variant={"page_title"} color={"black"} paddingBottom={"0px"}>
            {t("infrastructure")}
          </Text>
        </Box>
      </Hidden>
      <VStack padding={"30px"} space={5}>
        <InfrastructureMainContents />
        {/* <InfrastructureMonogram /> */}
      </VStack>
    </div>
  );
};

export default InfrastructureContents;
