import { FilterItems, RupeesFormat } from "@helpers/constants";
import {
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
} from "@services/redux";
import { Pagination } from "antd";
import { Box, HStack, Stack, Text, VStack } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

const FAQTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open_index, setOpenIndex] = useState(0);
  const [data, setData] = useState([]);

  const { items } = useSelector(getsystemCMSFieldListSelector);

  const handleExpendable = (index) => {
    setOpenIndex(index);
  };

  useEffect(() => {
    dispatch(
      get_system_cms_field({
        json: {
          system_cms_key: "faqs_table",
        },
      })
    );
  }, []);

  useEffect(() => {
    let values = FilterItems(items);
    setData(values);
    dispatch(get_system_cms_value_clear());
  }, [items]);

  const TableData = [
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      hostel_list: [
        {
          hostel_name:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse venenatis iaculis diam, sit amet ullamcorper arcu posuere vitae. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla vitae enim libero. Suspendisse egestas convallis purus tempus congue. Cras semper ipsum eu facilisis tempus. Proin sed bibendum ipsum. Suspendisse accumsan nunc ligula, et efficitur lorem ultricies sit amet.",
        },
        {
          hostel_name2:
            "Nunc ac mauris vitae velit mollis eleifend in a mauris. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam dignissim posuere eros, lacinia egestas leo tempor a. Vestibulum malesuada in nisi nec mattis. Duis blandit justo quam, et egestas enim gravida a. Vivamus luctus dictum metus. Suspendisse aliquam tellus sit amet tortor placerat, a tincidunt leo laoreet. Sed nec leo vehicula, faucibus sem maximus, vulputate neque. Mauris a molestie massa, nec ornare augue.",
        },
      ],
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
      hostel_list: [
        {
          hostel_name:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse venenatis iaculis diam, sit amet ullamcorper arcu posuere vitae. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla vitae enim libero. Suspendisse egestas convallis purus tempus congue. Cras semper ipsum eu facilisis tempus. Proin sed bibendum ipsum. Suspendisse accumsan nunc ligula, et efficitur lorem ultricies sit amet.",
        },
      ],
    },
  ];
  return (
    <div>
      <table class="table">
        <thead className="table-header">
          <tr>
            <th className="radius-right">{t("table:s_no")}</th>
            <th className="radius-left">{t("table:name_of_faqs")}</th>
          </tr>
        </thead>
        <tbody>
          {TableData.map((tablelist, index) => {
            return (
              <>
                <tr
                  className={`space-add ${
                    TableData.length - 1 !== index ? "table-border-bottom" : ""
                  }`}
                >
                  <td className="index-padding">{index + 1}</td>
                  <td>
                    <div
                      onClick={() => handleExpendable(index)}
                      className={`table_row ${
                        open_index === index
                          ? `table_row_height`
                          : `table_row_normal`
                      }`}
                    >
                      {open_index === index ? (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            <Box width={"20px"}>
                              <AiOutlineMinus />
                            </Box>
                            <Text variant={"table_title"}>
                              {tablelist?.title}
                            </Text>
                          </HStack>
                        </p>
                      ) : (
                        <p className="competition_name">
                          <HStack variant={"table_title_tag"}>
                            <AiOutlinePlus fontWeight={600} />
                            <Text variant={"table_title"}>
                              {tablelist?.title}
                            </Text>
                          </HStack>
                        </p>
                      )}
                      <div
                        className={`table_open ${
                          open_index === index
                            ? `table_active`
                            : `table_inactive`
                        }`}
                      >
                        <div>
                          {tablelist?.hostel_list?.map((name, index) => {
                            return (
                              <>
                                {/* <HStack space={3} alignItems={"start"}>
                                    <Text width={"20px"} textAlign={"end"}>
                                      {index + 1}.
                                    </Text> */}
                                <VStack>
                                  <Text
                                    fontFamily={"Inter"}
                                    textAlign={"justify"}
                                    width={"94%"}
                                    // mb={"20px"}
                                  >
                                    {name?.hostel_name}
                                  </Text>
                                  <Text
                                    fontFamily={"Inter"}
                                    textAlign={"justify"}
                                    width={"94%"}
                                    mt={"10px"}
                                  >
                                    {name?.hostel_name2}
                                  </Text>
                                </VStack>
                                {/* </HStack> */}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-box">
        <Stack variant={"pagination_stack"}>
          <Box justifyContent={"center"}>
            <Text>Records per page</Text>
          </Box>
          <Pagination
            total={85}
            //   showTotal={(total) => `Records per page ${total} items`}
            defaultPageSize={20}
            defaultCurrent={1}
          />
        </Stack>
      </div>
    </div>
  );
};

export default FAQTable;
