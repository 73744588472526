import HighCashIncentiveContent from "@views/components/high_cash_incentive/high_cash_incentive_content";
import React from "react";

const HighCashIncentivePage = () => {
  return (
    <div>
      <HighCashIncentiveContent />
    </div>
  );
};

export default HighCashIncentivePage;
