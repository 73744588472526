import { gql } from "@apollo/client";

export const mutation_logout = gql`
  mutation logout($session_id: String!) {
    logout(session_id: $session_id) {
      status
      error {
        status_code
        message
      }
    }
  }
`;
