import { FilterItems } from "@helpers/constants";
import {
  dynamicRequest,
  get_system_cms_field,
  get_system_cms_value_clear,
  getsystemCMSFieldListSelector,
  useDynamicSelector,
} from "@services/redux";
import { get_cms_announcement_table_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_announcement_table_query";
import { Pagination, Skeleton } from "antd";
import { Box, HStack, Hidden, Stack, Text } from "native-base";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import TableLoading from "../events/table_loading";

const AnnouncementsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [announce_data, setAnnounceData] = useState([]);
  const [open_index, setOpenIndex] = useState(0);
  const handleExpendable = (index) => {
    setOpenIndex(index);
  };
  const [current, setCurrent] = useState(3);

  const {
    items: announcement_items,
    loading,
    pagination,
  } = useDynamicSelector("get_cms_announcement_table");
  const onChange = (page) => {
    setCurrent(page);
  };

  const get_announcement_table = () => {
    let keys = [{ key: "get_cms_announcement_table", loading: true }];
    let variables = {
      json: {
        system_cms_key: "announcement_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_announcement_table_query, variables));
  };

  useEffect(() => {
    get_announcement_table();
  }, []);

  useEffect(() => {
    let values = FilterItems(announcement_items);
    setAnnounceData(values);
  }, [announcement_items]);

  // const TableData = [
  //   {
  //     title: "Online Registration for CM Trophy 2022 - 23",
  //     hostel_list:
  //       "The district level games for Chief Minister Cup 2023 are being conducted district wise. Applications for Sportspersons/Sportspersons who have registered online are approved by their District Youth Welfare and Sports Welfare Officer.",
  //   },
  //   {
  //     title: "Tamil Nadu Champions Foundation",
  //     hostel_list: "",
  //   },
  //   {
  //     title: "Advertisement for High Performance Coach",
  //     hostel_list: "",
  //   },
  // ];
  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <Hidden from="base" till="lg">
            <table class="table">
              <thead className="table-header">
                <tr>
                  <th className="radius-right">{t("table:s_no")}</th>
                  <th className="radius-left">{t("table:announcement")}</th>
                </tr>
              </thead>
              <tbody>
                {announce_data.map((tablelist, index) => {
                  return (
                    <>
                      <tr
                        className={`space-add ${
                          announce_data.length - 1 !== index
                            ? "table-border-bottom"
                            : ""
                        }`}
                      >
                        <td className="index-padding">{index + 1}</td>
                        <td>
                          <div
                            onClick={() => handleExpendable(index)}
                            className={`table_row ${
                              open_index === index
                                ? `table_row_height`
                                : `table_row_normal`
                            }`}
                          >
                            {open_index === index ? (
                              <p className="competition_name">
                                <HStack variant={"table_title_tag"}>
                                  <Box width={"20px"}>
                                    <AiOutlineMinus />
                                  </Box>
                                  <Text variant={"table_title"}>
                                    {tablelist?.title?.value}
                                  </Text>
                                </HStack>
                              </p>
                            ) : (
                              <p className="competition_name">
                                <HStack variant={"table_title_tag"}>
                                  <Box width={"20px"}>
                                    <AiOutlinePlus fontWeight={600} />
                                  </Box>
                                  <Text variant={"table_title"}>
                                    {tablelist?.title?.value}
                                  </Text>
                                </HStack>
                              </p>
                            )}
                            <div
                              className={`table_open ${
                                open_index === index
                                  ? `table_active`
                                  : `table_inactive`
                              }`}
                            >
                              <Text
                                fontFamily={"Inter"}
                                textAlign={"justify"}
                                width={"94%"}
                              >
                                {tablelist?.description?.value}
                              </Text>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Hidden>

          <Hidden from="lg" till="3xl">
            <Box variant={"tender_table_box"}>
              {announce_data?.map((content, index) => {
                return (
                  <>
                    <Box variant={"tender_table_contents"}>
                      <Text variant={"tender_description"}>
                        {content?.title?.value}
                      </Text>
                      <Text>
                        <Text variant={"table_headline"} color={"#828282"}>
                          {t("details")}:
                        </Text>
                        <Text
                          color={"black"}
                          fontWeight={"500"}
                          marginLeft={"1px"}
                        >
                          {content?.description?.value}
                        </Text>
                      </Text>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Hidden>

          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              {/* <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden> */}
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default AnnouncementsTable;
