import { Button, Form } from "antd";
import JoditEditor from "jodit-react";
import VForm from "../antd_form/antd_form";
import { useEffect } from "react";
import { startCase } from "lodash";
import { HStack } from "native-base";
import { Form as AntdForm } from "antd";

const HTMLForm = ({ htmlForm, items, onFinishHtml, cms_loading }) => {
    const [form] = AntdForm.useForm()
    let html_data =
        items?.find((field) => field.key === "code")?.system_cms_values?.[0] ||
        {};
    htmlForm.resetFields();
    useEffect(() => {
        let values = items?.map((list) => {
            // if(list)
            if (list.type !== "file") {
                return { [list?.id]: list?.system_cms_values?.[0]?.value };
            } else {
                return {
                    [list?.id]:
                        [{
                            uid: "-1",
                            url: list?.system_cms_values?.[0]?.value || "",
                            status: "done",
                            name: "View",
                        }],
                }
            }
        });
        let setValues = Object.assign({}, ...values);
        form.setFieldsValue(setValues)
    }, [items]);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    return (
        <VForm form={form} onFinish={onFinishHtml}>
            <div className="form-container">
                {items?.map((list) => {
                    return (
                        <>
                            {list?.type === "select" ?
                                (
                                    <VForm.Select
                                        label={startCase(list?.key)}
                                        field={list?.id}
                                        options={stadium_option}
                                        valueField="id"
                                        labelFiled="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: `${startCase(list?.key)} is required`,
                                            },
                                        ]}
                                    />
                                ) : list?.type === "text" ? (
                                    <VForm.TextBox
                                        label={startCase(list?.key)}
                                        field={list?.id}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${startCase(list?.key)} is required`,
                                            },
                                        ]}
                                    />
                                ) : list?.type === "textarea" ? (
                                    <VForm.TextArea
                                        label={startCase(list?.key)}
                                        field={list?.id}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${startCase(list?.key)} is required`,
                                            },
                                        ]}
                                    />
                                ) : list?.type === "file" ? (
                                    <VForm.FormFile
                                        label={startCase(list?.key)}
                                        field={list?.id || ""}
                                        getValueFromEvent={normFile || []}
                                        allowed_file_formats={["pdf", "png", "jpg"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${startCase(list?.key)} is required`,
                                            },
                                        ]}
                                    />
                                ) : list?.type === "number" ? (
                                    <VForm.Number label={startCase(list?.key)} field={list?.id} rules={[
                                        {
                                            required: true,
                                            message: `${startCase(list?.key)} is required`,
                                        },
                                    ]} />
                                ) : list?.type === "datetime" ? (
                                    <VForm.Date label={startCase(list?.key)} field={list?.id} rules={[
                                        {
                                            required: true,
                                            message: `${startCase(list?.key)} is required`,
                                        },
                                    ]} />
                                ) : list.type === "rich_text" ? (
                                    <VForm.RichText
                                        label={startCase(list?.key)}
                                        field={list?.id}
                                        rules={[
                                            {
                                                required: true,
                                                message: `${startCase(list?.key)} is required`,
                                            },
                                        ]}
                                    />
                                ) : null}
                        </>
                    );
                })}
            </div>
            <HStack space={3} justifyContent={"flex-end"}>
                <VForm.Button isLoading={cms_loading} />
            </HStack>
        </VForm>
    );
};

export default HTMLForm;
