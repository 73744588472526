import { Pagination } from "antd";
import { Box, HStack, Hidden, Link, Stack, Text, VStack } from "native-base";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HighCashIncentiveResponsiveTable from "./high_cash_incentive_responsive_table";
import ReactHtmlParser from "react-html-parser";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { get_cms_high_case_incentive_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_high_cash_incentive";
import { useDispatch } from "react-redux";
import { useState } from "react";
import TableLoading from "../events/table_loading";

const HighCaseIncentiveTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [high_data, setHighData] = useState([]);

  const { items: high_cash_items, loading } = useDynamicSelector(
    "get_cms_high_case_incentive"
  );



  const get_high_case_incentive = () => {
    let keys = [{ key: "get_cms_high_case_incentive", loading: true }];
    let variables = {
      json: {
        system_cms_key: "high",
      },
    };
    dispatch(
      dynamicRequest(keys, get_cms_high_case_incentive_query, variables)
    );
  };

  useEffect(() => {
    get_high_case_incentive();
  }, []);

  useEffect(() => {
    let values = FilterItems(high_cash_items);
    setHighData(values.sort((a, b) => Number(a?.title?.row_number) - Number(b?.title?.row_number)));
  }, [high_cash_items]);

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <Text fontSize={"2.5vh"} bold fontFamily={"Inter"}>
            {t("high_cash_incentive_for_competition")}
          </Text>
          {high_data?.map((item) => {
            return (
              <>
                <Box
                  padding={"10px"}
                  fontFamily={"Inter"}
                  backgroundColor={"#E1E6FF"}
                  borderRadius={"10px"}
                  mt={"20px"}
                  mb={"20px"}
                  _text={{ textAlign: "justify" }}
                >
                  <Text bold fontSize={"var(--font-size-14)"}>{item?.title?.value}</Text>
                  {item?.note?.value}
                </Box>
                <Hidden from="base" till="lg">
                  {ReactHtmlParser(item?.content?.value)}
                </Hidden>

                <Hidden from="lg" till="3xl">
                  <HighCashIncentiveResponsiveTable
                    high_data={item?.content?.value}
                  />
                </Hidden>
              </>
            );
          })}
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default HighCaseIncentiveTable;
