import FontScaler from "./font_scaler";
import AppRouter from "@views/routes/app_router";

function App() {
  return (
    // <FontScaler>
    <AppRouter />
    // </FontScaler>
  );
}
export default App;
