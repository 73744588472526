import YouthHostelContents from "@views/components/youth_hostel/youth_hostel_content";
import React from "react";

const YouthHostel = () => {
  return (
    <div>
      <YouthHostelContents />
    </div>
  );
};
export default YouthHostel;
