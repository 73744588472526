import CentreOfExcellenceContent from "@views/components/sports_hostel/centre_of_excellence_content";
import SportsHostelContents from "@views/components/sports_hostel/sports_hostel_contents";
import React from "react";

const CentreOfExcellencePage = () => {
  return (
    <div>
      <CentreOfExcellenceContent />
    </div>
  );
};

export default CentreOfExcellencePage;
