import React from "react";
import { Box, HStack, VStack, Text, Center } from "native-base";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useEffect } from "react";
import { useState } from "react";
import { FilterItems } from "@helpers/constants";
import { get_cms_upcoming_events_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_upcoming_events_query";
import { Skeleton } from "antd";
const UpcomingEvent = ({ events_data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [events_data, setEventsData] = useState([]);
  // const { items: events_items, loading } = useDynamicSelector(
  //   "get_cms_upcoming_events"
  // );
  // set_loading_status(loading);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    large_laptop: {
      breakpoint: { max: 2560, min: 1640 },
      items: 1,
      slidesToSlide: 1,
    },
    laptop: {
      breakpoint: { max: 1024, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet1: {
      breakpoint: { max: 768, min: 480 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  // const get_upcoming_events = () => {
  //   let keys = [{ key: "get_cms_upcoming_events", loading: true }];
  //   let variables = {
  //     json: {
  //       system_cms_key: "upcoming_events",
  //     },
  //   };
  //   dispatch(dynamicRequest(keys, get_cms_upcoming_events_query, variables));
  // };

  // useEffect(() => {
  //   get_upcoming_events();
  // }, []);

  // useEffect(() => {
  //   let values = FilterItems(events_items);
  //   setEventsData(values);
  // }, [events_items]);

  const openEvent = (link) => {
    if (link?.file?.value && link?.link?.value) {
      window.open(link?.file?.value);
      window.open(link?.link?.value);
    } else if (link?.file?.value) {
      window.open(link?.file?.value);
    } else if (link?.link?.value) {
      window.open(link?.link?.value);
    }
  };

  return (
    <Box
      alignItems={{ xs: "center", lg: "none" }}
      mt={{ xs: "-12", md: "-4", lg: "-16" }}
    >
      <Box
        variant="UpcomingEventBox"
        style={{
          boxShadow: "-10px 0px 10px 0px  rgb(0 0 0 / 17%) ",
        }}
      >
        <Carousel
          responsive={responsive}
          customLeftArrow={<></>}
          infinite={true}
        >
          {events_data?.map((event, index) => {
            // const EventMonth = event?.month?.substring(0, 3);
            const date = new Date(event?.date?.value);
            const EventMonth = date.toLocaleString("default", {
              month: "short",
            });
            const EventYear = date.getFullYear();
            return (
              <div
                className={`carousel-height ${
                  event?.file?.value || event?.link?.value  ? "cursor-pointer" : ""
                }` }
                onClick={() => openEvent(event)}
              >
                <HStack space={3}>
                  <VStack space={1}>
                    <Box
                      variant="UpcomingContentBox"
                      alignContent={"center"}
                      justifyContent={"center"}
                    >
                      <Center>
                        {/* <Box height={{ xs: "30px", lg: "47px" }}>
                          <Text variant="NumberFormat">{event?.date}</Text>
                        </Box> */}
                        {/* <Box>
                          <Text variant="MonthFormat">{EventMonth}</Text>
                        </Box> */}

                        <Box height={{ xs: "30px", lg: "47px" }}>
                          <Text variant="YearFormat">{EventYear}</Text>
                        </Box>
                      </Center>
                    </Box>
                  </VStack>
                  <VStack space={1}>
                    <Box>
                      <Text variant="RightHeadLine">{t("upcoming_event")}</Text>
                    </Box>
                    <Text variant="RightSecondHeadLine">
                      {event?.event_name?.value}
                    </Text>
                    <Text variant="RightDescription">
                      {event?.description?.value}
                    </Text>
                  </VStack>
                </HStack>
              </div>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
};
export default UpcomingEvent;
