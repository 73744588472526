import React from "react";
import { Pressable, Text } from "native-base";
import Card from "@src/threecard/Card";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get_cms_announcement_table_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_announcement_table_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { FilterItems } from "@helpers/constants";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

const Announcements = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [announce_data, setAnnounceData] = useState([]);

  const { items: announcement_items } = useDynamicSelector(
    "get_cms_announcement_table"
  );


  const get_announcement_table = () => {
    let keys = [{ key: "get_cms_announcement_table", loading: true }];
    let variables = {
      json: {
        system_cms_key: "announcement_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_announcement_table_query, variables));
  };

  useEffect(() => {
    get_announcement_table();
  }, []);

  useEffect(() => {
    let values = FilterItems(announcement_items);
    setAnnounceData(values);
  }, [announcement_items]);

  const AnnouncementList = (announce_data) => (
    <>
      {announce_data?.map((information) => (
        <Pressable
          onPress={() => {
            history.push(ROUTES.ANNOUNCEMENTS_PAGE);
          }}
        >
          <Text variant="announcementCard_txt">
            {information?.title?.value}
          </Text>
        </Pressable>
      ))}
    </>
  );

  return (
    <>
      <Card
        header={t("announcements")}
        component={AnnouncementList(announce_data)}
        url={ROUTES.ANNOUNCEMENTS_PAGE}
      />
    </>
);
};

export default Announcements;
