import React, { useEffect, useRef, useState } from "react";
import {
  HStack,
  VStack,
  Text,
  Image,
  useColorModeValue,
  IconButton,
  Icon,
  Center,
  Hidden,
  StatusBar,
  Stack,
  Box,
  Pressable,
} from "native-base";
import AntDesign from "react-native-vector-icons/dist/AntDesign";
import Entypo from "react-native-vector-icons/dist/Entypo";
import FloatingLabelInput from "./components/FloatingLabelInput";
import { ROUTES } from "@views/routes/my_routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  login,
  loginSelector,
  set_login_reset,
} from "@services/redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "native-base";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { showToast } from "@helpers/toast";
import Footer from "./footer";
import Header from "./header";
import Admin from "@assets/icons/admin-portal.png";
import { Button, Form, Input, Spin } from "antd";
import { IoReloadOutline } from "react-icons/io5";
import lock from "@assets/icons/padlock.png";
import locked from "@assets/icons/lock_pass.png";
import Profile from "@assets/icons/avatar.png";
import Profile_icon from "@assets/icons/profile.png";
import SprotsLogo from "@assets/images/SDAT New English Logo.png";
import { color_gradients } from "@helpers/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { query_get_captcha } from "@services/redux/slices/dynamic_entity/graphql/graphql_captcha";
import { dynamicClear, dynamicRequest, useDynamicSelector } from "@services/redux";
import { decryptData, encryptData } from "@helpers/crypto";
import { getUUID } from "@helpers/uuid";
import { query_request_seed } from "@services/redux/slices/login/graphql";

// import FooterBar from "@views/components/ui/footer_bar";
export function SignInForm({ props }) {
  // add next router here
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    item,
    authorize,
    loading,
    error,
    is_profile_completed,
    status: login_status,
  } = useSelector(loginSelector);
  const { captcha, loading: captcha_loading, error: captcha_error } = useDynamicSelector("getCaptcha")
  const { data: seed } = useDynamicSelector("requestSeed")
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const [captcha_value, set_Captcha] = useState("")
  const [seed_request, set_seed_request] = useState({});
  const canvasRef = useRef();

  const handleLogin = (values) => {
    dispatch(login({ ...values, username: encryptData(values?.username, seed_request?.key), password: encryptData(values?.password, seed_request?.key), id: seed_request?.id }));
  };

  const createCaptcha = () => {
    let decryptCaptcha = decryptData(captcha);
    // var canvas = canvasRef.current;
    // var context = canvas.getContext("2d");
    // context.clearRect(0, 0, 100, 100);
    // context.font = "20px Georgia white";
    // context.fillStyle = "black";
    // context.fillText(decryptCaptcha, 20, 30);
    let canvas = canvasRef.current;
    canvas.width = 150;
    canvas.height = 100;
    let ctx = canvas.getContext("2d");
    ctx.font = "bold 22px Arial";
    ctx.fillStyle = "white";
    ctx.fillText(decryptCaptcha, 10, 20);
    ctx.textAlign = "center";
    ctx.alignItems = "center";
    ctx.justifyContent = "center";
  };

  const get_captcha = () => {
    let keys = [{ key: "getCaptcha", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, query_get_captcha, variables));
  };
  const requested_seed = () => {
    let keys = [{ key: "requestSeed", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, query_request_seed, variables));
  };

  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);

  useEffect(() => {
    if (seed) {
      let value = decryptData(seed)
      set_seed_request(value)
    }
  }, [seed])

  useEffect(() => {
    get_captcha()
    requested_seed()
  }, [])

  useEffect(() => {
    if (captcha_error) {
      showToast({ type: "error", message: captcha_error?.message || captcha_error })
      dispatch(dynamicClear("getCaptcha"))
    }
  }, [captcha_error])

  if (authorize) {
    history.push({
      pathname: `${ROUTES.CMS_LIST}`,
      state: item,
    });
  }
  useEffect(() => {
    if (error) {
      showToast({
        type: "error",
        message: error,
      });
      dispatch(set_login_reset());
    } else if (login_status === "success") {
      history.push(ROUTES.CMS_LIST);
      dispatch(set_login_reset());
    }
  }, [error, login_status]);
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  const handle_get_captcha = () => {
    get_captcha()
    requested_seed()
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  return (
    <HStack
      width={"900px"}
      backgroundColor={"white"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"500px"}
      style={{
        borderRadius: "26px",
      }}
    >
      <Box
        flex={1}
        bgImage={color_gradients.menu_gradients}
        height={"100%"}
        justifyContent={"center"}
        borderLeftRadius={"26px"}
        borderBottomLeftRadius={"26px"}
      // opacity={0.9}
      // opacity={0.9}
      >
        <VStack
          alignItems={"center"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <Box width={"170px"}>
            <img src={SprotsLogo} alt="SprotsLogo" />
          </Box>
          <Text fontSize={"xl"} fontWeight={800} color={"white"}>
            {t("SPORTS DEVELOPMENT AUTHORITY OF TAMILNADU")}
          </Text>
        </VStack>
      </Box>
      <Box space={3} justifyItems={"center"} alignItems={"center"} flex={1}>
        <Box mx="auto">
          <Form onFinish={handleLogin} autoComplete="off">
            <Form.Item
              // label="Username"
              name={"username"}
              rules={[
                {
                  required: true,
                  message: "Please enter your username / Email",
                },
                {
                  pattern: /^[^<>]+$/,
                  message: "Invalid characters entered.",
                },
              ]}
            >
              <Input
                style={{
                  borderLeft: "0px",
                  borderRight: "0px",
                  borderTop: "0px",
                  borderColor: "#aa2dae",
                  borderWidth: "1px",
                  height: "45px",
                  borderRadius: "40px",
                  loading,
                }}
                maxLength={30}
                placeholder="Username"
                defaultValue={text}
                onPressEnter={handleKeypress}
                onChange={(txt) => setText(txt.target.value)}
                prefix={
                  text ? (
                    <img src={Profile_icon} alt="Profile Icon" width="12px" />
                  ) : (
                    <img src={Profile} alt="Profile" width="12px" />
                  )
                }
                autoComplete={false}
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern: /^[^<>]+$/,
                  message: "Invalid characters entered.",
                },
              ]}
            >
              <Input.Password
                style={{
                  borderLeft: "0px",
                  borderRight: "0px",
                  borderTop: "0px",
                  borderColor: "#aa2dae",
                  borderWidth: "1px",
                  height: "45px",
                  borderRadius: "40px",
                }}
                maxLength={30}
                placeholder="Password"
                // type={showPass ? "text" : "password"}
                defaultValue={pass}
                onChangeText={(txt) => setPass(txt)}
                onChange={(e) => setPass(e.target.value)}
                prefix={
                  pass ? (
                    <img src={locked} alt="Profile Icon" width="12px" />
                  ) : (
                    <img src={lock} alt="Profile" width="12px" />
                  )
                }
                autoComplete={false}
              />
            </Form.Item>
            <HStack height={"70px"} space={4}>
              <HStack mt={5} marginTop={"0px"} space={2}>
                <Box
                  style={{
                    borderRadius: "10px",
                    // backgroundColor: "#cfdaff",
                    alignItems: "center",
                    width: "100px",
                    height: "50px",
                  }}
                  bgImage={
                    "linear-gradient(99.9deg, rgb(56, 29, 109), rgb(170, 45, 174))"
                  }
                >

                  <canvas
                    ref={canvasRef}
                    style={{
                      marginLeft: "55px",
                      objectFit: "cover",
                      marginTop: "15px",
                      display: captcha_loading ? "none" : "block"
                    }}
                  />
                  {captcha_loading &&
                    <div class="leap-frog">
                      <div class="leap-frog__dot"></div>
                      <div class="leap-frog__dot"></div>
                      <div class="leap-frog__dot"></div>
                    </div>
                  }
                </Box>
                <Box marginTop={4}>
                  <Pressable onPress={handle_get_captcha}>
                    <IoReloadOutline
                      // onClick={get_one_captcha}
                      size={20}
                      style={{ cursor: "pointer", color: "#15255e" }}
                    />
                  </Pressable>
                </Box>
              </HStack>
              <Form.Item
                name={"captcha"}
                rules={[
                  {
                    required: true,
                    message: "Captcha is required",
                  },
                  {
                    pattern: /^[^<>]+$/,
                    message: "Invalid characters entered.",
                  },
                ]}
              >
                <Input
                  style={{
                    borderLeft: "0px",
                    borderRight: "0px",
                    borderTop: "0px",
                    borderColor: "#094b7f",
                    borderWidth: "1px",
                    height: "45px",
                    borderRadius: "40px",
                  }}
                  placeholder="Captcha"
                  onChangeText={(txt) => set_Captcha(txt)}
                  onChange={(e) => set_Captcha(e.target.value)}
                // prefix={
                //   get_captcha ? (
                //     <HiPuzzle color="#094b7f" />
                //   ) : (
                //     <HiPuzzle color="#b5a4a4" />
                //   )
                // }
                />
              </Form.Item>
            </HStack>
            {/* <Box mt={"10px"}>
              <Text
                textAlign={"end"}
                fontSize={"15px"}
                // color={"white"}
                style={{ cursor: "pointer" }}
              // onClick={() => handleForgotPassword()}
              >
                {"Forgot Password?"}
              </Text>
            </Box> */}
            <Box alignItems={"center"} mt={"10px"}>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#aa2dae",
                    borderRadius: "24px",
                    width: "150px",
                    color: "white",
                  }}
                  className="btn_width"
                  type="text"
                  htmlType="submit"
                  loading={loading}
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Box>
          </Form>
        </Box>
      </Box>
    </HStack>
  );
}
export default function SignIn(props) {
  const { t } = useTranslation();
  return (
    <>
      {/* <Header /> */}
      <div className="background-images-set">
        <Box
          _dark={{
            bgImage: `url("")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          _light={{
            bgImage: `url("")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          overflowY="hidden"
        // mt={"-5px"}
        //   height={"fit-content"}
        >
          <Center>
            <Stack>
              <Box flex={1}>
                <SignInForm props={props} />
              </Box>
            </Stack>
          </Center>
        </Box>
      </div>
      <Footer />
    </>
  );
}
