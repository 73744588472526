import React from "react";
import { HStack, Center, Divider, Box, Column } from "native-base";
import Card from "./Card";
import Announcements from "./announcment/announcement";
import Events from "./events/events";
import Tender from "./tender/tenders";
import Notifications from "./notifications/notifications";

export const Cards = () => {
  return (
    <Box mb={"80px"}>
      <Center>
        <Box>
          <HStack
            flexDirection={{ base: "Column", md: "row" }}
            space={{ base: "21px", md: "10px", lg: "15px", xl: "50px" }}
          >
            <Announcements />
            <Events />
            {/* <Tender /> */}
            <Notifications />
          </HStack>
        </Box>
      </Center>
    </Box>
  );
};
