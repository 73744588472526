import { createSlice } from "@reduxjs/toolkit";
import { MutateRequest } from "@services/apollo/api_service";
import { updatePageMutation } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    id: null,
    status: null,
};

const slice = createSlice({
    name: "pageUpdate",
    initialState,
    reducers: {
        _update_page: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _update_page_reset: (state) => {
            state.loading = false;
            state.id = null;
            state.status = null;
            state.error = null;
        },
        _update_page_success: (state, { payload }) => {
            state.loading = false;
            state.error = null;
            state.id = payload.id;
            state.status = payload.status;
        },
        _update_page_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
        },
    },
});

const {
    _update_page,
    _update_page_reset,
    _update_page_success,
    _update_page_failure,
} = slice.actions;

export const updatePageSelector = (state) => state.pageUpdate;

export const updatePageReducer = slice.reducer;

export function update_page(variables) {
    return async (dispatch) => {
        dispatch(_update_page());
        try {
            const response = await MutateRequest(
                updatePageMutation,
                variables,
                dispatch
            );
            if (
                response?.data?.updatePage &&
                !response?.data?.updatePage.error
            ) {
                dispatch(_update_page_success(response?.data?.updatePage));
            } else if (response?.data?.updatePage?.error) {
                dispatch(
                    _update_page_failure(response?.data?.updatePage.error)
                );
            }
        } catch (error) {
            dispatch(_update_page_failure(error));
        }
    };
}
export async function update_one_page_clear() {
    return async (dispatch) => {
        dispatch(_update_page_reset());
    };
}
