import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/menu/sport-logo.png";
import { Button } from "antd";
import {
  Text,
  Box,
  Center,
  Image,
  HStack,
  Hidden,
  Stack,
  VStack,
} from "native-base";
import { useTranslation } from "react-i18next";
import { Timeline } from "react-twitter-widgets";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import ScrollView from "../ui/scroll_view/scroll_view";
import OAuth from "oauth-1.0a";
import axios from "axios";

const TwitterPage = () => {
  const querystring = require("querystring-es3")
  const { t } = useTranslation();
  const [tweets, setTweets] = useState([]);
  const twitterApiUrl = 'https://api.twitter.com/2/tweets/user_timeline.json';
  const targetUserName = 'SportsTN_'; // Replace with the desired Twitter username

  useEffect(() => {
    const fetchTweets = async () => {
      try {
        const oauth = createOAuthInstance();
        const params = new URLSearchParams({
          screen_name: targetUserName,
          count: 10,
        });

        const url = `${twitterApiUrl}?${params.toString()}`;
        const requestData = {
          url,
          method: 'GET',
        };

        try {
          const response = await axios.get(
            twitterApiUrl,
            {
              params: {
                screen_name: targetUserName, // Replace 'twitterusername' with the Twitter username whose tweets you want to fetch
                count: 5, // Number of tweets to fetch
              },
              headers: {
                Authorization: `Bearer AAAAAAAAAAAAAAAAAAAAAAjisQEAAAAAYOhKZkA3lp1FGw78ujgQ%2BAnApS0%3DgTiKHDnSqk2vidaYfOgdfcajA0MQsgHaQAyhMZFl9bSgCRXPUr`, // Replace 'YOUR_ACCESS_TOKEN' with your Twitter access token
              },
            }
          );

          setTweets(response.data);
        } catch (error) {
          console.error('Error fetching tweets:', error);
        }
        setTweets(response.data);
      } catch (error) {
        console.error('Error fetching tweets:', error);
      }
    };

    fetchTweets();
  }, []);

  const createOAuthInstance = () => {
    return OAuth({
      consumer: {
        key: process.env.REACT_APP_TWITTER_API_KEY || 'Hgx5vzUSjjDlNL7Eq9hcFARvE',
        secret: process.env.REACT_APP_YOUR_API_SECRET_KEY || "e4qrHVmJaYhq7f9mfUH8dIXKsXw3kmjurSiDlK9gJnObhVrvru",
      },
      signature_method: 'HMAC-SHA1',
      hash_function: (base_string, key) => window.btoa(key),
    });
  };
  return (
    <>
      <Box>
        <Box
          alignItems={{
            base: "start",
            xs: "center",
            lg: "start",
            "2xl": "start",
          }}
        >
          <Center variant="galleryAndTwitterTitle">
            <Text variant={"TwitterTitleOne"} textTransform="capitalize">
              {t("twitter")}
            </Text>
            <Text variant={"TwitterTitleTwo"}>{t("twitter")}</Text>
          </Center>
        </Box>
        <Box>
          <Stack
            display={"flex"}
            flexDirection={{ md: "row", lg: "column" }}
            justifyContent={"center"}
          >
            <Center variant="galleryAndTwitterTitle">
              <Box variant="TwitterContentContainer" height={"450px"}>

                <ScrollView>
                  <Timeline
                    dataSource={{
                      sourceType: "profile",
                      screenName: "SportsTN",
                      // url: "https://twitter.com/SportsTN_?ref_src=twsrc%5Etfw",
                      url: "https://twitter.com/SportsTN_?ref_src=twsrc%5Etfw",
                    }}
                    options={{
                      width: "100%",
                      // height: "43vh",
                      overflow: "scroll",
                      chrome: "transparent",
                    }}
                  /> 
                  {/* <TwitterTimelineEmbed
                    sourceType="profile"
                    userId={"1538887468832894977"}
                    
                    options={{ height: 400 }}
                  /> */}
                  
                </ScrollView>
              </Box>
            </Center>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default TwitterPage;
