import { gql } from "@apollo/client";

export const mutation_sports_createSystemCmsSportsData = gql`
  mutation createSystemCmsSportsData(
    $json: create_system_cms_sports_data_input
  ) {
    createSystemCmsSportsData(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_sports_updateSystemCmsSportsData = gql`
  mutation updateSystemCmsSportsData(
    $json: update_system_cms_sports_data_input
  ) {
    updateSystemCmsSportsData(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_sports_deleteSystemCmsSportsData = gql`
  mutation deleteSystemCmsSportsData(
    $json: delete_system_cms_sports_data_input
  ) {
    deleteSystemCmsSportsData(json: $json) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_all_system_cms_sports_data = gql`
  query getAllSystemCmsSportsData($json: get_all_system_cms_sports_data_input) {
    getAllSystemCmsSportsData(json: $json) {
      items {
        id
        name
        system_cms_sports_data_value {
          id
          name
          details
        }
      }
    }
  }
`;

export const get_sports_hostel_details = gql`
  query getSystemCmsFields($json: get_all_system_cms_field_input) {
    get_cms_sports_hostel_details: getSystemCmsFields(json: $json) {
      items {
        id
        key
        type
        system_cms {
          id
        }
        system_cms_values {
          id
          value
          row_number
          system_cms_field_id
          system_cms_language_id
          system_cms_field_option_value_id
        }
      }
    }
  }
`;

