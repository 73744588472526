import React from 'react'
import TenderContents from '@views/components/tender/tender_contents'
const TenderPage = () => {
  return (
    <>
    <TenderContents/>
    </>
  )
}

export default TenderPage