import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_cms_query } from "./graphql";
// import { get_departments_query } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    items: [],
    // item: {},
    total_items: null,
    page_number: null,
    page_limit: null,
    sort_column: null,
    sort_order: null,
};

const slice = createSlice({
    name: "cmsList",
    initialState,
    reducers: {
        _get_cms: (state) => {
            state.loading = true;
            state.id = null;
            state.status = null;
        },
        _get_cms_success: (state, { payload }) => {
            state.loading = false;
            state.items = payload.items;
            state.error = null;
            state.total_items = payload.pagination?.total_count;
            state.page_number = payload.pagination?.page_number;
            state.page_limit = payload.pagination?.page_limit;
        },
        _get_cms_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
            state.total_items = [];
        },
    },
});

const { _get_cms, _get_cms_success, _get_cms_failure } =
    slice.actions;

export const getcmsListSelector = (state) => state.cmsList;

export const getcmsListReducer = slice.reducer;

export function get_cms(variables) {
    return async (dispatch) => {
        dispatch(_get_cms());
        try {
            const response = await QueryRequest(
                get_cms_query,
                variables,
                dispatch
            );
            if (
                response?.data?.getSystemCmss &&
                !response?.data?.getSystemCmss.error
            ) {
                dispatch(_get_cms_success(response?.data?.getSystemCmss));
            } else if (response?.data?.getSystemCmss?.error) {
                dispatch(
                    _get_cms_failure(response?.data?.getSystemCmss.error)
                );
            }
        } catch (error) {
            dispatch(_get_cms_failure(error));
        }
    };
}
