import ApplicationFormContents from "@views/components/application_form/application_form_contents";
import React from "react";

const ApplicationFormPage = () => {
  return (
    <div>
      <ApplicationFormContents />
    </div>
  );
};

export default ApplicationFormPage;
