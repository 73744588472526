import React, { useState } from "react";
import { Box, VStack } from "native-base";
import ScrollView from "@views/components/ui/scroll_view";
import FooterPage from "@views/components/footer/footer";
import HeaderPage from "@views/components/banner";
import FontScaler from "../../font_scaler";
const Layout1 = ({ children }) => {
  return (
    <Box flexDirection="row" flexGrow="1">
      <VStack flex={1}>
        <ScrollView>
          <FontScaler>
            <HeaderPage />
          </FontScaler>
          <div
            className="layout-children"
          >
            <FontScaler>
              {children}
            </FontScaler>
          </div>
          <FontScaler>
            <FooterPage />
          </FontScaler>
        </ScrollView>
      </VStack>
    </Box>

  );
};
export default Layout1;
