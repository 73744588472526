import React from "react";
import "../Button/button.css";
const IconButton = (props) => {
  return (
    <button {...props} className="delete-button">
      <span className="span">{props?.icon}</span>
    </button>
  );
};

const CustomizeButton = () => {
  return <div></div>; // This div doesn't seem to serve any purpose in this component
};

CustomizeButton.IconButton = IconButton;

export default CustomizeButton;
