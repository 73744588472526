import { createSlice } from "@reduxjs/toolkit";
import { QueryRequest } from "@services/apollo/api_service";
import { get_one_cms_query } from "./graphql";

const initialState = {
    loading: false,
    error: null,
    item: null,
};

const slice = createSlice({
    name: "cmsGet",
    initialState,
    reducers: {
        _get_one_cms: (state) => {
            state.loading = true;
            state.item = null;
            state.error = null;
        },

        _get_one_cms_success: (state, { payload }) => {
            state.loading = false;
            state.item = payload;
            state.error = null;
        },
        _get_one_cms_failure: (state, { payload }) => {
            state.loading = false;
            state.error = payload.message;
            state.item = null;
        },
    },
});

const {
    _get_one_cms,
    _get_one_cms_success,
    _get_one_cms_failure,
} = slice.actions;

export const getcmsSelector = (state) => state.cmsGet;

export const getcmsReducer = slice.reducer;

export function get_one_cms(variables) {
    return async (dispatch) => {
        dispatch(_get_one_cms());
        try {
            const response = await QueryRequest(
                get_one_cms_query,
                variables,
                dispatch
            );
            if (
                response?.data?.getSystemCms &&
                !response?.data?.getSystemCms.error
            ) {
                dispatch(
                    _get_one_cms_success(response?.data?.getSystemCms)
                );
            } else if (response?.data?.getSystemCms?.error) {
                dispatch(
                    _get_one_cms_failure(
                        response?.data?.getSystemCms.error
                    )
                );
            }
        } catch (error) {
            dispatch(_get_one_cms_failure(error));
        }
    };
}
export function get_one_cms_clear() {
    return async (dispatch) => {
        dispatch(_get_one_cms());
    };
}
