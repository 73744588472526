import AnnouncementsContents from "@views/components/announcements/announcements_contents";
import React from "react";

const AnnouncementsPage = () => {
  return (
    <div>
      <AnnouncementsContents />
    </div>
  );
};

export default AnnouncementsPage;
