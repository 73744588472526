import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

import { retrieveItem } from "@helpers/storage";
import { showToast } from "@helpers/toast";

// const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BASE_URL="https://sdat.tn.gov.in/api"
// const BASE_URL="http://192.168.1.50:5026/graphql"
// const BASE_URL="https://dev.vertace.org/sdat-website/graphql"


const httpLink = createHttpLink({
  uri: BASE_URL,
});
  
const authLink = setContext(async (_, { headers }) => {
  const session_id = retrieveItem("session_id");
  return {
    headers: {
      ...headers,
      token: session_id || "",
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export const handle_logout = () => {
  // window.localStorage.clear();
  // window.location.replace("/admin");
};

export async function QueryRequest(query, variables, dispatch) {
  let response = null;
  try {
    response = await client.query({
      query,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      showToast({ type: "error", message: "Not authorized" });
      handle_logout();
      return null;
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
      handle_logout();
    }
    return null;
  }
}

export async function MutateRequest(mutation, variables, dispatch) {
  let response = null;
  try {
    response = await client.mutate({
      mutation,
      variables,
    });
    let isUnauthorized =
      JSON.stringify(response).indexOf("Not authorized") > -1;
    if (isUnauthorized && dispatch) {
      //  dispatch(logoutRequest("invalid"));
      showToast({ type: "error", message: "Not authorized" });
      handle_logout();
      return null;
    }
    return response;
  } catch (e) {
    if (dispatch) {
      //  dispatch(logoutRequest("invalid"));
      handle_logout();
    }
    return null;
  }
}
