import { Box, Hidden, SimpleGrid, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import Servicebox from "./service_box";
import BackgroundWaterMarkLogo from "@views/pages/home/background_water_mark_logo";

const Servicepagecontents = () => {
  const { t } = useTranslation();
  return (
    <VStack overflow={"hidden"} mb={"50px"}>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("services")}</Text>
        </Hidden>
      </Box>
      <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
        <Box>
          <Text variant={"page_title"} color={"black"} paddingBottom={"0px"}>
            {t("services")}
          </Text>
        </Box>
      </Hidden>
      <Box padding={"20px"}>
        {/* <BackgroundWaterMarkLogo /> */}
        <Box padding={"30px"}>
          <Servicebox />
        </Box>
      </Box>
    </VStack>
  );
};

export default Servicepagecontents;
