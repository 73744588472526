import { Box, Hidden, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import TenderPageTable from "./tender_page_table";
import Largelogo from "@assets/images/menu/background_image.png";
const TenderContents = () => {
  const { t } = useTranslation();
  return (
    <div style={{ overflow: "hidden" }}>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("tenders")}</Text>
        </Hidden>
      </Box>
      <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
        <Box>
          <Text variant={"page_title"} color={"black"} paddingBottom={"0px"}>
            {t("tenders")}
          </Text>
        </Box>
      </Hidden>
      <Box width={"100%"}>
        {/* <Box
          variant={"service_content_image_box"}
          marginTop={["", "", "", "40px", "250px", "30px"]}
        >
          <img src={Largelogo} alt="largelogo" className="sport-large-logo" />
        </Box> */}
        <Box
          padding={{
            base: "20px",
            md: "60px",
            lg: "60px",
            xl: "60px",
            "2xl": "60px",
          }}
        >
          <TenderPageTable />
        </Box>
      </Box>
    </div>
  );
};
export default TenderContents;
