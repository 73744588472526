import SportsHostelContents from "@views/components/sports_hostel/sports_hostel_contents";
import SportsHostelOfExcellenceContents from "@views/components/sports_hostel/sports_hostel_of_excellence_content";
import React from "react";

const SportsHostelOfExcellencePage = () => {
  return (
    <div>
      <SportsHostelOfExcellenceContents />
    </div>
  );
};

export default SportsHostelOfExcellencePage;
