import { Box, HStack, Hidden, Link, Stack, Text } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../tender/table.css";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  get_system_cms_field,
  getsystemCMSFieldListSelector,
} from "@services/redux";
import { FilterItems } from "@helpers/constants";
import moment from "moment";
import TableLoading from "../events/table_loading";

const TenderPageTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);


  const { items, loading } = useSelector(getsystemCMSFieldListSelector);

  useEffect(() => {
    dispatch(
      get_system_cms_field({
        json: {
          system_cms_key: "tenders_table",
        },
      })
    );
  }, []);

  useEffect(() => {
    let values = FilterItems(items);
    values.sort((a, b) => moment(b?.closing_date?.value)- moment(a?.closing_date?.value));
    setData(values);
  }, [items]);

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <div>
          <Hidden from="base" till="lg">
            <div>
              <table class="table">
                <thead className="table-header">
                  <tr>
                    <th className="radius-right">{t("table:s_no")}</th>
                    <th>{t("tenders")}</th>
                    <th>{t("table:closing_date")}</th>
                    <th className="radius-left">{t("table:reference_no")}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((tablelist, index) => {
                    return (
                      <>
                        <tr
                          className={`space-add ${
                            data.length - 1 !== index
                              ? "table-border-bottom"
                              : ""
                          }`}
                        >
                          <td data-label="S.No">{index + 1}</td>
                          <td data-label="Tenders">
                            <Link
                              _text={{
                                color: "#2F80ED",
                                textDecoration: "none",
                                fontWeight: "500",
                                maxWidth: "1400px",
                                minWidth: "600px",
                                fontFamily: "Inter",
                              }}
                              href={tablelist?.document?.value}
                              isExternal
                            >
                              {tablelist?.tenders?.value}
                            </Link>
                            {/* {tablelist.Supporting_docs.length
                          ? tablelist.Supporting_docs.map((s) => {
                              return (
                                <>
                                  <Link
                                    marginTop={"10px"}
                                    _text={{
                                      color: "#2F80ED",
                                      textDecoration: "none",
                                      fontWeight: "500",
                                      maxWidth: "1400px",
                                      minWidth: "600px",
                                      fontFamily: "Inter",
                                    }}
                                    href={s?.urls}
                                    isExternal
                                  >
                                    {s?.name}
                                  </Link>
                                </>
                              );
                            })
                          : ""} */}
                          </td>
                          <td
                            data-label="Closing Date"
                            className="column-width"
                          >
                            {moment(
                              new Date(tablelist?.closing_date?.value)
                            ).format("DD/MM/YYYY")}
                          </td>
                          <td
                            data-label="Reference No"
                            className="column-width"
                          >
                            {tablelist?.reference_no?.value}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Hidden>
          <Hidden from="lg" till="3xl">
            <Box variant={"tender_table_box"}>
              {data.map((content, index) => {
                return (
                  <>
                    <Box variant={"tender_table_contents"}>
                      {/* <Text variant={"tender_description"}> */}
                      <Link
                        _text={{
                          color: "#2F80ED",
                          textDecoration: "none",
                          fontWeight: "500",
                          maxWidth: "1400px",
                          fontFamily: "Inter",
                        }}
                        href={content.doc}
                        isExternal
                      >
                        {content?.tenders?.value}
                      </Link>
                      {/* {content.Supporting_docs.length
                    ? content.Supporting_docs.map((s) => {
                        return (
                          <>
                            <Link
                              marginTop={"10px"}
                              _text={{
                                color: "#2F80ED",
                                textDecoration: "none",
                                fontWeight: "500",
                                maxWidth: "1400px",
                                fontFamily: "Inter",
                              }}
                              href={s?.urls}
                              isExternal
                            >
                              {s?.name}
                            </Link>
                          </>
                        );
                      })
                    : ""} */}
                      {/* </Text> */}
                      <Text>
                        <Text variant={"table_headline"} color={"#828282"}>
                          {t("closing_date")}:
                        </Text>
                        <Text
                          color={"black"}
                          fontWeight={"500"}
                          marginLeft={"1px"}
                        >
                          {moment(
                            new Date(content?.closing_date?.value)
                          )?.format("DD/MM/YYYY")}
                        </Text>
                      </Text>
                      <Text>
                        <Text variant={"table_headline"} color={"#828282"}>
                          {t("reference_no")}:
                        </Text>
                        <Text
                          color={"black"}
                          fontWeight={"500"}
                          marginLeft={"1px"}
                          fontFamily={"Inter"}
                        >
                          {content?.reference_no?.value}
                        </Text>
                      </Text>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default TenderPageTable;
