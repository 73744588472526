import { Image } from "antd";
import React from "react";
import BrokenImage from "../../../assets/icons/broken_image.png";
import { useBreakpointValue } from "native-base";

const ImageGrid = ({ images }) => {
  const responsiveData = useBreakpointValue({
    base: { is_mobile: true, rowLayouts: [[1, 2, 2]] },
    xs: { is_mobile: true, rowLayouts: [[1, 2, 2]] },
    sm: { is_mobile: true, rowLayouts: [[1, 2, 2]] },
    md: { is_mobile: true, rowLayouts: [[1, 2, 2]] },
    lg: {
      is_mobile: false,
      rowLayouts: [
        [1, 2, 2],
        [2, 1, 2],
        [2, 2, 1],
      ],
    },
    xl: {
      is_mobile: false,
      rowLayouts: [
        [1, 2, 2],
        [2, 1, 2],
        [2, 2, 1],
      ],
    },
    "2xl": {
      is_mobile: false,
      rowLayouts: [
        [1, 2, 2],
        [2, 1, 2],
        [2, 2, 1],
      ],
    },
  });
  const numberOfRows = images?.length / 5 + 1;

  let imageIndex = 0;

  const get_image_class_name = (is_mobile, column_count) => {
    if (is_mobile) {
      if (column_count == 1) {
        return "gallery_mobile_main_image_size";
      } else {
        return "gallery_mobile_other_image_size";
      }
    } else {
      if (column_count == 1) {
        return "gallery_main_image_size";
      } else {
        return "gallery_other_image_size";
      }
    }
  };

  const get_col_class_name = (is_mobile, column_count) => {
    if (is_mobile) {
      if (column_count == 1) {
        return "col-12 gallery_mobile_row_space";
      } else {
        return "col-6 gallery_mobile_other_image_space";
      }
    } else {
      if (column_count == 1) {
        return "col-6 gallery_row_space";
      } else {
        return "col-3 gallery_other_image_space";
      }
    }
  };

  const renderImages = (rowLayouts, images, isMobile) =>
    Array.from({ length: numberOfRows }).map((_, rowIndex) => {
      const rowLayout = rowLayouts[rowIndex % rowLayouts.length];
      console.log("isMobile", isMobile);
      return (
        <div key={rowIndex} className="row">
          {rowLayout.map((columnCount, columnIndex) => {
            const imagesInColumn = [];
            // Collect the specified number of images for the column
            for (let i = 0; i < columnCount; i++) {
              if (imageIndex < images?.length) {
                imagesInColumn.push(
                  <Image
                    width="100%"
                    src={images[imageIndex]?.image_url || BrokenImage}
                    alt="gallery-image"
                    className={get_image_class_name(isMobile, columnCount)}
                  />
                );
                imageIndex++;
              }
            }
            return (
              <div
                key={columnIndex}
                className={get_col_class_name(isMobile, columnCount)}
              >
                {imagesInColumn}
              </div>
            );
          })}
        </div>
      );
    });

  return (
    <div>
      {renderImages(
        responsiveData.rowLayouts,
        images,
        responsiveData.is_mobile
      )}
    </div>
  );
};

export default ImageGrid;
