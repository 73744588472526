import { Box, Hidden, Text } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import MenuBar from "../banner/menubar";
import VigilanceAntiCorruptionTable from "./vigilance_anti_corruption_table";
import { color_gradients } from "@helpers/constants";

const VigilanceAntiCorruptionContents = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Box variant={"tender_page_menubox"} height={"auto"}>
        <Box height={"120px"}>
          <MenuBar />
          <Hidden only={["lg", "xl", "2xl", "3xl"]}>
            <Box variant={"xs_size_menu_headline"}>
              <Text variant={"xs_size_menu_headline_one"}>
                {t("header_content_one")}
              </Text>
              <Text variant={"xs_size_menu_headline_two"}>
                {t("sports_development_authority_of_tamilnadu")}
              </Text>
            </Box>
          </Hidden>
        </Box>
        <Hidden only={["xs", "sm"]}>
          <Text variant={"page_title"}>{t("vigilance_anti_corruption")}</Text>
        </Hidden>
      </Box>
      <Hidden only={["lg", "xl", "md", "2xl", "3xl"]}>
        <Box>
          <Text variant={"page_title"} color={"black"} paddingBottom={"0px"}>
            {t("vigilance_anti_corruption")}
          </Text>
        </Box>
      </Hidden>
      <Box
        padding={{
          base: "40px",
          xs: "30px",
          lg: "40px",
          xl: "40px",
          "2xl": "40px",
          "3xl": "40px",
        }}
      >
        <Box variant={"vigilance_content_box"}>
          <h1 className="vigilince_text">
            "{t("bribe_giving_and_receiving")}"{t("complaints_about_demand")}
          </h1>
        </Box>
        <Box variant={"vigilance_table_box"}>
          <VigilanceAntiCorruptionTable />
        </Box>
      </Box>
    </div>
  );
};

export default VigilanceAntiCorruptionContents;
