import { FilterItems, RupeesFormat } from "@helpers/constants";
import { Box, HStack, Hidden, Stack, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
// import imge1 from "@assets/images/menu/campion2.jpg";
// import imge2 from "@assets/images/menu/1531316511.jpeg";
// import imge3 from "@assets/images/menu/1531163694.jpg";
import MobileResponsiveTable from "./mobile_responsive_table";
import { Pagination } from "antd";
import { get_cms_schemes_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_cms_schemes_query";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import TableLoading from "../events/table_loading";
import { retrieveItem } from "@helpers/storage";

const SchemesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open_index, setOpenIndex] = useState(0);
  const [scheme_data, setSchemeData] = useState([]);
  let language = retrieveItem("language");

  const handleExpendable = (index) => {
    setOpenIndex(index);
  };

  const { items: schemes_items, loading } =
    useDynamicSelector("get_cms_schemes");


  const get_schemes = () => {
    let keys = [{ key: "get_cms_schemes", loading: true }];
    let variables = {
      json: {
        system_cms_key: "schemes_table",
      },
    };
    dispatch(dynamicRequest(keys, get_cms_schemes_query, variables));
  };

  useEffect(() => {
    get_schemes();
  }, []);

  useEffect(() => {
    let values = FilterItems(schemes_items);
    setSchemeData(values);
  }, [schemes_items]);

  return (
    <>
      {loading ? (
        <TableLoading />
      ) : (
        <>
          <Hidden from="base" till="lg">
            <div>
              <table class="table">
                <thead className="table-header">
                  <tr>
                    <th className="radius-right">{t("table:s_no")}</th>
                    <th className="radius-left">{t("table:name_of_scheme")}</th>
                  </tr>
                </thead>
                <tbody>
                  {scheme_data?.map((tablelist, index) => {
                    return (
                      <>
                        <tr
                          className={`space-add ${scheme_data?.length - 1 !== index
                              ? "table-border-bottom"
                              : ""
                            }`}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <div
                              onClick={() => handleExpendable(index)}
                              className={`table_row ${open_index === index
                                  ? `table_row_height`
                                  : `table_row_normal`
                                }`}
                            >
                              {open_index === index ? (
                                <p className="competition_name">
                                  <HStack variant={"table_title_tag"}>
                                    {/* <Text width={"10px"}>-</Text> */}
                                    <Box width={"20px"}>
                                      <AiOutlineMinus />
                                    </Box>
                                    <Text variant={"table_title"} >
                                      {tablelist?.title?.value}
                                    </Text>
                                  </HStack>
                                </p>
                              ) : (
                                <p className="competition_name">
                                  <HStack variant={"table_title_tag"}>
                                    {/* <Text>+</Text> */}
                                    <Box width={"20px"}>
                                      <AiOutlinePlus />
                                    </Box>
                                    <Text variant={"table_title"}>
                                      {tablelist?.title?.value}
                                    </Text>
                                  </HStack>
                                </p>
                              )}
                              <div
                                className={`table_open ${open_index === index
                                    ? `table_active`
                                    : `table_inactive`
                                  }`}
                              >
                                <div className="description_contents">
                                  {tablelist?.description?.value}
                                </div>
                                <HStack
                                  padding={"20px"}
                                  space={"20px"}
                                  alignItems={"center"}
                                >
                                  {tablelist?.image?.value &&
                                    <img
                                      src={tablelist?.image?.value}
                                      alt="fd"
                                      style={{ borderRadius: "10%",display:"none" }}
                                      width={"40%"}
                                      height={"20%"}
                                    />
                                  }
                                  <Box w="50%">
                                    {ReactHtmlParser(tablelist?.list?.value)}
                                  </Box>
                                </HStack>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Hidden>
          <Hidden from="lg" till="3xl">
            <div>
              <MobileResponsiveTable TableData={scheme_data} />
            </div>
          </Hidden>
          <div className="pagination-box">
            <Stack variant={"pagination_stack"}>
              <Hidden from="base" till="lg">
                <Box justifyContent={"center"}>
                  <Text>Records per page</Text>
                </Box>
              </Hidden>
              <Box variant={"pagination_box"}>
                <Pagination
                  total={85}
                  //   showTotal={(total) => `Records per page ${total} items`}
                  defaultPageSize={20}
                  defaultCurrent={1}
                />
              </Box>
            </Stack>
          </div>
        </>
      )}
    </>
  );
};

export default SchemesTable;
