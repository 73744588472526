import {
  create_system_cms_value,
  getsystemCMSFieldListSelector,
  get_system_cms_field,
  update_system_cms_value,
  dynamicRequest,
  useDynamicSelector,
  dynamicClear,
} from "@services/redux/index.js";
import { Button, Col, Divider, Form, Row, Space } from "antd";
import { startCase } from "lodash";
import { Box, HStack, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VForm from "../antd_form/antd_form.jsx";
import moment from "moment";
import {
  FilterItems,
  find_field,
  table_initial_content,
} from "@helpers/constants.js";
import { get_system_cms_field_stadium_query } from "@services/redux/slices/dynamic_entity/graphql/graphql_get_cms.js";
import { update_system_cms_values_mutation } from "@services/redux/slices/system_cms_value/graphql.js";
import { showToast } from "@helpers/toast.js";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AiOutlineMinusCircle } from "react-icons/ai";
const CmsDetails = (props) => {
  const [toDoList, setToDoList] = useState([]);
  const [stadium_option, set_stadium_option] = useState([]);
  const [nameList, setNameList] = useState([
    { title: "", order: "", images: "" },
  ]);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    setData,
    id,
    close,
    get_cms,
    submit,
    language,
    get_one,
    model_header,
    type,
    key_name,
    row_number: row_index,
    SetGetOne,
    // form,
  } = props;
  const { items } = useSelector(getsystemCMSFieldListSelector);
  const { items: stadium_list } = useDynamicSelector("stadium_list");
  const { loading: cms_value_create_loading } = useDynamicSelector(
    "createSystemCmsValues"
  );
  // let to_do_list_items = items?.filter(
  // (list) => list?.type === "list" || list?.type === "nested_list"
  // );
  let to_do_list_items = items;
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  let row_number = items?.map((list) => {
    let Lists = get_one?.[list?.key];
    return Lists?.row_number;
  });

  const find_key = () => {
    let row_number = null;
    Object?.keys(get_one)?.map((it) => {
      row_number = get_one?.[it]?.row_number;
    });
    return row_number;
  };
  const Ids = (id) => {
    let value_id = null;
    Object?.keys(get_one)?.map((it) => {
      if (get_one?.[it]?.system_cms_field_id === id)
        value_id = get_one?.[it]?.id;
    });
    return value_id;
  };

  const get_right_to_person_disabilities_menu = (data) => {
    let keys = [{ key: "createSystemCmsValues", loading: true }];
    let variables = {
      json: {
        data,
      },
    };
    dispatch(
      dynamicRequest(keys, update_system_cms_values_mutation, variables)
    );
  };
  const handleSubmit = (value) => {
    let orders = {
      [items?.[4]?.id]: JSON.stringify(
        value?.fields?.map((item) => item.order)
      ),
    };
    let titles = {
      [items?.[0]?.id]: JSON.stringify(
        value?.fields?.map((item) => item?.name)
      ),
    };
    let images = {
      [items?.[3]?.id]: JSON.stringify(
        value?.fields?.map((item) => item?.images?.map((list) => list?.url))
      ),
    };
    let Document = {
      [items?.[1]?.id]: JSON.stringify(
        value?.fields?.map((item) => item?.files?.map((list) => list?.url))
      ),
    };
    delete value.fields;
    if (
      images &&
      titles &&
      orders &&
      Document &&
      key_name === "Press Release"
    ) {
      value = { ...value, ...orders, ...titles, ...images, ...Document };
    }
    let convert_keys = Object.entries(value);
    let Create;
    let Update;
    if (model_header) {
      Create = convert_keys.map((s, index) => {
        let values = {
          system_cms_language_id: language,
          row_number: row_index + 1,
          system_cms_field_id: s[0],
          value: Array.isArray(s[1]) ? s[1]?.[0]?.url : s[1]?.toString() || "",
        };
        return values;
      });
    } else {
      Update = convert_keys.map((s, index) => {
        let values = {
          id: Ids(s?.[0]),
          system_cms_language_id: language,
          row_number: find_key(),
          system_cms_field_id: s[0],
          value:
            (Array.isArray(s[1]) ? s[1]?.[0]?.url : s[1]?.toString()) || "",
        };
        return values;
      });
    }
    model_header
      ? dispatch(
          create_system_cms_value({
            json: {
              data: Create,
            },
          })
        )
      : get_right_to_person_disabilities_menu(Update);

    // close(false);
  };
  // const handleInputChange = (values) => {};

  const handleClose = () => {
    close(false);
    form.resetFields();
    SetGetOne({});
  };
  const get_stadium_list = () => {
    let keys = [{ key: "stadium_list", loading: true }];
    let variables = {
      json: {
        system_cms_key: "Infrastructure_table",
      },
    };
    dispatch(
      dynamicRequest(keys, get_system_cms_field_stadium_query, variables)
    );
  };

  useEffect(() => {
    get_stadium_list();
  }, []);
  useEffect(() => {
    let val = {};

    if (get_one && type === "Edit CMS List") {
      Object?.keys(get_one)?.map((it, index) => {
        if (get_one[it]?.type === "datetime") {
          val[get_one[it]?.system_cms_field_id] = moment(
            new Date(get_one[it]?.value)
          );
        } else if (get_one[it]?.type === "file") {
          if (get_one[it]?.value) {
            val[get_one[it]?.system_cms_field_id] = [
              {
                uid: "-1",
                url: get_one[it]?.value || "",
                status: "done",
                name: "View",
              },
            ];
          } else {
            val[get_one[it]?.system_cms_field_id] = [];
          }
        } else {
          // Set a default value when none of the conditions are met
          val[get_one[it]?.system_cms_field_id] = get_one[it]?.value;
        }
      });

      if (get_one && key_name === "Press Release") {
        let images = get_one?.Images?.value
          ? JSON.parse(get_one?.Images?.value)
          : [];
        let files = get_one?.files?.value
          ? JSON.parse(get_one?.files?.value)
          : [];
        let title = get_one?.title?.value
          ? JSON.parse(get_one?.title?.value)
          : "";
        let order = get_one?.order?.value
          ? JSON.parse(get_one?.order?.value)
          : null;
        let fields = order?.map((x, i) => {
          return {
            images: images[i]?.[0]
              ? [
                  {
                    uid: "-1",
                    url: images[i]?.[0] || "",
                    status: "done",
                    name: "View",
                  },
                ]
              : [],
            files: files[i]?.[0]
              ? [
                  {
                    uid: "-1",
                    url: files[i]?.[0] || "",
                    status: "done",
                    name: "View",
                  },
                ]
              : [],
            order: x,
            name: title[i],
          };
        });
        val.fields = fields;
      }
      form.setFieldsValue(val);
    } else {
      form.resetFields();
    }
  }, [get_one, type, model_header]);

  useEffect(() => {
    if (type !== "Edit CMS List") {
      let values = items?.map((list) => {
        if (list?.type === "rich_text" && key_name === "High") {
          return { [list?.id]: table_initial_content.high_cash_incentive };
        } else if (list?.type === "rich_text" && key_name === "Competition") {
          return { [list?.id]: table_initial_content.compition_table };
        } else if (
          list?.type === "rich_text" &&
          key_name === "Tarrif For Competition 2" &&
          list.key === "list"
        ) {
          return {
            [list?.id]: table_initial_content.infrastructure_triff_table,
          };
        } else if (
          list?.type === "rich_text" &&
          key_name === "Youth Welfare Officers"
        ) {
          return {
            [list?.id]: table_initial_content.infrastructure_triff_table,
          };
        } else if (list?.type === "rich_text" && key_name === "Sports Hostal") {
          return {
            [list?.id]: table_initial_content.sports_hostel_table,
          };
        }
      });
      let setValues = Object.assign({}, ...values);
      form.setFieldsValue(setValues);
    }
  }, [type, key_name]);

  useEffect(() => {
    let values = FilterItems(stadium_list) || [];
    let stadium_option = values?.map((list) => {
      return {
        id: list?.stadium_name?.value,
        name: list?.stadium_name?.value,
      };
    });
    set_stadium_option(stadium_option);
  }, [stadium_list]);

  const handleChange = (e, val) => {
    console.log("handleChange", e, val);
    // setInitialValues(val);
  };
  useEffect(() => {
    let existing_list = to_do_list_items?.map((list) => {
      return {
        key: list?.key, // Set a default key or generate dynamically
        type: list?.type,
        id: list?.id, // Generate a unique id (you might want to use a better approach)
      };
    });
    setNameList(existing_list);
    if (to_do_list_items) {
      setToDoList([existing_list]);
    }
  }, [items]);
  return (
    <Box>
      {/* <VForm form={form} onFinish={handleSubmit} onValueChange={handleChange}> */}
      <Form
        form={form}
        onFinish={handleSubmit}
        // initialValues={initialValues}
        onValuesChange={handleChange}
        layout="vertical"
      >
        <Row style={{ gap: "10px" }}>
          {items?.map((list) => {
            return (
              <Col span={list?.type === "rich_text" ? 24 : 11}>
                {list?.type === "dropdown" &&
                key_name === "Tarrif For Competition 2" ? (
                  <VForm.Select
                    label={startCase(list?.key)}
                    field={list?.id}
                    options={stadium_option}
                    valueField="id"
                    labelFiled="name"
                    rules={[
                      {
                        required: false,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list?.type === "text" ? (
                  key_name === "Press Release" ? (
                    ""
                  ) : (
                    <VForm.TextBox
                      label={startCase(list?.key)}
                      field={list?.id}
                      rules={[
                        {
                          required: false,
                          message: `${startCase(list?.key)} is required`,
                        },
                      ]}
                    />
                  )
                ) : list?.type === "textarea" ? (
                  <VForm.TextArea
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: false,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list?.type === "file" ? (
                  key_name === "Press Release" ? (
                    ""
                  ) : (
                    <VStack>
                      <VForm.FormFile
                        label={startCase(list?.key)}
                        field={list?.id || ""}
                        getValueFromEvent={normFile}
                        allowed_file_formats={["pdf", "png", "jpg"]}
                        form={form}
                        rules={[
                          {
                            required: false,
                            message: `${startCase(list?.key)} is required`,
                          },
                        ]}
                      />
                      <span style={{ color: "red" }}>
                        {`Allowed file formats ${[
                          "pdf",
                          "png",
                          "jpg",
                          "jpeg",
                          "docx",
                          "doc",
                        ]?.map((s) => s)}`?.replace(/,/g, ", ")}
                      </span>
                    </VStack>
                  )
                ) : list?.type === "number" ? (
                  key_name === "Press Release" ? (
                    ""
                  ) : (
                    <VForm.Number
                      label={startCase(list?.key)}
                      field={list?.id}
                      rules={[
                        {
                          required: false,
                          message: `${startCase(list?.key)} is required`,
                        },
                      ]}
                    />
                  )
                ) : list?.type === "datetime" ? (
                  <VForm.Date
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: false,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : list.type === "rich_text" ? (
                  <VForm.RichText
                    label={startCase(list?.key)}
                    field={list?.id}
                    rules={[
                      {
                        required: false,
                        message: `${startCase(list?.key)} is required`,
                      },
                    ]}
                  />
                ) : null}
              </Col>
            );
          })}
        </Row>

        {key_name === "Press Release" ? (
          <VStack space={"10px"}>
            <Form.List name="fields">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <div key={field.key}>
                        <Divider>Field {index + 1}</Divider>

                        <VForm.Number
                          field={[index, "order"]}
                          label="Order"
                          rules={[{ required: true }]}
                        />
                        <VForm.TextBox
                          field={[index, "name"]}
                          label="Name"
                          rules={[{ required: true }]}
                        />
                        <VForm.FormFile
                          field={[index, "images"]}
                          label="Image"
                          maxCount={5}
                        />
                        <VForm.FormFile
                          field={[index, "files"]}
                          label="Document"
                        />
                        {fields.length > 1 ? (
                          <Button
                            type="danger"
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            icon={<MinusCircleOutlined />}
                          >
                            Remove Above Field
                          </Button>
                        ) : null}
                      </div>
                    ))}
                    <Divider />
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "60%" }}
                      >
                        <PlusOutlined /> Add field
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
          </VStack>
        ) : (
          ""
        )}

        <HStack space={3} justifyContent={"flex-end"}>
          <VForm.Button isLoading={cms_value_create_loading} />
          <Button onClick={handleClose}>Close</Button>
        </HStack>
      </Form>
    </Box>
  );
};

export default CmsDetails;
