import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fontSizeSelector } from "./services/redux";

function FontScaler({ children }) {
  const font_size_20 = 20;
  const font_size_14 = 14;
  const font_size_15 = 15;
  const font_size_13 = 13;
  const font_size_28 = 28;
  const font_size_12 = 12;
  const font_size_16 = 16;
  const font_size_17 = 17;
  const font_size_24 = 24;
  const font_size_25 = 25;
  const font_size_42 = 42;
  const font_size_80 = 80;
  const font_size_29 = 29;
  const font_size_35 = 35;
  const font_size_21 = 21;
  const header_selection_large_screen_size = 32;
  const overview_paragraph = 16;
  const font_size_22 = 22;
  const font_size_19 = 19;
  const font_size_36 = 36;
  const font_size_33 = 33;
  const font_size_30 = 30;
  const font_size_18 = 18;
  const font_size_04 = 4;
  const font_size_3_5 = 3.5;
  const font_size_1_1 = 1.1;
  const font_size_1_6 = 1.269;
  const font_size_1_2 = 1.2;
  const font_size_1_7 = 1.171;
  const font_size_1_8 = 1.8;
  const font_size_1_1_1 = 1.111;
  const font_size_2_8 = 2.8;
  const font_size_2_9 = 2.9166;
  const font_size_2_4 = 2.44;
  const font_size_2_5 = 2.5;
  const font_size_2 = 2;
  const font_size_3_8 = 3.8;
  const font_size_3 = 3;
  const font_size_4 = 4;
  const font_size_10 = 10;
  const font_size_3_2 = 3.2;
  const font_size_0_8 = 0.8;
  const font_size_32=32;
  const font_size_40=40;
  const font_size_65=65;
  const font_size_8=8;


  const { scale, font_size_change } = useSelector(fontSizeSelector);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--font-size-24",
      `${font_size_24 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-04",
      `${font_size_04 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-3-5",
      `${font_size_3_5 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-1-1",
      `${font_size_1_1 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-1-6",
      `${font_size_1_6 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-1-2",
      `${font_size_16 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-1-171",
      `${font_size_1_7 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-1-111",
      `${font_size_1_1_1 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-2-8",
      `${font_size_2_8 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-2-9",
      `${font_size_2_9 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-2-4",
      `${font_size_2_4 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-3-2",
      `${font_size_3_2 + font_size_change}vw`
    );
    document.documentElement.style.setProperty(
      "--font-size-1-8",
      `${font_size_1_8 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-2",
      `${font_size_2 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-2-5",
      `${font_size_2_5 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-3",
      `${font_size_3 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-3-8",
      `${font_size_3_8 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-4",
      `${font_size_4 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-10",
      `${font_size_10 + font_size_change}vh`
    );
    document.documentElement.style.setProperty(
      "--font-size-25",
      `${font_size_25 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-17",
      `${font_size_17 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-12",
      `${font_size_12 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-28",
      `${font_size_28 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-14",
      `${font_size_14 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-16",
      `${font_size_16 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-13",
      `${font_size_13 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-18",
      `${font_size_18 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-30",
      `${font_size_30 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-36",
      `${font_size_36 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-19",
      `${font_size_19 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-22",
      `${font_size_22 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-20",
      `${font_size_20 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--menubar-fontSize",
      `${font_size_15 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-15",
      `${font_size_15 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-18",
      `${font_size_18 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--header-selection-large",
      `${header_selection_large_screen_size + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--overview-paragraph",
      `${overview_paragraph + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-42",
      `${font_size_42 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--event-font-calculate",
      `${"calc(11px + 0.35625vw)" + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-33",
      `${font_size_33 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-80",
      `${font_size_80 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-29",
      `${font_size_29 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-35",
      `${font_size_35 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-21",
      `${font_size_21 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-32",
      `${font_size_32 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-40",
      `${font_size_40 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-65",
      `${font_size_65 + font_size_change}px`
    );
    document.documentElement.style.setProperty(
      "--font-size-8",
      `${font_size_8 + font_size_change}px`
    );
  }, [font_size_change]);

  const scaledStyle = {
    fontSize: `${scale}em`,
  };

  return (
    <div>
      <div style={scaledStyle}>{children}</div>
    </div>
  );
}

export default FontScaler;
