import produce from "immer";
import { Button, Empty, Form, Input, Modal, Select, Spin, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Center, HStack, Text, VStack } from "native-base";
import DynamicForm from "./dynamic_form";
import { useDispatch, useSelector } from "react-redux";

import {
    createsystemCMSValueSelector,
    create_one_system_cms_value_clear,
    create_system_cms_value,
    deletesystemCMSValueSelector,
    delete_one_system_cms_value_modal,
    delete_system_cms_value,
    getcmsSelector,
    getsystemCMSFieldListSelector,
    get_one_cms,
    get_one_system_cms_value_clear,
    get_system_cms_field,
    get_cms_languages,
    getcmsLanguageSelector,
    update_system_cms_value,
    dynamicRequest,
    useDynamicSelector,
    dynamicClear,
} from "@services/redux";
import { startCase } from "lodash";
import HTMLForm from "./html_form";
import { useParams } from "react-router-dom";
import { showToast } from "@helpers/toast";
import AntdModal from "../antd_modal/antd_modal";
import CmsDetails from "./system_cms_value_details";
import { update_system_cms_values_mutation } from "@services/redux/slices/system_cms_value/graphql";
const SystemCMSContentValue = () => {
    const { id } = useParams();
    const { item, loading: cms_loading } = useSelector(getcmsSelector);
    const { items, loading: cms_filed_loading } = useSelector(
        getsystemCMSFieldListSelector
    );
    const [open, setOpen] = useState(false);
    const { Option } = Select;
    const [columnChildren, setColumnChildren] = useState([]);
    const [form_data, setFormData] = useState();
    const [model_header, setModelHeader] = useState(true);
    const { status: cmsValueCreate, error: cmsValueErrorCreate } = useSelector(
        createsystemCMSValueSelector
    );
    const { status: cmsValueDelete, error: cmsValueErrorDelete } = useSelector(
        deletesystemCMSValueSelector
    );
    const { items: cms_languages } = useSelector(getcmsLanguageSelector);

    const dispatch = useDispatch();
    let cms_field = item?.system_cms_fields;
    const [form] = Form.useForm();
    const [tableForm] = Form.useForm();
    const [htmlForm] = Form.useForm();
    const [data, setData] = useState([]);
    const [isAdded, setIsAdded] = useState(false);
    const [dnata, setDnata] = useState([]);
    const [htmlValue, setHtmlValue] = useState();
    const [index, setIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [headers, setHeaders] = useState();
    const [get_one, SetGetOne] = useState();

    const { status, error, loading: cms_value_create_loading } = useDynamicSelector("createSystemCmsValues")

    const [language, setLanguage] = useState(
        "815d74c2-5c50-4bc0-96fd-8f81c403a7cf"
    );
    // setData(items)
    let system_cms_field_id = item?.system_cms_fields?.find(
        (field) => field.key === "code"
    )?.id;

    useEffect(() => {
        dispatch(get_one_cms({ json: { id: id } }));
    }, [id]);
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    // const onFinish = (formData) => {
    //   let obj = {};
    //   cms_field.map((item) => {
    //     obj[item.key] = "";
    //   });
    //   let raw_data = [...data];
    //   raw_data[data.length] = obj;
    //   setData(raw_data);
    //   setIsAdded(true);
    // };
    const onSubmit = (value) => {
        tableForm.validateFields().then(() => { });
    };
    const handleSubmit = (ind, _records) => {
        let record = data.find((_item, index) => index === ind);
        // setIsEditing(!isEditing);
        let final_record = Object.values(record);
        final_record = final_record.map((x) => {
            return { ...x, value: x?.value?.toString() || "" };
        });
        dispatch(create_system_cms_value({ json: { data: final_record } }));
    };
    const onDelete = (index, record) => {
        let cms_field_values = Object.values(item?.system_cms_fields)?.map((value) => value?.key)
        let cms_values = cms_field_values.map((list) => record[list]?.id).filter(x=>x!=null)
        dispatch(
            delete_system_cms_value({
                system_cms_value_ids: cms_values,
            })
        );
    };
    function callDynamicalForm() {
        let children = DynamicForm({
            Form,
            cms_field,
            setData,
            data: data,
            normFile,
            setIsEditing,
            isEditing: isEditing,
            fileList: fileList,
            setFileList,
            headers: headers,
            setHeaders,
            setIndex,
            index: index,
            handleSubmit,
            language: language,
            onDelete,
            setModelHeader,
            setOpen,
            SetGetOne,
            title: item?.system_cms_names.find(
                (field_item) => field_item.system_cms_language_id === language
            )?.name,
        });
        setColumnChildren(children);
    }
    // let Ids =()=> items?.map((list, index) => {
    //     let Lists = get_one?.[list?.key];
    //     return Lists?.id;
    // });
    const Ids = (id) => {
        let find_id = items?.filter((list) => list?.id === id)
        return find_id?.[0]?.system_cms_values?.[0]?.id
    }
    const get_right_to_person_disabilities_menu = (data) => {
        let keys = [{ key: "createSystemCmsValues", loading: true }];
        let variables = {
            json: {
                data
            },
        };
        dispatch(
            dynamicRequest(
                keys,
                update_system_cms_values_mutation,
                variables
            )
        );
    };

    const onFinishHtml = (values,) => {
        let convert_keys = Object.entries(values);
        const Update = convert_keys.map((s, index) => {
            let values = {
                id: Ids(s?.[0]),
                system_cms_language_id: language,
                row_number: index,
                system_cms_field_id: s[0],
                value: Array.isArray(s[1]) ? s[1]?.[0]?.url : s[1],
            };
            return values;
        });
        get_right_to_person_disabilities_menu(Update)

    };

    useEffect(() => {
        if (item) {
            callDynamicalForm();
            dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
        }
    }, [item, isEditing, fileList, headers, index, language]);


    useEffect(() => {
        if (cmsValueCreate === "Success") {
            setIsAdded(false);
            dispatch(create_one_system_cms_value_clear());
            showToast({
                type: "success",
                message: "CMS value added successfully",
            });
            handleModalCancel();
            dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
        } else if (cmsValueCreate === "Failure") {
            setIsAdded(false);
            dispatch(create_one_system_cms_value_clear());
            showToast({
                type: "error",
                message: cmsValueErrorCreate,
            });
        } else if (cmsValueDelete === "Success") {
            setIsAdded(false);
            dispatch(delete_one_system_cms_value_modal());
            showToast({
                type: "success",
                message: "CMS value deleted successfully",
            });
            dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
        } else if (cmsValueDelete === "Failure") {
            setIsAdded(false);
            dispatch(create_one_system_cms_value_clear());
            showToast({
                type: "error",
                message: cmsValueErrorDelete,
            });
        } else if (status === "Success") {
            showToast({
                type: "success",
                message: "CMS value updated successfully",
            });
            setOpen(false);
            SetGetOne({})
            dispatch(get_system_cms_field({ json: { system_cms_id: item?.id } }));
            dispatch(dynamicClear("createSystemCmsValues"))
        } else if (error) {
            showToast({
                type: "error",
                message: "Something went wrong ",
            });
            dispatch(dynamicClear("createSystemCmsValues"))
        }
    }, [cmsValueCreate, cmsValueDelete, status, error]);

    useEffect(() => {
        if (!isAdded && items) {
            let filteredItems = items?.map((item) => {
                return {
                    ...item,
                    system_cms_values: item.system_cms_values.filter(
                        (fieldItemValue) =>
                            fieldItemValue.system_cms_language_id === language
                    ),
                };
            });
            let highestValue = 0;
            let objectKeys = filteredItems.map((fieldItem) => {
                highestValue =
                    fieldItem.system_cms_values?.length > highestValue
                        ? fieldItem.system_cms_values?.length
                        : highestValue;
                return fieldItem.key;
            });
            let finalList = [];
            for (let index = 0; index < highestValue; index++) {
                let object = {};
                for (let key of objectKeys) {
                    let itemToFind = items.find((filterItem) => filterItem.key === key);

                    let value = itemToFind.system_cms_values.filter(
                        (findItem) => findItem.system_cms_language_id === language
                    );
                    let fie = {
                        ...value?.[index],
                        type: itemToFind?.type,
                    };
                    object[key] = fie || undefined;
                }
                finalList.push(object);
            }
            setData(finalList);
        }
    }, [items, language]);

    // useEffect(() => {
    //   let row_data = data;
    //   row_data[index][item.key] = {
    //     system_cms_field_id: item.id,
    //     system_cms_language_id: language,
    //     value: e.target.value,
    //     row_number: index,
    //     id: row_data?.[index]?.[item.key]?.id || undefined,
    //   };
    //   setData(row_data);
    // }, [data]);

    useEffect(() => {
        htmlFormValue();
        dispatch(get_cms_languages());
    }, [dnata]);
    useEffect(() => {
        if (item) {
            listFormValue();
            dispatch(get_cms_languages());
        }
    }, [data]);

    const htmlFormValue = () => {
        return (
            <HTMLForm
                htmlForm={htmlForm}
                items={items}
                onFinishHtml={onFinishHtml}
                language={language}
                cms_loading={cms_value_create_loading}
            />
        );
    };
    const handleClick = () => {
        setOpen(true);
        setModelHeader(true);
        form.resetFields();
    };
    const handleModalCancel = () => {
        setOpen(false);
        SetGetOne({})
    };

    const listFormValue = () => {
        return (
            <>
                <HStack justifyContent={"flex-end"}>
                    <Button className="add-cms-list-but" onClick={handleClick}>
                        Add
                    </Button>
                </HStack>
                <Form
                    name={item.key}
                    form={tableForm}
                    initialValues={data}
                    onFinish={onSubmit}
                >
                    <Table
                        key={item.key}
                        dataSource={data}
                        columns={columnChildren}
                        loading={cms_filed_loading}
                        bordered
                        childrenColumnName="antdChildren"
                        scroll={{
                            x: 1500,
                        }}
                    />
                </Form>
            </>)
    };
    let key_name = item?.system_cms_names.find(
        (field_item) => field_item.system_cms_language_id === language
    )?.name;
    return id ? (
        <div>
            <VStack space={item?.type === "list" ? 0 : 20}>
                <HStack space={10}>
                    <Box>
                        <Center>
                            <Text bold fontSize={28}>
                                {`${startCase(key_name)} ${startCase(item?.type)}`}
                            </Text>
                        </Center>
                    </Box>
                    <Box width={200}>
                        <Center height={"100%"} width={200}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setLanguage(e);
                                }}
                                defaultValue="815d74c2-5c50-4bc0-96fd-8f81c403a7cf"
                            >
                                {cms_languages?.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.id}>
                                            {item.key}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Center>
                    </Box>
                </HStack>
                {cms_loading && <Spin size="large" />}
                {item?.type === "list" && listFormValue(startCase(key_name))}
                {item?.type === "html" && htmlFormValue(startCase(key_name))}
            </VStack>
            <Modal
                title={model_header ? "Create CMS List" : "Edit CMS List"}
                open={open}
                footer={null}
                onCancel={handleModalCancel}
                width={1000}
            >
                <CmsDetails
                    id={item?.id}
                    index={index}
                    setData={setFormData}
                    close={setOpen}
                    data={data}
                    get_cms={item}
                    submit={system_cms_field_id}
                    language={language}
                    get_one={get_one}
                    model_header={model_header}
                    form={form}
                    type={model_header ? "Create CMS List" : "Edit CMS List"}
                    key_name={startCase(key_name)}
                    row_number={data?.length}
                    onCancel={handleModalCancel}
                    SetGetOne={SetGetOne}
                />
            </Modal>
        </div>
    ) : (
        <Empty />
    );
};
export default SystemCMSContentValue;
