import { gql } from "@apollo/client";

export const get_cms_homepage_slider_query = gql`
  query getSystemCmsFields($json: get_all_system_cms_field_input) {
    get_cms_homepage_slider: getSystemCmsFields(json: $json) {
      items {
        id
        key
        type
        system_cms {
          id
        }
        system_cms_values {
          id
          value
          row_number
          system_cms_field_id
          system_cms_language_id
          system_cms_field_option_value_id
        }
      }
    }
  }
`;
